export const getAdvancedPizzaPrice = (data) => {
  const finalPrice = [];

  data
    ?.filter(
      (t) => !t.defaultSelected || (t?.defaultSelected && t?.type === "extra")
    )
    ?.map((da) => {
      const { halfPrice, extraPrice, price, size, type } = da;

      if (size !== "" && type !== "") {
        if (type === "regular" && (size === "left" || size === "right")) {
          finalPrice.push(halfPrice);
        } else if (type === "regular" && size === "all") {
          finalPrice.push(price);
        } else if (type === "extra" && size === "all") {
          finalPrice.push(extraPrice);
        } else if ((type === "extra" && size === "left") || size === "right") {
          finalPrice.push(extraPrice / 2);
        }
      }
    });

  const sum = finalPrice.reduce((acc, val) => acc + val, 0);

  return sum;
};
