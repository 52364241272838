/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { FaCaretDown } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Button, UncontrolledCollapse } from "reactstrap";
import HorizontalList from "../../../../components/List/HorizontalList";
import TopNav from "../../../../components/Navbar/TopNav";
import Notification from "../../../../components/Popups/Notification";
import ProductCard from "../../../../components/ProductCard";
import { useCart } from "../../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../../hooks/useEntity";
import useIsInViewport from "../../../../hooks/useInViewPort";
import useScreenSize from "../../../../hooks/useScreenSize";
import { useCartStore } from "../../../../store";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import compareArrays from "../../../../utils/compareArray";
import groupByNameParent from "../../../../utils/groupByParent";
import logger from "../../../../utils/logger";
import DetailsPanel from "../../catering/components/DetailsPanel";
import CartModal from "../../components/CartModal";
import EditDetailsModal from "../../components/EditDetailsModal";
import Footer from "../../components/Footer";
import ViewDishModal from "../../components/ViewDishModal";
import ViewDishModalCatering from "../../components/ViewDishModalCatering";
import GenericModal from "../../components/modals/GenericModal";
import EmptyPlaceholder from "./EmptyPlaceholder";
import GridCardProduct from "./GridCardProduct";
import GroupedPizzaToppings from "./GroupPizzaToppings";
import MenuBannerDetails from "./MenuBannerDetails";

const MenuMainSections = (props) => {
  const {
    data,
    catName,
    history,
    allItems,
    sidebarOpen,
    groupCategories,
    onGroupCategoryChange,
    selectedGroupCategories,
    catSelected,
    categories,
    searchText,
    isLoading: menuLoading,
    allData,
    total,
    hasExtraCats,
  } = props;

  const [dish, setDish] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [openDish, setOpenDish] = useState(false);
  const { restaurant } = useOrderingRestaurant();
  const [isOpenCart, setIsOpenCart] = useState(false);
  const [isMobileSearch, setIsMobileSearch] = useState(false);
  const { cartItems } = useCartStore();
  const { theme } = useTheme();
  // eslint-disable-next-line no-unused-vars
  const [billingDetails, setBillingDetails] = useState();
  const [searchResults, setSearchResults] = useState([]);

  const contentRef = useRef(null);
  const headerRef = useRef(null);
  const categoryButtonRefs = useRef([]);
  const sectionRef = useRef(null);
  const isInViewPort = useIsInViewport(sectionRef);
  const screenSize = useScreenSize();

  const {
    location,
    deliveryConfirmAddress,
    method,
    deliveryZone,
    deliveryMethod,
    cateringData,
    mobileSearch,
    deliveryFee,
    menuId,
  } = useOrdering();

  console.log("MENU-ID", menuId);

  const { addToCart, changeQuantity } = useCart();
  const [homeModal, setHomeModal] = useState(false);

  let pathname = window?.location?.pathname?.replace(/\/+$/, "");
  pathname = pathname?.split("/");

  async function getBillingDetails() {
    try {
      const res = await getBilling({
        data: [...cartItems],
        restaurant: restaurant?._id,
        method,
        location: location?._id,
        address: deliveryConfirmAddress,
        deliveryZoneId: deliveryZone?._id,
        deliveryMethod,
        catering: cateringData?.catering,
        menuId,
      });
      setBillingDetails(res);
    } catch (error) {
      logger.push({ error, info: "MainMenuSections" });
      toast.error(error.message);
    }
  }

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (restaurant?.pop_up_alerts?.category_popup?.isActive) {
      setOpen(true);
    }
  }, [restaurant]);

  const { create: getBilling, loading } = useEntity("eat/ordering/billing");

  useEffect(() => {
    if (cartItems?.length > 0) {
      getBillingDetails().then((res) => {});
    }
  }, [cartItems, cateringData, menuId]);

  useEffect(() => {
    setHomeModal(true);
  }, [restaurant]);

  const handleScroll = () => {
    const currentScrollPos =
      contentRef.current.scrollTop + contentRef.current.clientHeight;
    const itemHeights = Array.from(contentRef.current.children).map(
      (child) => child.offsetTop
    );

    let foundCategory = null;
    for (let i = 0; i < allData?.length; i++) {
      const start = itemHeights[i];
      const end =
        i + 1 < allData?.length
          ? itemHeights[i + 1]
          : contentRef.current.scrollHeight;

      if (
        (currentScrollPos >= start && currentScrollPos < end) ||
        (i === allData?.length - 1 && currentScrollPos >= start)
      ) {
        foundCategory = {
          category: allData[i].category,
          description: allData[i]?.description,
        };
        break;
      }
    }
    if (foundCategory !== null) {
      catName({
        name: foundCategory?.category,
        description: foundCategory?.description,
      });
    }
  };

  const scrollToCategory = (category) => {
    if (screenSize.width <= 750) {
      const targetItemIndex = allData.findIndex(
        (item) => item.category === category
      );

      if (targetItemIndex !== -1) {
        if (
          contentRef.current &&
          contentRef.current.children[targetItemIndex]
        ) {
          const targetElement = contentRef.current.children[targetItemIndex];

          targetElement.classList.add("scroll-offset");

          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }
    }
  };

  const searchItems = (searchTerm) => {
    try {
      if (searchTerm.toLocaleLowerCase() === "") {
        setSearchResults([]);
        return;
      }

      const searchLower = searchTerm.toLowerCase().trim();

      const results = allItems
        ?.filter((item) => {
          const nameMatch = item?.name.toLowerCase().includes(searchLower);
          const descriptionMatch =
            item?.description &&
            item?.description.toLowerCase().includes(searchLower);

          return nameMatch || descriptionMatch;
        })
        .filter(
          (function () {
            const seen = new Set();
            return function (item) {
              if (!seen.has(item._id)) {
                seen.add(item._id);
                return true;
              }
              return false;
            };
          })()
        );

      setSearchResults(results);
    } catch (error) {}
  };

  useEffect(() => {
    searchItems(searchText);
  }, [searchText]);

  useEffect(() => {
    if (catSelected?.name) {
      scrollToCategory(catSelected?.name);
    }
  }, [catSelected?.name]);

  useEffect(() => {
    setIsMobileSearch(mobileSearch);
  }, [mobileSearch]);

  const RenderedComponent = ({ d, i }) => {
    return restaurant?.ordering?.menuLayout === "grid" ? (
      <GridCardProduct
        length={allItems?.length}
        ind={i}
        key={i}
        theme={theme}
        sidebarOpen={sidebarOpen}
        data={d}
        onClick={() => {
          setDish(d);
          setOpenDish(true);
        }}
      />
    ) : (
      <ProductCard
        length={allItems?.length}
        ind={i}
        key={i}
        theme={theme}
        sidebarOpen={sidebarOpen}
        data={d}
        onClick={() => {
          setDish(d);
          setOpenDish(true);
        }}
      />
    );
  };

  const MemoizedComponent = memo(RenderedComponent);

  return (
    <div>
      <TopNav menu={true} startOver={false} history={history} />

      {restaurant?.ordering?.categoryLayout === "horizontal" &&
        restaurant?.ordering?.categoryLayout !== "select" &&
        screenSize?.width <= 750 && (
          <div
            style={{
              borderTop: `1px solid silver`,
              borderBottom: `1px solid silver`,
              zIndex: 9,
              marginTop:
                !isInViewPort && !isMobileSearch
                  ? 60
                  : !isInViewPort && isMobileSearch
                  ? 140
                  : 0,
            }}
            ref={headerRef}
            className={
              isInViewPort
                ? "hide_scroll hide_mobile_category"
                : "hide_mobile_category mobile_horizontal_layout_fixed hide_scroll"
            }
          >
            {allData?.map((cat) => {
              return (
                <div
                  key={cat?.category}
                  ref={(el) => {
                    categoryButtonRefs.current[cat.category] = el;
                  }}
                  onClick={() => {
                    scrollToCategory(cat?.category);
                    catName({
                      name: cat?.category,
                      description: cat?.description,
                    });
                  }}
                  className="px-4 py-3"
                  style={{
                    borderBottom:
                      catSelected?.name === cat?.category
                        ? `1px solid ${theme.secondary}`
                        : "none",
                    fontSize: 14,
                    fontWeight: "600",
                    cursor: "pointer",
                    borderRadius: "0px",
                    background: " #FFFFFF",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    flex: 1,
                    zIndex: 99,
                  }}
                >
                  {cat.category}
                </div>
              );
            })}
          </div>
        )}

      {restaurant?.ordering?.headerImage !== "" &&
        restaurant?.ordering?.headerImage && (
          <div className="menu_header_image">
            <img
              src={restaurant?.ordering?.headerImage}
              alt="header_image"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </div>
        )}

      <div
        className="header_image_bottom"
        style={{
          display: "flex",
          marginTop:
            restaurant?.ordering?.headerImage !== "" &&
            restaurant?.ordering?.headerImage
              ? -70
              : 0,
        }}
      >
        <div
          className="main_section_wrapper"
          style={{
            flex: 2.5,
          }}
        >
          <div ref={sectionRef}>
            {cateringData?.catering ? (
              <DetailsPanel
                whiteBg={true}
                showNav={true}
                history={props.history}
                theme={theme}
                origin="menu"
              />
            ) : (
              <MenuBannerDetails popover={false} />
            )}
          </div>

          <div className="hide_mobile_group_cats">
            {groupCategories?.length > 0 && (
              <HorizontalList
                selected={selectedGroupCategories}
                onChangeItem={(id) => {
                  onGroupCategoryChange(id);
                }}
                items={groupCategories || []}
                cats={categories?.data?.availCats}
                hasExtraCats={hasExtraCats}
              />
            )}
          </div>

          {screenSize.width <= 750 ? (
            <div
              style={{ width: "100%" }}
              className=" mobile-minus text-left mb-8"
            >
              {menuLoading ? (
                <div className="my-3">
                  <Skeleton height={30} width={100} />
                  <Skeleton height={25} />
                  <Skeleton height={25} width={"100%"} />
                  {[...new Array(4).fill(null).map((_) => _)].map((_, i) => {
                    return (
                      <div key={i} className="">
                        <Skeleton height={200} />
                        <Skeleton height={15} width={100} />
                        <Skeleton height={15} />
                        <Skeleton height={15} />
                        <Skeleton height={40} width={100} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="mt-5">
                  {searchResults.length <= 0 && searchText === "" ? (
                    <>
                      <div className="prod_menu_card">
                        <div onScroll={handleScroll} ref={contentRef}>
                          {allData?.map((menu) => {
                            return (
                              <div key={menu.category}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: 20,
                                  }}
                                >
                                  <div>
                                    <div className="d-flex w-full justify-content-between align-items-center">
                                      <h1 style={{ color: theme.primary }}>
                                        {menu?.category}
                                      </h1>
                                    </div>
                                    <h1
                                      className="mb-3"
                                      style={{
                                        color: "#666666",
                                        fontSize: 14,
                                        fontWeight: "500",
                                        marginBottom: 10,
                                      }}
                                    >
                                      {menu?.description
                                        ? menu?.description
                                        : ""}
                                    </h1>
                                  </div>
                                </div>
                                <div>
                                  {restaurant?.ordering?.menuLayout ===
                                  "grid" ? (
                                    <div className="grid-layout-menu-new">
                                      {menu?.items?.map((d, i) => {
                                        return (
                                          <GridCardProduct
                                            length={menu?.items?.length}
                                            ind={i}
                                            key={i}
                                            theme={theme}
                                            sidebarOpen={sidebarOpen}
                                            data={d}
                                            onClick={() => {
                                              setDish(d);
                                              setOpenDish(true);
                                            }}
                                          />
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <div
                                      className="main-sec-cati row"
                                      style={{ margin: 0 }}
                                    >
                                      {menu?.items?.map((d, i) => {
                                        return (
                                          <ProductCard
                                            length={menu?.items?.length}
                                            ind={i}
                                            key={i}
                                            theme={theme}
                                            sidebarOpen={sidebarOpen}
                                            data={d}
                                            onClick={() => {
                                              setDish(d);
                                              setOpenDish(true);
                                            }}
                                          />
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="mt-4">
                      {searchResults?.length === 0 ? (
                        <div>
                          <EmptyPlaceholder text="Nothing found" />
                        </div>
                      ) : (
                        <div>
                          <h1>Search Results</h1>
                          {restaurant?.ordering?.menuLayout === "grid" ? (
                            <div className="grid-layout-menu-new">
                              {searchResults?.map((d, i) => {
                                return (
                                  <GridCardProduct
                                    length={searchResults?.length}
                                    ind={i}
                                    key={i}
                                    theme={theme}
                                    sidebarOpen={sidebarOpen}
                                    data={d}
                                    onClick={() => {
                                      setDish(d);
                                      setOpenDish(true);
                                    }}
                                  />
                                );
                              })}
                            </div>
                          ) : (
                            <>
                              <div
                                className="main-sec-cati row"
                                style={{ margin: 0 }}
                              >
                                {searchResults?.map((d, i) => {
                                  return (
                                    <ProductCard
                                      length={searchResults?.length}
                                      ind={i}
                                      key={i}
                                      theme={theme}
                                      sidebarOpen={sidebarOpen}
                                      data={d}
                                      onClick={() => {
                                        setDish(d);
                                        setOpenDish(true);
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="mt-3">
              <h1 className="text-left">{catSelected?.name}</h1>
              <h1
                className="mb-3 text-left"
                style={{
                  color: "#666666",
                  fontSize: 14,
                  fontWeight: "500",
                  marginBottom: 10,
                }}
              >
                {catSelected?.description ? catSelected?.description : ""}
              </h1>
              <div className="text-left" style={{ margin: 0 }}>
                {menuLoading && (
                  <div>
                    <Skeleton height={30} width={100} />
                    <Skeleton height={25} />
                    <Skeleton height={25} width={"100%"} />
                    {[...new Array(4).fill(null).map((_) => _)].map((_, i) => {
                      return (
                        <div key={i} className="">
                          <Skeleton height={200} />
                          <Skeleton height={15} width={100} />
                          <Skeleton height={15} />
                          <Skeleton height={15} />
                          <Skeleton height={40} width={100} />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div
                  className={
                    restaurant?.ordering?.menuLayout === "grid"
                      ? "grid-layout-menu-new"
                      : "main-sec-cati"
                  }
                >
                  {data?.map((d, i) => (
                    <MemoizedComponent d={d} i={i} key={i} />
                  ))}
                </div>
                <div className="">
                  {data?.length <= 0 && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <EmptyPlaceholder />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="mb-8">
            <Footer />
          </div>
        </div>
        <div
          className="mt-2 order_bag_sidebar"
          style={{
            flex: 1,
            background: "#FFFFFF",
            boxShadow: " -4px -4px 26px 4px rgba(0, 0, 0, 0.3)",
            borderRadius: "6px 0px 0px 6px",
            minHeight: "70vh",
          }}
        >
          <div className="px-4">
            <h1 className="my-3 text-left" style={{ fontWeight: "400" }}>
              Cart
            </h1>

            <div
              style={{
                background: "#FFFFFF",
                borderRadius: 6,
              }}
            >
              {cartItems?.length > 0 && (
                <div
                  style={{
                    boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
                    background: "#FFFFFF",
                    borderRadius: 6,
                    padding: 10,
                  }}
                >
                  {cartItems?.map((it, i) => {
                    let o = allItems?.find((itm) => itm?._id === it?._id);

                    const allSubprods = Object.values(
                      o?.modifiers || {}
                    ).flatMap((modif) => modif?.subProducts);

                    const allSelectedModifiers = allSubprods.filter(
                      (subProd) =>
                        subProd?.defaultSelected ||
                        subProd?.advancedPizzaOptions
                    );

                    const missingSelected = compareArrays(
                      allSelectedModifiers,
                      it?.modifiers
                    );

                    const filteredBasedOnParentModifs = missingSelected.filter(
                      (f) => {
                        const idx = it?.modifiers?.findIndex(
                          (t) =>
                            f?.selectedParentValue?.label === t?.product_name ||
                            f?.selectedParentValue?.label === "" ||
                            !f?.selectedParentValue?.label
                        );
                        return idx !== -1;
                      }
                    );

                    const finalMissingSelected =
                      filteredBasedOnParentModifs.filter(
                        (obj, index, self) =>
                          index ===
                          self.findIndex(
                            (t) =>
                              t.product_id === obj.product_id &&
                              t.product_name === obj.product_name
                          )
                      );

                    const AdvancedPizzaModifiersCart = it?.modifiers?.filter(
                      (mod) => mod?.advancedPizzaOptions
                    );

                    const quantityItem = cartItems.reduce((ac, ar) => {
                      if (ar?._id === o?._id) {
                        return ac + ar.qty;
                      } else return ac;
                    }, 0);

                    return (
                      <div className="mt-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h1
                            style={{
                              fontWeight: "500",
                              fontSize: 18,
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            {o?.name}
                          </h1>
                          <h3
                            style={{
                              fontSize: 20,
                              color: "#212121",
                              fontWeight: "600",
                            }}
                          >
                            {Number(o?.price) !== 0
                              ? `$${Number(o?.price * it?.qty).toFixed(2)}`
                              : ""}
                          </h3>
                        </div>

                        <div className="plus-icon inner-plus rounded">
                          <Button
                            onClick={() => {
                              changeQuantity(i);
                            }}
                            size="sm"
                            className="btn-icon btn-2"
                            type="button"
                            style={{
                              margin: "none",
                              boxShadow: "none",
                              transform: "none",
                            }}
                          >
                            <span>
                              <i
                                className="fa fa-minus"
                                style={{ fontSize: "16px" }}
                              />
                            </span>
                          </Button>
                          <Button
                            style={{
                              margin: "none",
                              boxShadow: "none",

                              transform: "none",
                            }}
                            size="sm"
                          >
                            {it?.qty}
                          </Button>
                          <Button
                            size="sm"
                            style={{
                              margin: "none",
                              boxShadow: "none",

                              transform: "none",
                            }}
                            type="button"
                            disabled={
                              o?.moqPerOrder
                                ? quantityItem >= o?.moqPerOrder
                                : false
                            }
                            onClick={() => {
                              if (
                                quantityItem >= o?.moqPerOrder &&
                                cateringData?.catering
                              ) {
                                toast(
                                  `Max quantity per order is ${o?.moqPerOrder}`
                                );

                                return;
                              }
                              addToCart({ ...it });
                            }}
                          >
                            <span>
                              <i
                                className="fa fa-plus"
                                style={{ fontSize: "16px" }}
                              />
                            </span>
                          </Button>
                        </div>
                        <div
                          className=" mt-3"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          <>
                            {finalMissingSelected?.map((selected) => {
                              return (
                                <div className="d-flex text-left justify-content-between  align-items-center">
                                  <div>
                                    <div className={"normal"}>
                                      No {selected?.product_name}
                                    </div>
                                  </div>

                                  <div></div>
                                </div>
                              );
                            })}
                            <GroupedPizzaToppings
                              it={it}
                              data={AdvancedPizzaModifiersCart}
                            />

                            {groupByNameParent(
                              it?.modifiers?.filter(
                                (it) =>
                                  (!it?.defaultSelected || it?.qty > 1) &&
                                  !it.advancedPizzaOptions
                              )
                            ).map((modif) => {
                              return (
                                <>
                                  {restaurant?.showModifierName && (
                                    <div className={"bold text-left"}>
                                      {modif.name}
                                    </div>
                                  )}
                                  {modif.data
                                    .sort((a, b) => a.sort - b.sort)
                                    .map((op) => {
                                      let newPrice;
                                      if (
                                        op?.qty &&
                                        op?.qty > 1 &&
                                        !op?.defaultSelected
                                      ) {
                                        newPrice = op?.price * op?.qty;
                                      } else if (
                                        op?.qty &&
                                        op?.qty > 1 &&
                                        op?.defaultSelected
                                      ) {
                                        const quantity = Number(op?.qty) - 1;
                                        newPrice = op?.price * quantity;
                                      } else newPrice = op?.price * 1;

                                      const modifierSelectdPrice =
                                        op?.selectedModifier?.label?.split(
                                          "$"
                                        )[1] === undefined
                                          ? 0
                                          : op?.selectedModifier?.label?.split(
                                              "$"
                                            )[1];

                                      return (
                                        <>
                                          {!op?.advancedPizzaOptions && (
                                            <>
                                              <div className="d-flex text-left justify-content-between  align-items-center">
                                                <div>
                                                  <div
                                                    className={
                                                      op?.selectedModifier
                                                        ?.label
                                                        ? "bold"
                                                        : "normal"
                                                    }
                                                  >
                                                    {op?.product_name}{" "}
                                                    {`${
                                                      (!op.defaultSelected &&
                                                        op?.qty > 1) ||
                                                      (op.defaultSelected &&
                                                        op?.qty > 2)
                                                        ? ` - x${
                                                            op?.defaultSelected
                                                              ? op?.qty - 1
                                                              : op?.qty
                                                          }`
                                                        : ""
                                                    }`}
                                                  </div>
                                                </div>

                                                <div>
                                                  {newPrice !== 0 && (
                                                    <div>
                                                      $
                                                      <>
                                                        {Number(
                                                          Number(
                                                            Number(newPrice) -
                                                              Number(
                                                                op?.selectedModifier?.label.split(
                                                                  "$"
                                                                )[1] || 0
                                                              )
                                                          ) * it?.qty
                                                        ).toFixed(2)}
                                                      </>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div>
                                                {op?.selectedModifier
                                                  ?.label && (
                                                  <div
                                                    className="d-flex"
                                                    style={{
                                                      width: "100%",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <div className="text-left">
                                                      -{" "}
                                                      {
                                                        op?.selectedModifier?.label
                                                          .split("$")[0]
                                                          .split(" -")[0]
                                                      }
                                                    </div>
                                                    {modifierSelectdPrice !==
                                                      0 && (
                                                      <div>
                                                        $
                                                        {Number(
                                                          modifierSelectdPrice *
                                                            it?.qty
                                                        ).toFixed(2)}
                                                      </div>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </>
                                      );
                                    })}
                                </>
                              );
                            })}
                          </>
                        </div>
                        {it?.note && (
                          <>
                            <h1
                              style={{
                                fontWeight: "500",
                                fontSize: 18,
                                color: "#212121",
                                textAlign: "left",
                              }}
                            >
                              Special Instructions
                            </h1>
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: 15,
                                color: "#666666",
                                textAlign: "left",
                              }}
                            >
                              {it?.note}
                            </p>
                          </>
                        )}
                        {cartItems?.length - 1 !== i && <hr />}
                      </div>
                    );
                  })}
                </div>
              )}

              {!loading ? (
                <>
                  {cartItems?.length > 0 ? (
                    <>
                      <div
                        className="mt-5"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3 style={{ fontWeight: "600" }}>Subtotal :</h3>
                        <div>
                          <h3 style={{ fontWeight: "600" }} className="mr-1">
                            ${Number(billingDetails?.data?.subTotal).toFixed(2)}
                          </h3>
                        </div>
                      </div>
                      <div>
                        <div
                          id="linkToggler"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h3 style={{ cursor: "pointer" }} className=" mt-1">
                            Tax & Fees <FaCaretDown size={20} />
                          </h3>
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              color: "black",
                            }}
                          >
                            <div>
                              {" "}
                              <h3 className="mr-1">
                                $
                                {(
                                  parseFloat(billingDetails?.data?.tax) +
                                  parseFloat(
                                    location?.enableIhd &&
                                      !cateringData?.catering &&
                                      method === "delivery"
                                      ? deliveryFee
                                      : 0
                                  )
                                ).toFixed(2)}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <UncontrolledCollapse toggler="#linkToggler">
                          <div
                            style={{
                              fontSize: "16px",
                              backgroundColor: "inherit",
                            }}
                            className="py-2"
                          >
                            {billingDetails?.data?.taxDetails?.map(
                              (taxName, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="text-left d-flex justify-content-between mb-2"
                                  >
                                    <div>{taxName?.name}</div>
                                    <div>
                                      ${Number(taxName?.amount).toFixed(2)}
                                    </div>
                                  </div>
                                );
                              }
                            )}

                            {(method === "pickup" ||
                              !location?.enableIhd ||
                              (location.enableIhd &&
                                restaurant.ihdFees === "restaurant")) &&
                              billingDetails?.data?.orderFee > 0 && (
                                <div className="d-flex justify-content-between mb-2">
                                  <div>Ordering Fee</div>
                                  <div>
                                    ${billingDetails?.data?.orderFee.toFixed(2)}
                                  </div>
                                </div>
                              )}

                            {method === "delivery" && (
                              <div className="d-flex justify-content-between mb-2">
                                <div>Delivery Fee</div>
                                <div>
                                  $
                                  {location?.enableIhd &&
                                  restaurant?.ihdFees === "partner"
                                    ? (
                                        billingDetails?.data
                                          ?.accumatedDeliveryFee + deliveryFee
                                      )?.toFixed(2)
                                    : (
                                        billingDetails?.data?.deliveryFee +
                                        (location?.enableIhd &&
                                        !cateringData?.catering
                                          ? deliveryFee
                                          : 0)
                                      ).toFixed(2)}
                                </div>
                              </div>
                            )}
                          </div>
                        </UncontrolledCollapse>
                      </div>
                      <div className="bold">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h3
                              style={{
                                color: theme.primary,
                              }}
                            >
                              Total
                            </h3>
                          </div>
                          <div>
                            <h3
                              style={{
                                color: theme.primary,
                              }}
                            >
                              $
                              {(
                                billingDetails?.data?.total +
                                Number(
                                  location?.enableIhd && method === "delivery"
                                    ? deliveryFee
                                    : 0
                                )
                              )?.toFixed(2)}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <Button
                        className="mt-3 py-3 mb-3"
                        block
                        disabled={billingDetails?.data?.disableOrdering}
                        style={{
                          backgroundColor: theme?.primary,
                          color: "white",
                          letterSpacing: "0.1em",
                          fontSize: 12,
                          borderRadius: 6,
                        }}
                        onClick={() => {
                          history.push(
                            "/" + pathname[1] + "/" + pathname[2] + "/checkout",
                            { fromFlow: true }
                          );
                        }}
                      >
                        PROCEED TO CHECKOUT
                      </Button>
                      {billingDetails?.data?.disableOrdering && (
                        <small>
                          NOTE: Minimum Subtotal should be $
                          {deliveryZone?.minimumDeliveryAmount}
                        </small>
                      )}
                    </>
                  ) : (
                    <div className="d-flex align-items-center flex-column">
                      <lottie-player
                        src="https://assets1.lottiefiles.com/packages/lf20_fkmiz05n.json"
                        background="transparent"
                        speed="1"
                        style={{ width: 100 }}
                        autoplay
                      ></lottie-player>

                      <h2 style={{ textTransform: "uppercase" }}>
                        Good Food is always cooking
                      </h2>
                      <span className="text-muted">Your cart is empty</span>
                    </div>
                  )}
                </>
              ) : (
                <div
                  style={{
                    marginTop: 40,
                  }}
                >
                  <Skeleton height="50px" width="100%" />
                  <Skeleton
                    style={{ marginTop: 10 }}
                    height="50px"
                    width="100%"
                  />
                  <Skeleton
                    style={{ marginTop: 10 }}
                    height="30px"
                    width="100%"
                  />
                  <Skeleton
                    style={{ marginTop: 10 }}
                    height="30px"
                    width="100%"
                  />
                  <Skeleton
                    style={{ marginTop: 10 }}
                    height="50px"
                    width="100%"
                  />
                  <Skeleton
                    style={{ marginTop: 20 }}
                    height="60px"
                    width="100%"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <EditDetailsModal
          theme={theme}
          isOpen={showEdit}
          toggle={() => setShowEdit(!showEdit)}
        />

        <CartModal
          isOpen={isOpenCart}
          toggle={() => setIsOpenCart(!isOpenCart)}
          history={history}
          theme={theme}
        />
        <GenericModal
          isOpen={open}
          title={
            restaurant?.restaurant?.pop_up_alerts?.category_popup?.isActive
              ? restaurant?.restaurant?.pop_up_alerts?.category_popup?.title
              : ""
          }
          toggle={() => setOpen(!open)}
          data={
            restaurant?.restaurant?.pop_up_alerts?.category_popup?.isActive
              ? restaurant?.restaurant?.pop_up_alerts?.category_popup?.message
              : ""
          }
        />
        {Object.keys(dish).length > 0 && !cateringData?.catering ? (
          <ViewDishModal
            theme={theme}
            onAddToCart={(op, notes, qty = 1) => {
              try {
                addToCart(
                  {
                    _id: dish?._id,
                    qty,
                    modifiers: op,
                    note: notes,
                  },
                  true
                );
              } catch (error) {
                logger.push({ error, info: "MainMenuSectionViewDish" });
                toast.error(error.message);
              }
            }}
            isOpen={openDish}
            dish={dish}
            toggle={() => setOpenDish(!openDish)}
          />
        ) : (
          <ViewDishModalCatering
            theme={theme}
            onAddToCart={(op, notes, qty = 1) => {
              try {
                addToCart(
                  {
                    _id: dish?._id,
                    qty,
                    modifiers: op,
                    note: notes,
                  },
                  true
                );
              } catch (error) {
                logger.push({ error, info: "MainMenuSectionViewDish" });
                toast.error(error.message);
              }
            }}
            isOpen={openDish}
            dish={dish}
            toggle={() => setOpenDish(!openDish)}
          />
        )}
      </div>
      {restaurant?.pop_up_alerts?.category_popup?.category_popup && (
        <Notification
          btnTitle={restaurant?.pop_up_alerts?.category_popup?.title}
          popupTitle={restaurant?.pop_up_alerts?.category_popup?.title}
          popupHeading={restaurant?.pop_up_alerts?.category_popup?.title}
          popupDescription={restaurant?.pop_up_alerts?.category_popup?.message}
          toggle={() => setHomeModal(!homeModal)}
          open={homeModal}
          origin="home"
        />
      )}
    </div>
  );
};

export default MenuMainSections;
