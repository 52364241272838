/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { Button, Card, Spinner } from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import swal from "sweetalert";
import CheckoutGraphic from "../../../../assets/icons/checkout_graphic.svg";
import TopNav from "../../../../components/Navbar/TopNav";
import { AuthContext } from "../../../../context";
import { useCart } from "../../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../../hooks/useEntity";
import { useCartStore } from "../../../../store";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import logger from "../../../../utils/logger";
import {
  GuestValidationSchema,
  GuestValidationWalkup,
} from "../../../../utils/schemas/Guest";
import getSliderSettings from "../../../../utils/sliderSettings";
import MenuHeader from "../../auth/components/MenuHeader";
import Footer from "../../components/Footer";
import ViewDishModal from "../../components/ViewDishModal";
import ViewDishModalCatering from "../../components/ViewDishModalCatering";
import CartSection from "./CartSection";
import CateringEnquiryCheckout from "./CateringEnquiryCheckout";
import Coupon from "./Coupon";
import CustomerDetails from "./CustomerDetails";
import Guest from "./Guest";
import LoginCustomer from "./LoginCustomer";
import LoyaltyBalance from "./LoyaltyBalance";
import MoreInfoCard from "./MoreInfoCard";
import OrderDetails from "./OrderDetails";
import PaymentMethodsCheckout from "./PaymentMethodsCheckout";
import Register from "./Register";
import SuggestedCard from "./SuggestedCard";
import Tip from "./Tip";
import UserButtons from "./UserButtons";
import useScreenSize from "../../../../hooks/useScreenSize";

const components = {
  "sign-in": LoginCustomer,
  guest: Guest,
  register: Register,
};

const CheckoutMain = ({ history, setTipAmount, allItems, menuId }) => {
  const myRef = useRef(null);
  const [activeTab, setActiveTab] = useState("sign-in");
  const authContext = useContext(AuthContext);
  const { create: getBilling, loading: billingLoading } = useEntity(
    "eat/ordering/billing"
  );
  const { width } = useScreenSize();
  const executeScroll = () => myRef.current.scrollIntoView();
  const { restaurant: orderingRestaurant } = useOrderingRestaurant();
  const { cartItems, coupon } = useCartStore();
  const {
    location: orderingLocation,
    method,
    laterDate,
    laterTime,
    timing,
    deliveryConfirmAddress,
    gateway,
    deliveryZone,
    tableNo,
    deliveryMethod,
    setActiveTab: setZustandActiveTab,
    cateringData,
    setCateringData,
  } = useOrdering();
  const { restaurant } = useOrderingRestaurant();

  const [openDish, setOpenDish] = useState(false);
  const [checkoutSessionLoading, setCheckoutSessionLoading] = useState(false);
  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [dish, setDish] = useState(null);
  const paymentFormRef = useRef();
  const { theme } = useTheme();
  const { addToCart } = useCart();
  const { create: createOrder } = useEntity("eat/ordering/order");
  const [timeLeft, setTimeLeft] = useState(() =>
    JSON.parse(localStorage.getItem("timeLeft"))
  );
  const [isDisabled, setIsDisabled] = useState(true);

  const [couponDoc, setCouponDoc] = useState();

  const { create: sendCheckoutOtp } = useEntity("2fa");
  const { create: sendVerificationCode } = useEntity("2fa/verify");

  const { entities: moreInfoData, find: findMoreInfo } = useEntity(
    "eat/ordering/more-info"
  );

  const { handleChange, values, errors, handleSubmit, touched } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      note: "",
    },
    onSubmit: (values) => {
      //setting guest details to order formik
      orderFormik.setFieldValue("guest_details", { ...values });

      //submit order if guest values is there

      if (
        gateway?.gateway === "stripe" &&
        orderFormik?.values?.payment_method !== "pay-there"
      ) {
        return;
      }

      if (orderFormik.values.payment_method === "new-card") {
        paymentFormRef?.current?.handleSubmit();
      } else {
        orderFormik.handleSubmit();
      }
    },
    validationSchema:
      method === "walkup" ? GuestValidationWalkup : GuestValidationSchema,
    validateOnMount: true,
  });

  const orderFormik = useFormik({
    initialValues: {
      coupon: "",
      tip: "",
      loyaltyPoints: "",
      orderNotes: "",
      billing: null,
      payment_method: "",
      selected_card: "",
      guest_details: {},
      moreInfoData: {},
      saveCard: false,
    },
    onSubmit: async (values) => {
      const splittedTime = laterTime.split(":");
      const scheduleDate = new Date(laterDate);
      scheduleDate.setHours(splittedTime[0], splittedTime[1], 0, 0);

      const response = moreInfoData?.data?.flatMap((moreInfo) => {
        return checkRequiredFields(moreInfo?.subInfo, values.moreInfoData);
      });

      if (response[0]?.length > 0) {
        toast.error(response[0]);
        return;
      }

      let dateString;
      if (timing === "later") {
        dateString = new Date(laterDate);
        const splittedTime = laterTime.split(":");
        dateString.setHours(splittedTime[0], splittedTime[1]);
      }

      let data = {
        customer: authContext?.user?.customer?._id,
        guest: { ...values?.guest_details },
        restaurant: orderingRestaurant?._id,
        method: method,
        orderTiming: timing,
        note: values?.orderNotes || values?.guest_details?.note,
        items: cartItems,
        // coupon: values?._id,
        paymentMethod: values?.payment_method,
        scheduledOn: timing === "later" ? scheduleDate.toISOString() : "",
        schedule: timing === "later" ? dateString : undefined,
        location: orderingLocation?._id,
        deliveryAddress: method === "delivery" ? deliveryConfirmAddress : null,
        tableNo: method === "dine-in" ? tableNo : "",
        // headCount: method === "catering" ? count : 0,
        loyalty: values.loyaltyPoints || 0,
        tip: values?.billing?.tip || 0,
        // tableNo,
        deliveryFee:
          (method === "delivery" && values.billing?.deliveryFee) || 0,
        deliveryZoneId: deliveryZone._id,
        deliveryMethod,
        loyaltyDiscount: values?.billing?.loyaltyDiscount,
        orderFee: values?.billing?.orderFee || 0,
        taxDetails: {
          appliedCoupon: values.billing.appliedCoupon,
          serviceCharge: values.billing.serviceCharge,
          taxDetails: values.billing.taxDetails,
        },
        moreInfoData: values.moreInfoData,
        menuId,
        cateringDetails: {
          ...cateringData,
        },
      };

      try {
        // await OrderValidationSchema.validate(data);

        if (
          values.payment_method === "saved-card" ||
          values.payment_method === "new-card"
        ) {
          data["selected_payment_method"] =
            values.payment_method === "saved-card" &&
            gateway?.gateway !== "stripe" &&
            gateway?.gateway !== "bridgepay"
              ? values?.selected_card?._id
              : values?.selected_card;

          data["gateway"] = gateway?._id;
        }

        if (
          gateway?.gateway === "bridgepay" &&
          values.saveCard &&
          values.payment_method === "new-card"
        ) {
          data["selected_payment_method"] = {
            ...values?.selected_card,
            saveCard: values.saveCard,
          };
        }

        const order = await createOrder({ ...data });

        setCateringData({});

        if (cateringData?.enquiry) {
          history.push(`success?orderId=${order?.data?._id}&orderType=enquiry`);
        }

        if (
          gateway?.gateway === "bridgepay" &&
          (values.payment_method === "saved-card" ||
            values.payment_method === "new-card")
        ) {
          const rest = window.location.pathname.split("/")[2];
          window.location.replace(
            `${window.location.origin}/ordering/${rest}/success?orderId=${order?.data?._id}`
          );
        } else history.push(`success?orderId=${order?.data?._id}`);
      } catch (error) {
        logger.push({ error, info: "CheckoutMain" });

        if (error.message === "payment_failed") {
          toast.error("Payment failed. Please contact administrator");
        } else if (error.message?.includes?.("ms_order_failed")) {
          swal({
            title: "Failed to place order",
            text: "We are unable to send your order to the restaurant. They are offline. Please call the restaurant to place your order",
          });
        } else if (error.message === "location_closed") {
          toast.error(
            "Sorry, The Restaurant is closed right now. Please try again later."
          );
        } else {
          toast.error(error.message);
        }
      }
    },
  });

  function checkRequiredFields(subInfoArray, userEnteredData) {
    const missingFields = [];
    for (let i = 0; i < subInfoArray.length; i++) {
      const subInfo = subInfoArray[i];
      if (subInfo.required && subInfo.type !== "checkbox") {
        const enteredValue = userEnteredData[subInfo.label];
        if (enteredValue === undefined || enteredValue === "") {
          missingFields.push(subInfo.label);
        }
      } else if (subInfo.required && subInfo.type === "checkbox") {
        const enteredValue = userEnteredData[subInfo.label];
        if (
          enteredValue?.length <= 0 ||
          enteredValue === undefined ||
          enteredValue === ""
        ) {
          missingFields.push(subInfo.label);
        }
      }
    }
    if (missingFields.length > 0) {
      return `Please fill these required fields: ${missingFields.join(", ")}`;
    }
    return "";
  }

  const getBillingDetails = async () => {
    try {
      const billingResponse = await getBilling({
        data: [...cartItems],
        tip: orderFormik.values?.tip,
        coupon: coupon?._id || orderFormik.values?.coupon?._id,
        points: orderFormik.values?.loyaltyPoints,
        location: orderingLocation?._id,
        restaurant: restaurant?._id,
        method: method,
        customer: authContext?.user?.customer?._id,
        address: deliveryConfirmAddress,
        deliveryZoneId: deliveryZone?._id,
        deliveryMethod,
        catering: cateringData?.catering,
        menuId,
      });

      orderFormik.setFieldValue("billing", billingResponse?.data);
    } catch (error) {
      logger.push({ error, info: "CheckoutMainBillingApi" });
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (orderingLocation._id && restaurant?._id) {
      getBillingDetails().then((res) => console.log(res));
    }
  }, [
    orderFormik.values.coupon,
    cartItems,
    orderFormik.values.tip,
    orderFormik.values.loyaltyPoints,
    orderingLocation,
    restaurant,
    coupon,
    menuId,
  ]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem("timeLeft", JSON.stringify(newTime));
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setIsDisabled(false);
      localStorage.removeItem("timeLeft");
    }
  }, [timeLeft]);

  const sentOtpDoc = JSON.parse(window.localStorage.getItem("otpDoc"));

  const Component = components[activeTab];

  useEffect(() => {
    findMoreInfo({
      restaurant: restaurant?.userId,
      location: orderingLocation?._id,
    });
  }, []);

  useEffect(() => {
    if (authContext?.user) {
      setActiveTab("logged_in");
      setZustandActiveTab("logged_in");
    } else {
      setActiveTab(method === "walkup" ? "guest" : "sign-in");
      setZustandActiveTab(method === "walkup" ? "guest" : "sign-in");
    }
  }, [authContext?.user]);

  useEffect(() => {
    setTipAmount(orderFormik.values.tip);
  }, [orderFormik.values.tip]);

  useEffect(() => {
    setPhone(sentOtpDoc?.phone || "");
  }, [localStorage]);

  const handleFormInfoChange = (data) => {
    orderFormik.setFieldValue("moreInfoData", data);
  };

  if (cateringData?.enquiry) {
    return (
      <CateringEnquiryCheckout
        menuId
        history={history}
        setTipAmount={() => {}}
      />
    );
  }

  const isAuthenticated = JSON.parse(
    window.localStorage.getItem("checkoutSessionData")
  );

  const handleSendOtp = async () => {
    try {
      setCheckoutSessionLoading(true);

      const payload = {
        phone: authContext?.user?.customer?.phone,
        restaurant: restaurant?._id,
        customer: authContext?.user?.customer?._id,
        user: authContext?.user?.customer?.userId,
        sentAt: new Date(),
      };

      await sendCheckoutOtp(payload);

      window.localStorage.setItem("otpDoc", JSON.stringify(payload));

      const newTime = 30;
      setTimeLeft(newTime);
      localStorage.setItem("timeLeft", JSON.stringify(newTime));
      setIsDisabled(true);

      setCheckoutSessionLoading(false);
      toast.success("OTP sent successfully");
    } catch (error) {
      toast.error(error?.message);
      setCheckoutSessionLoading(false);
    }
  };

  const verifyOtp = async () => {
    try {
      setVerifyOtpLoading(true);
      const payload = {
        phone: authContext?.user?.customer?.phone,
        restaurant: restaurant?._id,
        customer: authContext?.user?.customer?._id,
        user: authContext?.user?.customer?.userId,
        code,
        resourceId: "checkout-session-otp",
        sessionId: isAuthenticated?.data?._id,
      };

      const sess = await sendVerificationCode(payload);

      window.localStorage.setItem("checkoutSessionData", JSON.stringify(sess));

      window.localStorage.removeItem("otpDoc");

      setVerifyOtpLoading(false);
      toast.success("OTP verified successfully");
    } catch (error) {
      toast.error(error?.message);
      setVerifyOtpLoading(false);
    }
  };

  const handleResendClick = () => {
    handleSendOtp();
    const newTime = 30;
    setTimeLeft(newTime);
    localStorage.setItem("timeLeft", JSON.stringify(newTime));
    setIsDisabled(true);
  };

  return (
    <div>
      <TopNav
        tip={orderFormik.values.tip}
        cart={true}
        startOver={true}
        history={history}
      />
      <div className="only_tab">
        <MenuHeader />
      </div>
      <div className="text-left my-3 mx-3" style={{ minHeight: "100vh" }}>
        <h1 style={{ fontWeight: "400", fontSize: 28 }}>Checkout</h1>
        {!authContext?.user && (
          <UserButtons activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
        {authContext?.user && (
          <CustomerDetails
            getOrderNotes={(val) =>
              orderFormik.setFieldValue("orderNotes", val)
            }
          />
        )}
        {!authContext?.user && (
          <div className="mt-4" ref={myRef}>
            <Component
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              toggle={() => setActiveTab("sign-in")}
            />
          </div>
        )}

        <div className={`small_cards_checkout ${"mb-4"}`}>
          {restaurant?.ordering?.allowTip && (
            <Tip
              billingLoading={billingLoading}
              tip={orderFormik.values.tip}
              total={
                orderFormik.values?.billing?.subTotal +
                orderFormik.values?.billing?.tax
              }
              setTip={(val) => orderFormik.setFieldValue("tip", val)}
              disabled={!restaurant?.ordering?.allowTip}
            />
          )}
          <Coupon
            subtotal={orderFormik.values?.billing?.subTotal}
            setCoupons={(val) => orderFormik.setFieldValue("coupon", val)}
            discount={orderFormik.values?.billing?.appliedCoupon?.discount}
            loading={billingLoading}
            getCouponDetails={(val) => setCouponDoc(val)}
          />
        </div>
        {authContext?.user && restaurant?.ordering?.allowLoyaltyTransaction && (
          <>
            <LoyaltyBalance
              billingLoading={billingLoading}
              billing={orderFormik.values.billing}
              onsubmit={(val) => {
                orderFormik.setFieldValue("loyaltyPoints", val);
              }}
            />
          </>
        )}
        <Card
          className="px-3 py-3"
          style={{
            boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            className="d-flex justify-content-between"
            style={{ overflow: "none", height: "max-content" }}
          >
            <div className="right_border" style={{ flex: 1.5 }}>
              <div className="py-4">
                <h1
                  style={{
                    fontWeight: "600",
                    color: theme.primary,
                  }}
                >
                  Order Details
                </h1>
                <hr className="my-4" style={{ padding: 0, margin: 0 }} />
                <OrderDetails />
                <hr
                  className="my-4 hide_mobile"
                  style={{ padding: 0, margin: 0 }}
                />
              </div>
              <CartSection
                billingDetails={orderFormik.values.billing}
                method={method}
                loading={billingLoading}
                couponDoc={couponDoc}
                allItems={allItems}
              />
            </div>
            <div className="hide mx-5"></div>
            <div
              className="hide "
              style={{
                overflow: "none",
                width: "100%",
                flex: 1,
                backgroundImage: `url(${CheckoutGraphic})`,
                backgroundSize: "cover",
              }}
            ></div>
          </div>
        </Card>

        {moreInfoData?.data?.map((data) => {
          return (
            <MoreInfoCard
              handleFormInfoChange={handleFormInfoChange}
              data={data}
            />
          );
        })}

        {allItems?.length !== 0 && (
          <>
            <div style={styles.suggestedProductStyle} className="px-3 py-3">
              <h1
                style={{
                  fontWeight: "600",
                  color: theme.primary,
                }}
              >
                Suggested Products
              </h1>
              <hr style={{ padding: 0, margin: 0 }} />

              <div style={{ width: "100%" }}>
                <div
                  className="slider_container_slick py-3"
                  style={styles.sliderStyle}
                >
                  <Slider {...getSliderSettings(allItems)}>
                    {allItems?.slice(0, 5)?.map((d, i) => {
                      return (
                        <SuggestedCard
                          onClick={() => {
                            setOpenDish(true);
                            setDish(d);
                          }}
                          data={d}
                        />
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </>
        )}
        {!isAuthenticated?.data?.isAuthenticated &&
        authContext?.user &&
        restaurant?.enable2fa ? (
          <div
            style={{
              ...styles.suggestedProductStyle,
              marginTop: 20,
              marginBottom: 20,
            }}
            className="px-3 py-3"
          >
            <h1
              style={{
                fontWeight: "600",
                color: theme.primary,
              }}
            >
              Verification
            </h1>
            <hr style={{ padding: 0, margin: 0 }} />
            <div className="mt-2">
              {" "}
              <div>
                We added an extra layer of protection for extra security.
              </div>
              <div>
                Please verify by sending a one-time passcode to your mobile
                phone and entering it here.
              </div>
            </div>
            <div
              className="d-flex "
              style={{
                justifyContent: "space-between",
                flexDirection: width <= 550 ? "column" : "row",
              }}
            >
              <div
                className="mt-3 px-2 py-3"
                style={{
                  background: "#ededed",
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <input
                  style={{
                    border: "none",
                    background: "transparent",
                    outline: "none",
                    width: "100%",
                  }}
                  onChange={(e) => setPhone(e.target.value)}
                  type="number"
                  placeholder="Phone number"
                  disabled={true}
                  value={authContext?.user?.customer?.phone}
                />
              </div>
              {!sentOtpDoc && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: width <= 500 ? "flex-start" : "flex-end",
                    marginLeft: width <= 550 ? 0 : 10,
                  }}
                >
                  <Button
                    className="mt-3"
                    style={{
                      backgroundColor: theme?.primary,
                      color: "white",
                      textTransform: "uppercase",
                      letterSpacing: "0.1em",
                      width: width > 550 && 200,
                    }}
                    onClick={handleSendOtp}
                    disabled={timeLeft > 0}
                  >
                    {checkoutSessionLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      "Send Code"
                    )}
                  </Button>
                </div>
              )}
            </div>
            {timeLeft > 0 && (
              <div id="timer">Resend in: {timeLeft} seconds</div>
            )}
            {!timeLeft && sentOtpDoc && (
              <Button
                onClick={handleResendClick}
                disabled={isDisabled}
                className="mt-2"
                size="sm"
                style={{
                  color: theme.primary,
                  cursor: "pointer",
                  boxShadow: "none",
                  backgroundColor: "white",
                  outline: "none",
                  border: "none",
                }}
              >
                Resend
              </Button>
            )}
            <div
              className="d-flex "
              style={{
                justifyContent: "space-between",
                flexDirection: width <= 550 ? "column" : "row",
              }}
            >
              <div
                className="mt-3 px-2 py-3 d-flex justify-content-between align-items-center"
                style={{ background: "#ededed", flex: 1 }}
              >
                <input
                  style={{
                    border: "none",
                    background: "transparent",
                    outline: "none",
                    width: "100%",
                  }}
                  type="text"
                  placeholder="Enter code"
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: width <= 500 ? "flex-start" : "flex-end",
                  marginLeft: width <= 550 ? 0 : 10,
                }}
              >
                <Button
                  className="mt-3 "
                  style={{
                    backgroundColor: theme?.primary,
                    color: "white",
                    textTransform: "uppercase",
                    letterSpacing: "0.1em",
                    width: width > 550 && 200,
                  }}
                  onClick={verifyOtp}
                >
                  {verifyOtpLoading ? <Spinner size="sm" /> : "Verify"}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {!orderFormik.values.billing?.disableOrdering &&
              !restaurant?.ordering?.viewOnly && (
                <div className="mt-4">
                  <PaymentMethodsCheckout
                    paymentFormRef={paymentFormRef}
                    formik={orderFormik}
                    handleSelectCard={(data) => {
                      orderFormik.setFieldValue("selected_card", data);
                    }}
                    onTokenizationSuccess={(data) => {
                      orderFormik.setFieldValue("selected_card", data);
                      orderFormik.handleSubmit();
                    }}
                    handleChangeMethod={(method) =>
                      orderFormik.setFieldValue("payment_method", method)
                    }
                    guestFormik={handleSubmit}
                    guestErrors={errors}
                    guestRef={myRef}
                    isSaveCard={(va) =>
                      orderFormik.setFieldValue("saveCard", va)
                    }
                    authMethod={activeTab}
                  />
                  {!authContext.user && activeTab !== "guest" && (
                    <small style={{ color: "red", fontSize: 16 }}>
                      Please Sign in, Register or choose guest to pay with card.
                    </small>
                  )}
                </div>
              )}
            {((gateway?.gateway !== "stripe" &&
              gateway?.gateway !== "bridgepay") ||
              orderFormik.values.payment_method === "pay-there") &&
              !restaurant?.ordering?.viewOnly && (
                <div className="mt-4">
                  <div className="text-red">
                    {(orderFormik.values.payment_method === "new-card" ||
                      orderFormik.values.payment_method === "saved-card") &&
                      !gateway &&
                      "Gateway isn't configured. please system administrator."}
                  </div>

                  <Button
                    className="py-3"
                    block
                    style={{
                      ...styles.btnStyle,
                      backgroundColor: theme.primary,
                      color: "white",
                    }}
                    disabled={
                      orderFormik?.values?.billing?.disableOrdering ||
                      cartItems?.length <= 0 ||
                      billingLoading ||
                      activeTab === "sign-in" ||
                      activeTab === "register" ||
                      (orderFormik.values.payment_method === "saved-card" &&
                        orderFormik.values.selected_card === "") ||
                      ((orderFormik.values.payment_method === "new-card" ||
                        orderFormik.values.payment_method === "saved-card") &&
                        !gateway) ||
                      orderFormik.isSubmitting
                    }
                    onClick={() => {
                      orderFormik.setSubmitting(true);
                      if (
                        Object.keys(errors).length === 0 ||
                        authContext?.user?.customer
                      ) {
                        if (!authContext?.user) {
                          handleSubmit();
                          return;
                        }
                        if (orderFormik.values.payment_method === "new-card") {
                          paymentFormRef?.current?.handleSubmit();
                          return;
                        }
                        orderFormik.handleSubmit();
                      } else {
                        executeScroll();
                        orderFormik.setSubmitting(false);
                        handleSubmit();
                        return;
                      }
                    }}
                  >
                    {orderFormik.isSubmitting ? (
                      <Spinner size={"sm"} />
                    ) : (
                      "Place Order"
                    )}
                  </Button>
                  {orderFormik?.values?.billing?.disableOrdering && (
                    <small className="text-lg mt-4">
                      NOTE: Minimum Subtotal should be $
                      {deliveryZone?.minimumDeliveryAmount}
                    </small>
                  )}
                </div>
              )}

            <hr />
          </>
        )}

        <div className="mb-8">
          <Footer showCardLogo={!restaurant?.ordering?.viewOnly} />
        </div>

        {dish && !cateringData?.catering ? (
          <ViewDishModal
            theme={theme}
            onAddToCart={(op, notes, qty) => {
              try {
                addToCart(
                  {
                    _id: dish?._id,
                    qty,
                    modifiers: op,
                    note: notes,
                    availableQty: dish?.qty,
                  },
                  true
                );
              } catch (error) {
                logger.push({ error, info: "SuggestedItemCheckout" });
                toast.error(error.message);
              }
            }}
            isOpen={openDish}
            dish={dish}
            toggle={() => setOpenDish(!openDish)}
          />
        ) : dish ? (
          <ViewDishModalCatering
            theme={theme}
            onAddToCart={(op, notes, qty) => {
              try {
                addToCart(
                  {
                    _id: dish?._id,
                    qty,
                    modifiers: op,
                    note: notes,
                    availableQty: dish?.qty,
                  },
                  true
                );
              } catch (error) {
                logger.push({ error, info: "SuggestedItemCheckout" });
                toast.error(error.message);
              }
            }}
            isOpen={openDish}
            dish={dish}
            toggle={() => setOpenDish(!openDish)}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const styles = {
  btnStyle: {
    color: "white",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
  },
  sliderStyle: {
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  suggestedProductStyle: {
    boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    borderRadius: "6px",
  },
};

export default CheckoutMain;
