import * as Yup from "yup"

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Required")
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .min(2),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required")
    .min(10, "cannot be less than 10")
    .max(13, "cannot be more than 13"),
  email: Yup.string().trim().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .matches(/^\S*$/, "Whitespace is not allowed")
    .min(6),
  // birthday: Yup.string().required("Birth date is required"),
  // birthMonth: Yup.string().required("Birth Month is required"),
  // anniversary: Yup.string().required("Anniversary is required"),
  // anniversaryMonth: Yup.string().required("Anniversary Month is required"),
})
