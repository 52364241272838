/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import * as Yup from "yup";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { useRestaurantStore } from "../../../store/restaurant";
import convertSelectedParentValue from "../../../utils/convertSelectedParentValue";
import findMatchingIndexes from "../../../utils/findMatchingElems";
import getGroupedRows from "../../../utils/ungroupSelectedParentValues";
import AddModifiersRow from "./components/AddModifiersRow";
import { getAlphabeticalOrderArray } from "../../../utils/getAlphbeticalSortedArray";

export let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

// const getSortedValues = (array) => {
//   return array;
// };

const AddEditModifiersTab = ({ handleChange, modifiers = {} }) => {
  const { entities, find } = useEntity("modifier-group");
  const { restaurant } = useAdminStore();
  const [selectedGroup, setSelectedGroup] = useState("");
  const authContext = useContext(AuthContext);
  const [selectedParentModifier, setSelectedParentModifier] = useState({});
  const [selectedWholeValue, setSelectedWholeValue] = useState({});

  const [updatedParentModifierValues, setUpdatedParentModifierValues] =
    useState([]);
  const { restaurant: restaurantDoc } = useRestaurantStore();
  const [isSortOrderChanged, setIsSortOrderChanged] = useState(false);

  // const handleSaveSortOrder = async () => {
  //   const IdsAndPos = modifiers[selectedGroup]?.subProducts.map((d, idx) => {
  //     const obj = { ...d, sort: idx };
  //     return obj;
  //   });

  //   modifiers[selectedGroup].subProducts = IdsAndPos;

  //   setIsSortOrderChanged(false);
  // };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    let items = convertSelectedParentValue(
      modifiers[selectedGroup].subProducts,
      modifiers[selectedGroup].advancedPizzaOptions
    );

    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    const allRows = getGroupedRows(items);

    const sortedRows = allRows.map((row, i) => {
      return {
        ...row,
        selectedParentValue: row.selectedParentValue,
        sort: i,
      };
    });

    modifiers[selectedGroup].subProducts = sortedRows;
    handleChange({ ...modifiers });

    setIsSortOrderChanged(true);
  };

  const handleDragEndModifierGroups = async (result) => {
    if (!result.destination) return;

    const items = Object.values(modifiers);

    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    const modifierObj = {};

    items.forEach((element, index) => {
      element.sortOrder = index;
      modifierObj[element.modifierId] = { ...element };
    });

    const sortedItems = items.sort((a, b) => a.sortOrder - b.sortOrder);

    sortedItems.forEach((element, index) => {
      modifierObj[element.modifierId] = { ...element };
    });

    for (const element of sortedItems) {
      modifierObj[element.modifierId] = { ...element };
    }

    // Convert object to array for sorting
    const sortedArray = Object.entries(modifierObj).sort(
      (a, b) => a[1].sortOrder - b[1].sortOrder
    );

    // Convert sorted array back to object
    const sortedJsonData = Object.fromEntries(sortedArray);

    handleChange({ ...sortedJsonData });
  };

  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;

  const formik = useFormik({
    initialValues: {
      product_id: "",
      product_name: "",
      price: 0,
      sort: 0,
      halfPrice: 0,
      extraPrice: 0,
      subModifiers: [],
      defaultSelected: false,
      selectedParentValue: { label: "", value: "" },
      selectedParentValues: [],
      jkSoftId: "",
    },
    validationSchema: Yup.object({
      product_id: Yup.string().required("Please select modifier"),
      price: Yup.number().max(999, "maximum 999").required("Is required"),
      halfPrice: Yup.number().max(999, "maximum 999").required("Is required"),
      extraPrice: Yup.number().max(999, "maximum 999").required("Is required"),
      sort: Yup.number()
        .min(0, "cannot be less than 0")
        .max(99, "Cannot be grater than 99"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (values?.subModifiers?.length > 0) {
        modifiers[selectedGroup].enableQtyUpdate = false;
        modifiers[selectedGroup].advancedPizzaOptions = false;
      }
      if (values.price === "") {
        values.price = 0;
      }

      if (
        modifiers[selectedGroup].enableParentModifier &&
        values?.selectedParentValue?.label === ""
      ) {
        toast.error("Please select parent modifier value");
        return;
      }

      if (Object.keys(modifiers)?.length > 0) {
        let arr = modifiers[selectedGroup]?.subProducts || [];

        let index;
        if (modifiers[selectedGroup].enableParentModifier) {
          index =
            arr?.length > 0
              ? arr?.findIndex(
                  (it) =>
                    it?.product_id === values?.product_id &&
                    it?.selectedParentValue?.value ===
                      values?.selectedParentValue?.value
                )
              : -1;
        } else
          index =
            arr?.length > 0
              ? arr?.findIndex((it) => it?.product_id === values?.product_id)
              : -1;

        if (index !== -1) {
          arr[index] = values;
        } else {
          arr.push(values);
        }

        modifiers[selectedGroup] = {
          ...modifiers[selectedGroup],
          subProducts: arr,
        };
      } else {
        let obj = {};
        obj[selectedGroup] = {
          ...modifiers[selectedGroup],
          subProducts: [values],
        };
        handleChange(obj);
      }
      resetForm();
    },
  });

  useEffect(() => {
    find({
      restaurant: restaurant || authRest,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
  }, [restaurant]);

  const handleSelectOption = (e) => {
    let op = entities?.data?.find((o) => o._id === e.value);

    let obj = {};
    obj[op._id] = {
      name: op?.name,
      isRequired: false,
      isHidden: false,
      enableQtyUpdate: false,
      type: op?.type,
      sortOrder: op?.sortOrder,
      subProducts: [],
      min: "",
      max: "",
      advancedPizzaOptions: op.advancedPizzaModifiers,
      defaultSelected: op?.defaultSelected,
      parentModifier: "",
      enableParentModifier: false,
      modifierId: op._id,
    };

    handleChange({ ...modifiers, ...obj });
  };

  const handleEditModifier = (data) => {
    if (Object.keys(modifiers).length > 0) {
      let arr = modifiers[selectedGroup]?.subProducts || [];

      let indexes = arr
        .flatMap((it, i) => {
          if (
            it.product_id === data.product_id &&
            !modifiers[selectedGroup]?.enableParentModifier
          ) {
            return i;
          } else if (
            it.product_id === data.product_id &&
            modifiers[selectedGroup]?.enableParentModifier
          ) {
            const out = findMatchingIndexes(arr, data);

            return out;
          }
        })
        .filter((index) => index !== -1 && index !== undefined);

      indexes.forEach((index) => {
        arr[index].price = Number(data.price);
        arr[index].defaultSelected = data.defaultSelected;
        arr[index].halfPrice = data.halfPrice;
        arr[index].extraPrice = data.extraPrice;
        arr[index].sort = data.sort;
      });

      if (indexes.length === 0) {
        arr.push({
          price: Number(data.price),
          ...data,
          defaultSelected: data.defaultSelected,
        });
      }

      modifiers[selectedGroup] = {
        subProducts: arr,
        ...modifiers[selectedGroup],
      };

      handleChange(modifiers);
    }
  };

  useEffect(() => {
    if (entities && modifiers[selectedGroup]?.parentModifier) {
      const findItem = entities?.data?.find(
        (mod) => mod?.name === modifiers[selectedGroup].parentModifier
      );
      setSelectedParentModifier({
        label: findItem?.name,
        value: findItem?._id,
        subProducts: findItem?.subProducts,
      });
    }
  }, [modifiers, entities, selectedGroup]);

  useEffect(() => {
    if (
      modifiers[selectedGroup]?.subProducts?.length > 0 &&
      modifiers[selectedGroup]?.enableParentModifier
    ) {
      const itemWithSelectedModifierValue = modifiers[
        selectedGroup
      ]?.subProducts?.map((pro) => {
        if (pro?.product_name === formik?.values?.product_name) {
          return pro?.selectedParentValue?.label;
        }
      });

      const parentModifierValues = selectedParentModifier?.subProducts?.filter(
        (parent) => !itemWithSelectedModifierValue.includes(parent.name)
      );

      setUpdatedParentModifierValues(parentModifierValues);
    } else setUpdatedParentModifierValues(selectedParentModifier?.subProducts);
  }, [modifiers, formik.values]);

  useEffect(() => {
    if (modifiers[selectedGroup]?.parentModifier === "") {
      setSelectedParentModifier({});
    }
  }, [modifiers, selectedGroup]);

  const filterWithType = (arr, type, name) => {
    const newArray = arr?.filter(
      (item) => item?.type === type && item.name !== name
    );
    return newArray || [];
  };

  const handleModifierAdd = () => {
    const { values, resetForm } = formik;
    console.log(formik, "ARRAY");
    if (
      formik?.values.selectedParentValues?.length === 0 ||
      !formik.values.product_id ||
      formik.values.product_id === ""
    ) {
      formik.handleSubmit();
      return;
    }
    formik.values.selectedParentValues.map((selectedParentValue) => {
      if (values?.subModifiers?.length > 0) {
        modifiers[selectedGroup].enableQtyUpdate = false;
        modifiers[selectedGroup].advancedPizzaOptions = false;
      }
      if (values.price === "") {
        values.price = 0;
      }

      if (
        modifiers[selectedGroup].enableParentModifier &&
        selectedParentValue?.label === ""
      ) {
        toast.error("Please select parent modifier value");
        return;
      }

      if (Object.keys(modifiers)?.length > 0) {
        let arr = modifiers[selectedGroup]?.subProducts || [];

        let index;
        if (modifiers[selectedGroup].enableParentModifier) {
          index =
            arr?.length > 0
              ? arr?.findIndex(
                  (it) =>
                    it?.product_id === values?.product_id &&
                    it?.selectedParentValue?.value ===
                      selectedParentValue?.value
                )
              : -1;
        } else
          index =
            arr?.length > 0
              ? arr?.findIndex((it) => it?.product_id === values?.product_id)
              : -1;

        if (index !== -1) {
          arr[index] = {
            ...values,
            selectedParentValue,
            defaultSelected: values.defaultSelected,
          };
        } else {
          arr.push({
            ...values,
            selectedParentValue,
            defaultSelected: values.defaultSelected,
          });
        }
        modifiers[selectedGroup] = {
          subProducts: arr,
          ...modifiers[selectedGroup],
        };

        console.log(arr, "ARRAY ONE");

        handleChange(modifiers);
      } else {
        let obj = {};
        obj[selectedGroup] = {
          subProducts: [values],
          ...modifiers[selectedGroup],
        };

        console.log([values], "ARRAY TWO");

        handleChange(obj);
      }
    });
    resetForm();
  };

  useEffect(() => {
    const mainModifierId = modifiers[selectedGroup]?.mainModifierId;
    const mainModifier = Object.values(modifiers)?.find((item) => {
      return item?.modifierId === mainModifierId;
    });

    setSelectedWholeValue({
      label: mainModifier?.name,
      value: mainModifier?.modifierId,
    });
  }, [modifiers, selectedGroup]);

  return (
    <Row style={{ minHeight: 300, marginBottom: 100 }}>
      <Col md="4">
        <FormGroup style={{ textAlign: "left" }}>
          <Row className="align-items-center">
            <Col md="4">
              <label
                style={{ fontSize: 15, fontWeight: "600", textAlign: "left" }}
              >
                Modifiers
              </label>
            </Col>
            <Col md="8">
              <Select
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                isSearchable={true}
                value={null}
                menuPortalTarget={document.body}
                options={entities?.data?.map((item, index) => {
                  return { label: item?.name, value: item?._id };
                })}
                onChange={(e) => {
                  handleSelectOption(e);
                }}
              />
            </Col>
          </Row>
          <DragDropContext onDragEnd={handleDragEndModifierGroups}>
            <Droppable droppableId="modifier_list_1">
              {(provided, snapshot) => (
                <div
                  className="mt-4"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <ListGroup>
                    {Object?.values(modifiers)
                      ?.sort((a, b) => a.sortOrder - b.sortOrder)
                      ?.map((key, i) => {
                        return (
                          <Draggable
                            key={key?.modifierId}
                            draggableId={key?.modifierId}
                            index={i}
                          >
                            {(provided) => (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <ListGroupItem
                                  onClick={() => {
                                    formik.setFieldValue("product_id", "");
                                    formik.setFieldValue("product_name", "");
                                    setSelectedGroup(key?.modifierId);
                                  }}
                                  className={
                                    snapshot.isDraggingOver &&
                                    selectedGroup === key?.modifierId
                                      ? "dragging active"
                                      : selectedGroup === key?.modifierId
                                      ? "active"
                                      : "dragging"
                                  }
                                  style={{ padding: 5, cursor: "pointer" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      // justifyContent: "space-between",
                                      padding: 5,
                                    }}
                                  >
                                    <i
                                      onClick={() => {
                                        delete modifiers[selectedGroup];
                                        handleChange({ ...modifiers });
                                      }}
                                      className="fa fa-minus-circle ml-2"
                                    />
                                    <span className="ml-2">
                                      {key?.name?.length > 20
                                        ? key?.name?.substring(0, 35) + "..."
                                        : key?.name}
                                    </span>
                                  </div>
                                </ListGroupItem>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </ListGroup>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </FormGroup>
      </Col>

      <Col md="8">
        {Object.keys(modifiers)?.length > 0 && selectedGroup?.length === 0 && (
          <span style={{ fontSize: 20 }}>
            Select modifiers to start editing
          </span>
        )}
        {selectedGroup?.length > 0 && Object.keys(modifiers)?.length > 0 && (
          <div>
            <Form>
              <FormGroup>
                <Row className="align-items-center">
                  <Col md="4" className="text-right">
                    <label
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      Clover POS
                    </label>
                  </Col>
                  <Col md="1">
                    <label className="custom-toggle">
                      <input
                        onChange={(e) => {
                          modifiers[selectedGroup].cloverPOS = e.target.checked;
                          handleChange({ ...modifiers });
                        }}
                        checked={
                          modifiers[selectedGroup]?.cloverPOS !== undefined &&
                          modifiers[selectedGroup]?.cloverPOS
                        }
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </Row>
              </FormGroup>
              {modifiers[selectedGroup]?.cloverPOS && (
                <>
                  <FormGroup>
                    <Row className="align-items-start">
                      <Col md="4" className="text-right">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                            textAlign: "right",
                          }}
                        >
                          Pizza Side
                        </label>
                      </Col>
                      <Col md="1">
                        {["Left", "Right", "Whole"].map((item) => {
                          return (
                            <FormGroup check>
                              <Input
                                name={`cloverPOSSide`}
                                id={`cloverPOS${item}-radio`}
                                type="radio"
                                checked={
                                  modifiers[selectedGroup]?.[
                                    `cloverPOS${item}`
                                  ] !== undefined &&
                                  modifiers[selectedGroup]?.[`cloverPOS${item}`]
                                }
                                onChange={(e) => {
                                  const newModifiers = {
                                    ...modifiers[selectedGroup],
                                    cloverPOSLeft: false,
                                    cloverPOSRight: false,
                                    cloverPOSWhole: false,
                                    [`cloverPOS${item}`]: e.target.checked,
                                  };

                                  console.log("HOWARYA", newModifiers);

                                  handleChange({
                                    ...modifiers,
                                    [selectedGroup]: newModifiers,
                                  });
                                }}
                              />{" "}
                              <Label check htmlFor={`cloverPOS${item}-radio`}>
                                {item}
                              </Label>
                            </FormGroup>
                          );
                        })}
                      </Col>
                    </Row>
                  </FormGroup>
                  {!modifiers[selectedGroup]?.cloverPOSWhole && (
                    <FormGroup>
                      <Row className="align-items-center">
                        <Col md="4" className="text-right">
                          <label
                            style={{
                              fontSize: 15,
                              fontWeight: "600",
                            }}
                          >
                            Select Whole Modifier
                          </label>
                        </Col>

                        <Col className="text-left" md="8">
                          <Select
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            isSearchable={true}
                            value={selectedWholeValue}
                            menuPortalTarget={document.body}
                            options={[
                              { name: "Select", _id: "", subProducts: [] },
                              ...Object.values(modifiers || {}).filter(
                                (t) => t.cloverPOSWhole
                              ),
                            ]?.map((item) => {
                              return {
                                label: item?.name,
                                value: item?.modifierId,
                              };
                            })}
                            onChange={(e) => {
                              modifiers[selectedGroup].mainModifierId = e.value;
                              handleChange({ ...modifiers });
                            }}
                            placeholder="Select whole modifier"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  )}
                </>
              )}
              <FormGroup>
                <Row className="align-items-center">
                  <Col md="4" className="text-right">
                    <label
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      Required
                    </label>
                  </Col>
                  <Col md="1">
                    <label className="custom-toggle">
                      <input
                        onChange={(e) => {
                          modifiers[selectedGroup].isRequired =
                            e.target.checked;
                          handleChange({ ...modifiers });
                        }}
                        checked={
                          modifiers[selectedGroup]?.isRequired !== undefined &&
                          modifiers[selectedGroup]?.isRequired
                        }
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className="align-items-center">
                  <Col md="4" className="text-right">
                    <label
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      Hidden
                    </label>
                  </Col>
                  <Col md="1">
                    <label className="custom-toggle">
                      <input
                        onChange={(e) => {
                          modifiers[selectedGroup].isHidden = e.target.checked;
                          handleChange({ ...modifiers });
                        }}
                        checked={
                          modifiers[selectedGroup]?.isHidden !== undefined &&
                          modifiers[selectedGroup]?.isHidden
                        }
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </Row>
              </FormGroup>

              <>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col md="4" className="text-right">
                      <label
                        style={{
                          fontSize: 15,
                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        Modifier Qty
                      </label>
                    </Col>
                    <Col md="1">
                      <label className="custom-toggle">
                        <input
                          onChange={(e) => {
                            modifiers[selectedGroup].enableQtyUpdate =
                              e.target.checked;
                            modifiers[
                              selectedGroup
                            ].advancedPizzaOptions = false;

                            handleChange({ ...modifiers });
                          }}
                          checked={modifiers[selectedGroup]?.enableQtyUpdate}
                          type="checkbox"
                          disabled={
                            modifiers[selectedGroup]?.type !== "checkbox" ||
                            modifiers[selectedGroup]?.subProducts?.some(
                              (item) => item?.subModifiers?.length > 0
                            )
                          }
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
                {/* <FormGroup>
                  <Row className="align-items-center">
                    <Col md="4" className="text-right">
                      <label
                        style={{
                          fontSize: 15,
                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        Parent Modifier
                      </label>
                    </Col>
                    <Col md="1">
                      <label className="custom-toggle">
                        <input
                          onChange={(e) => {
                            if (
                              modifiers[selectedGroup]?.enableParentModifier
                            ) {
                              try {
                                swal({
                                  title: "Are you sure?",
                                  text: "once you disbale all the defined values will be cleared!",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    modifiers[selectedGroup].subProducts = [];
                                    modifiers[
                                      selectedGroup
                                    ].enableParentModifier = false;

                                    modifiers[
                                      selectedGroup
                                    ].enableQtyUpdate = false;
                                    handleChange({ ...modifiers });
                                  }
                                });
                              } catch (error) {}
                            } else {
                              modifiers[selectedGroup].enableParentModifier =
                                e.target.checked;

                              modifiers[selectedGroup].enableQtyUpdate = false;
                              handleChange({ ...modifiers });
                            }
                          }}
                          checked={
                            modifiers[selectedGroup]?.enableParentModifier
                          }
                          type="checkbox"
                          disabled={
                            modifiers[selectedGroup]?.type === "radio" ||
                            modifiers[selectedGroup]?.subProducts?.some(
                              (item) => item?.subModifiers?.length > 0
                            )
                          }
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup> */}
                <FormGroup>
                  <Row className="align-items-center">
                    <Col md="4" className="text-right">
                      <label
                        style={{
                          fontSize: 15,
                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        Advanced Pizza Options
                      </label>
                    </Col>
                    <Col md="1">
                      <label className="custom-toggle">
                        <input
                          onChange={(e) => {
                            if (
                              modifiers[selectedGroup]?.advancedPizzaOptions
                            ) {
                              try {
                                swal({
                                  title: "Are you sure?",
                                  text: "once you disbale all the defined values will be cleared!",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    modifiers[selectedGroup].subProducts = [];
                                    modifiers[
                                      selectedGroup
                                    ].advancedPizzaOptions = false;

                                    modifiers[
                                      selectedGroup
                                    ].enableQtyUpdate = false;
                                    handleChange({ ...modifiers });
                                  }
                                });
                              } catch (error) {}
                            } else {
                              modifiers[selectedGroup].advancedPizzaOptions =
                                e.target.checked;

                              modifiers[selectedGroup].enableQtyUpdate = false;
                              handleChange({ ...modifiers });
                            }
                          }}
                          checked={
                            modifiers[selectedGroup]?.advancedPizzaOptions
                          }
                          type="checkbox"
                          disabled={
                            modifiers[selectedGroup]?.subProducts?.some(
                              (item) => item?.subModifiers?.length > 0
                            ) || modifiers[selectedGroup]?.type !== "checkbox"
                          }
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </>

              {(modifiers[selectedGroup]?.type === "select" ||
                modifiers[selectedGroup]?.type === "checkbox") && (
                <FormGroup>
                  <Row className="align-items-center">
                    <Col md="4" className="text-right">
                      <label
                        style={{
                          fontSize: 15,
                          fontWeight: "600",
                        }}
                      >
                        Select Parent Modifier
                      </label>
                    </Col>
                    <Col className="text-left" md="8">
                      <Select
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        isSearchable={true}
                        value={{
                          label: selectedParentModifier?.label,
                          value: selectedParentModifier?.value,
                        }}
                        menuPortalTarget={document.body}
                        options={[
                          { name: "Select", _id: "", subProducts: [] },
                          ...filterWithType(
                            entities?.data,
                            "select",
                            modifiers[selectedGroup]?.name
                          ),
                        ]?.map((item) => {
                          return {
                            label: item?.name,
                            value: item?._id,
                            subProducts: item?.subProducts,
                          };
                        })}
                        onChange={(e) => {
                          try {
                            if (e?.value === "") {
                              modifiers[
                                selectedGroup
                              ].enableParentModifier = false;
                              modifiers[selectedGroup].parentModifier = "";
                              modifiers[selectedGroup].subProducts = [];

                              setSelectedParentModifier(e);
                            }
                            if (
                              (modifiers[selectedGroup]?.parentModifier ===
                                "" ||
                                modifiers[selectedGroup]?.parentModifier ===
                                  e?.label) &&
                              e?.value !== ""
                            ) {
                              modifiers[selectedGroup].subProducts = [];
                              modifiers[
                                selectedGroup
                              ].enableParentModifier = true;
                              modifiers[selectedGroup].parentModifier =
                                e?.label;
                              modifiers[selectedGroup].parentModifierId =
                                e?.value;
                              setSelectedParentModifier(e);
                            } else if (
                              (modifiers[selectedGroup]?.parentModifier ===
                                "" ||
                                modifiers[selectedGroup]?.parentModifier !==
                                  e?.label) &&
                              e?.value !== ""
                            ) {
                              swal({
                                title: "Are you sure?",
                                text: "Once deleted, you will not be able to recover!",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then(async (willDelete) => {
                                if (willDelete) {
                                  modifiers[
                                    selectedGroup
                                  ].enableParentModifier = true;
                                  modifiers[selectedGroup].subProducts = [];
                                  modifiers[selectedGroup].parentModifier =
                                    e?.label;
                                  modifiers[selectedGroup].parentModifierId =
                                    e?.value;
                                  setSelectedParentModifier(e);
                                }
                              });
                            }
                          } catch (error) {}
                        }}
                        placeholder="Select parent modifier"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              )}

              {modifiers[selectedGroup]?.type === "checkbox" && (
                <>
                  <FormGroup>
                    <Row className="align-items-center">
                      <Col className="text-right" md="4">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                          }}
                        >
                          Min
                        </label>
                      </Col>
                      <Col md="8">
                        <Input
                          id="min"
                          onKeyDown={(e) => {
                            if (e.key === "." || e.key === "-") {
                              e.preventDefault();
                            }
                          }}
                          style={{ maxWidth: 100 }}
                          type="number"
                          value={modifiers[selectedGroup]?.min}
                          onChange={(e) => {
                            modifiers[selectedGroup].min = parseInt(
                              e.target.value
                            );
                            if (
                              modifiers[selectedGroup]?.max > 0 &&
                              modifiers[selectedGroup]?.max < e.target.value
                            ) {
                              return;
                            }
                            handleChange({ ...modifiers });
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row className="align-items-center">
                      <Col className="text-right" md="4">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                          }}
                        >
                          Max
                        </label>
                      </Col>
                      <Col md="6">
                        <Input
                          style={{ maxWidth: 100 }}
                          id="max"
                          type="number"
                          onKeyDown={(e) => {
                            if (e.key === "." || e.key === "-") {
                              e.preventDefault();
                            }
                          }}
                          value={modifiers[selectedGroup]?.max}
                          onChange={(e) => {
                            modifiers[selectedGroup].max = parseInt(
                              e.target.value
                            );
                            handleChange({ ...modifiers });
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              <FormGroup>
                <Row className="align-items-center">
                  <Col md="4" className="text-right">
                    <label
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                      }}
                    >
                      Modifier Sort Order
                    </label>
                  </Col>
                  <Col md="8">
                    <Input
                      style={{ maxWidth: 100 }}
                      id="productName"
                      multiple=""
                      type="number"
                      min={0}
                      onKeyDown={(e) => {
                        if (e.key === ".") {
                          e.preventDefault();
                        }
                      }}
                      value={modifiers[selectedGroup]?.sortOrder}
                      onChange={(e) => {
                        modifiers[selectedGroup].sortOrder = e.target.value;
                        handleChange({ ...modifiers });
                      }}
                    ></Input>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </div>
        )}
      </Col>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Table responsive>
          <thead className="thead-light text-left">
            <tr>
              <th>JKSoft ID</th>
              <th>Modifier Value</th>
              {modifiers[selectedGroup]?.enableParentModifier && (
                <th>Parent Modifier Values</th>
              )}
              <th>Price</th>
              {modifiers[selectedGroup]?.advancedPizzaOptions && (
                <>
                  {" "}
                  <th>Half Price</th>
                  <th>Extra Price</th>
                </>
              )}
              <th>Sort</th>
              <th>Action</th>
            </tr>
          </thead>
          <Droppable droppableId="table">
            {(provided, snapshot) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                <tr>
                  <td>-</td>
                  <td style={{ textAlign: "left" }}>
                    <Select
                      menuPortalTarget={document.body}
                      isSearchable={true}
                      value={
                        formik?.values?.product_id?.length > 0 && {
                          label: formik?.values?.product_name,
                          value: formik?.values?.product_id,
                        }
                      }
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={entities?.data
                        ?.find((d) => d.name === modifiers[selectedGroup]?.name)
                        ?.subProducts?.map((prod) => {
                          return {
                            label: prod?.name,
                            value: prod?._id,
                            subModifiers: prod?.subModifiers,
                            jkSoftId: prod?.jkSoftId,
                            sort: prod?.sort,
                            price: prod?.price,
                            halfPrice: prod?.halfPrice,
                            extraPrice: prod?.extraPrice,
                          };
                        })}
                      onChange={(e) => {
                        if (e?.subModifiers?.length > 0) {
                          modifiers[selectedGroup].enableQtyUpdate = false;
                        }
                        formik.setFieldValue("product_id", e.value);
                        formik.setFieldValue("product_name", e.label);
                        formik.setFieldValue("subModifiers", e.subModifiers);
                        formik.setFieldValue("jkSoftId", e.jkSoftId);
                        formik.setFieldValue("sort", e.sort);
                        formik.setFieldValue("price", e.price || 0);
                        formik.setFieldValue("halfPrice", e.halfPrice || 0);
                        formik.setFieldValue("extraPrice", e.extraPrice || 0);
                      }}
                      isMulti={false}
                    />

                    {formik.errors.product_id && formik.touched.product_id && (
                      <span className="text-danger mt-3">
                        {formik.errors.product_id}
                      </span>
                    )}
                  </td>
                  {modifiers[selectedGroup]?.enableParentModifier && (
                    <td>
                      <Select
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        isSearchable={true}
                        isMulti
                        menuPortalTarget={document.body}
                        options={updatedParentModifierValues?.map((item) => {
                          return {
                            label: item?.name,
                            value: item?._id,
                            parentModifier:
                              modifiers[selectedGroup]?.parentModifier,
                          };
                        })}
                        onChange={(e) => {
                          formik.setFieldValue("selectedParentValues", e);
                        }}
                        placeholder="Select parent modifier"
                        value={formik.values.selectedParentValues}
                      />
                    </td>
                  )}
                  <td style={{ textAlign: "left" }}>
                    <Input
                      type="number"
                      size={"md"}
                      placeholder="Price"
                      style={{ height: 38, width: 100 }}
                      min
                      onChange={formik.handleChange("price")}
                      value={formik.values.price}
                      className={`form-control ${
                        formik.errors.price && formik.touched.price
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {formik.errors.price && formik.touched.price && (
                      <span className="text-danger mt-3">
                        {formik.errors.price}
                      </span>
                    )}
                  </td>
                  {modifiers[selectedGroup]?.advancedPizzaOptions && (
                    <>
                      {" "}
                      <td>
                        <Input
                          type="number"
                          onChange={formik.handleChange("halfPrice")}
                          value={formik.values.halfPrice}
                          placeholder="Half price"
                          style={{ height: 38, width: 100 }}
                          className={`form-control ${
                            formik.errors.halfPrice && formik.touched.halfPrice
                              ? "is-invalid"
                              : ""
                          }`}
                        />

                        {formik.errors.halfPrice &&
                          formik.touched.halfPrice && (
                            <span className="text-danger mt-3">
                              {formik.errors.halfPrice}
                            </span>
                          )}
                      </td>
                      <td>
                        <Input
                          type="number"
                          onChange={formik.handleChange("extraPrice")}
                          value={formik.values.extraPrice}
                          placeholder="Full price"
                          style={{ height: 38, width: 100 }}
                          className={`form-control ${
                            formik.errors.extraPrice &&
                            formik.touched.extraPrice
                              ? "is-invalid"
                              : ""
                          }`}
                        />

                        {formik.errors.extraPrice &&
                          formik.touched.extraPrice && (
                            <span className="text-danger mt-3">
                              {formik.errors.extraPrice}
                            </span>
                          )}
                      </td>
                    </>
                  )}
                  <td style={{ textAlign: "left" }}>
                    <Input
                      type="number"
                      onChange={formik.handleChange("sort")}
                      value={formik.values.sort}
                      placeholder="Sort Order"
                      onKeyDown={(e) => {
                        if (e.key === ".") {
                          e.preventDefault();
                        }
                      }}
                      style={{ height: 38, width: 100 }}
                      className={`form-control ${
                        formik.errors.sort && formik.touched.sort
                          ? "is-invalid"
                          : ""
                      }`}
                    />

                    {formik.errors.sort && formik.touched.sort && (
                      <span className="text-danger mt-3">
                        {formik.errors.sort}
                      </span>
                    )}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <Button color="primary" onClick={handleModifierAdd}>
                      <i className="fa fa-plus" />
                    </Button>
                  </td>
                </tr>

                <>
                  {convertSelectedParentValue(
                    modifiers[selectedGroup]?.subProducts,
                    modifiers[selectedGroup]?.advancedPizzaOptions
                  )?.map((prod, i) => (
                    <Draggable
                      key={prod?.rowId}
                      draggableId={prod?.rowId}
                      index={i}
                    >
                      {(provided) => (
                        <AddModifiersRow
                          advancedPizzaModifiers={
                            modifiers[selectedGroup]?.advancedPizzaOptions
                          }
                          handleChange={handleChange}
                          handleEditModifier={handleEditModifier}
                          i={i}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          refDoc={provided.innerRef}
                          className={snapshot.isDraggingOver ? "dragging" : ""}
                          modifiers={modifiers}
                          prod={prod}
                          selectedGroup={selectedGroup}
                          key={prod?.product_id}
                          parentModifierValues={
                            selectedParentModifier?.subProducts
                          }
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </>
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </Row>
  );
};

export default AddEditModifiersTab;
