/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { FaCaretDown, FaTable } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router";
import { Button, Card, Spinner, UncontrolledCollapse } from "reactstrap";
import CheckCircle from "../../../assets/check_cirlce.svg";
import BillIcon from "../../../assets/icons/bill_outline.svg";
import CardIcon from "../../../assets/icons/card.svg";
import LocationAddress from "../../../assets/icons/ep_location.svg";
import Truck from "../../../assets/icons/truck.svg";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import RestaurantWrapper from "../../../components/Layout/RestaurantWrapper";
import MobileNavMenu from "../../../components/Navbar/MobileNavMenu";
import { AuthContext } from "../../../context";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../hooks/useEntity";
import { useCartStore } from "../../../store";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import compareArrays from "../../../utils/compareArray";
import groupByNameParent from "../../../utils/groupByParent";
import GroupedPizzaToppings from "../menu/components/GroupPizzaToppings";

const PostCheckout = (props) => {
  const location = useLocation();
  const { theme } = useTheme();
  const { clearCart } = useCart();
  const { setCoupon } = useCartStore();
  const url = new URLSearchParams(location.search);
  const authContext = useContext(AuthContext);
  const {
    setTip,
    location: locationDoc,
    method,
    setCateringData,
  } = useOrdering();
  const { restaurant } = useOrderingRestaurant();
  const [mappedPairs, setMappedPairs] = useState([]);
  const queryClient = useQueryClient();

  const type = url.get("orderType");

  const {
    method: orderingMethod,
    timing,
    deliveryConfirmAddress,
  } = useOrdering();

  const {
    findOne,
    entity,
    loading: loadingOrder,
  } = useEntity(
    `eat/ordering/${type && type === "enquiry" ? "enquiry-order" : "order"}`
  );

  const { find, entities: data } = useEntity("eat/ordering/items");

  useEffect(() => {
    findOne(url.get("orderId"));
  }, [url.get("orderId")]);

  useEffect(() => {
    clearCart();
    setCoupon(null);
    setCateringData({});
  }, [url.get("orderId")]);

  useEffect(() => {
    find({
      restaurant: restaurant?.userId?._id,
      location: locationDoc?._id,
      method,
    });
  }, [restaurant, locationDoc, method, type]);

  useEffect(() => {
    setTip({ amount: 0, percentage: 0, other: false });
  }, [setTip]);

  useEffect(() => {
    if (entity) {
      const pairs = Object.entries(entity?.data?.moreInfoData || {}).map(
        ([key, value]) => [{ q: key, a: value }]
      );

      setMappedPairs(pairs);
    }
  }, [entity]);

  useEffect(() => {
    // Function to check if the streamStatus is cancelled or accepted
    const isStreamStatusValid = () =>
      entity?.data?.streamStatus !== "cancelled" &&
      entity?.data?.streamStatus !== "accepted";

    // Invalidate the query every 20 seconds until the condition is met
    const intervalId = setInterval(() => {
      if (isStreamStatusValid() && entity?.data?.location?.connectedToStream) {
        queryClient.invalidateQueries("find-one-eat/ordering/order");
      } else {
        clearInterval(intervalId);
      }
    }, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [entity, queryClient]);

  if (loadingOrder) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size="25" />
      </div>
    );
  }

  return (
    <RestaurantWrapper>
      <div
        style={{
          maxWidth: "1920px",
          margin: "auto",
          backgroundColor: "#fff !important",
        }}
      >
        <div className="mobile_top_nav_menu shadow">
          <MobileNavMenu
            hideSearch={true}
            history={props.history}
            hideBack={true}
            hideCart={true}
          />
          <div
            style={{
              backgroundColor: theme?.primary,
              padding: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 className="mt-2 text-white hide_desktop_only">
              {restaurant?.name}
            </h3>
          </div>
        </div>
        <div className="mb-8">
          <OrderingLayout
            history={props.history}
            showImage={false}
            classname="main_ordering_card_ultra_post"
            noBoxShadow={true}
            hideHeader={true}
          >
            <div className="show_mobile">
              <h1 style={{ fontSize: 30 }}>
                Order ID : #{entity?.data?.orderNum}
              </h1>
            </div>
            <div className="post_checkout_header shadow_mobile_cards_header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <div className="mr-2">
                  <img src={CheckCircle} height="60px" alt="" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    flex: 1,
                  }}
                >
                  <h3>Success!!</h3>
                  {!type || type !== "enquiry" ? (
                    <h4 style={{ fontWeight: "400" }}>
                      You've placed your order
                    </h4>
                  ) : (
                    <h4 style={{ fontWeight: "400" }}>
                      You’ve submitted a catering inquiry
                    </h4>
                  )}
                </div>
              </div>
              <div>
                <Button
                  className="py-2 px-4 order_again_button"
                  style={{
                    backgroundColor: theme?.primary,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let url = new URL(restaurant?.url);
                    props.history.push(
                      url.pathname +
                        (orderingMethod === "walkup" ? "/menu" : ""),
                      { fromFlow: true }
                    );
                  }}
                >
                  <span
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Place another order
                  </span>
                </Button>
              </div>
            </div>
            <div className="mt-3 post_checkout_wrapper">
              <div
                className=" order_wrapper_checkout"
                style={{ width: "100%" }}
              >
                <div className="shadow_mobile_cards">
                  <h1 style={{ fontWeight: "600" }}>Order Details</h1>
                  <div className="order_placed_card">
                    <div className="mr-4">
                      <img style={{ height: "20px" }} src={BillIcon} alt="" />
                    </div>
                    <div>Order Number : {entity?.data?.orderNum}</div>
                  </div>
                  {entity?.data?.method === "dine-in" && (
                    <div className="order_placed_card">
                      <div className="mr-4">
                        <FaTable size={20} />
                      </div>
                      <div>Table Number : {entity?.data?.tableNo}</div>
                    </div>
                  )}
                  <div className="order_placed_card">
                    <div className="mr-3">
                      <img style={{ height: "20px" }} src={Truck} alt="" />
                    </div>
                    {orderingMethod === "pickup" ? (
                      <div
                        className="rounded"
                        style={{
                          padding: "5px 10px",
                          backgroundColor:
                            entity?.data?.location?.connectedToStream &&
                            entity?.data?.streamStatus === "cancelled"
                              ? "red"
                              : "#00AC4A",
                          color: "white",
                          textTransform: "capitalize",
                        }}
                      >
                        {entity?.data?.location?.connectedToStream && (
                          <>
                            {entity?.data?.streamStatus === "accepted" ||
                            entity?.data?.streamStatus === "cancelled" ? (
                              entity?.data?.streamStatus === "cancelled" ? (
                                <>Cancelled</>
                              ) : (
                                <>
                                  {"Pickup"}{" "}
                                  {timing === "later" ? (
                                    <>
                                      {moment(
                                        new Date(entity?.data?.scheduledOn)
                                      ).format("MMM DD, YYYY , hh:mm A")}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {moment(
                                        entity?.data?.orderNowPickupDate
                                      ).format("MMM DD, YYYY hh:mm A")}{" "}
                                    </>
                                  )}
                                </>
                              )
                            ) : (
                              <>
                                Processing <Spinner size={"sm"} />
                              </>
                            )}
                          </>
                        )}

                        {!entity?.data?.location?.connectedToStream && (
                          <>
                            {"Pickup"}{" "}
                            {timing === "later" ? (
                              <>
                                {moment(
                                  new Date(entity?.data?.scheduledOn)
                                ).format("MMM DD, YYYY , hh:mm A")}{" "}
                              </>
                            ) : (
                              <>
                                {moment(
                                  entity?.data?.orderNowPickupDate
                                ).format("MMM DD, YYYY hh:mm A")}{" "}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    ) : orderingMethod === "delivery" ? (
                      <div
                        className="rounded"
                        style={{
                          padding: "5px 10px",
                          backgroundColor:
                            entity?.data?.location?.connectedToStream &&
                            entity?.data?.streamStatus === "cancelled"
                              ? "red"
                              : "#00AC4A",
                          color: "white",
                          textTransform: "capitalize",
                        }}
                      >
                        {entity?.data?.location?.connectedToStream && (
                          <>
                            {entity?.data?.streamStatus === "accepted" ||
                            entity?.data?.streamStatus === "cancelled" ? (
                              entity?.data?.streamStatus === "cancelled" ? (
                                <>Cancelled</>
                              ) : (
                                <>
                                  {"Delivery"}{" "}
                                  {timing === "later" ? (
                                    <>
                                      {moment(
                                        new Date(entity?.data?.scheduledOn)
                                      ).format("MMM DD, YYYY , hh:mm A")}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {moment(
                                        locationDoc?.enableIhd
                                          ? entity?.data?.ihdDeliveryTime
                                          : entity?.data?.orderNowPickupDate
                                      ).format("MMM DD, YYYY hh:mm A")}{" "}
                                    </>
                                  )}
                                </>
                              )
                            ) : (
                              <>
                                Processing <Spinner size={"sm"} />
                              </>
                            )}
                          </>
                        )}

                        {!entity?.data?.location?.connectedToStream && (
                          <>
                            {"Delivery"}{" "}
                            {timing === "later" ? (
                              <>
                                {moment(
                                  new Date(entity?.data?.scheduledOn)
                                ).format("MMM DD, YYYY , hh:mm A")}{" "}
                              </>
                            ) : (
                              <>
                                {moment(
                                  locationDoc?.enableIhd
                                    ? entity?.data?.ihdDeliveryTime
                                    : entity?.data?.orderNowPickupDate
                                ).format("MMM DD, YYYY hh:mm A")}{" "}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div
                        className="rounded"
                        style={{
                          padding: "5px 10px",
                          backgroundColor:
                            entity?.data?.location?.connectedToStream &&
                            entity?.data?.streamStatus === "cancelled"
                              ? "red"
                              : "#00AC4A",
                          color: "white",
                          textTransform: "capitalize",
                        }}
                      >
                        {entity?.data?.location?.connectedToStream && (
                          <>
                            {entity?.data?.streamStatus === "accepted" ||
                            entity?.data?.streamStatus === "cancelled" ? (
                              entity?.data?.streamStatus === "cancelled" ? (
                                <>Cancelled</>
                              ) : (
                                <>
                                  {"Dine In"}{" "}
                                  {timing === "later" ? (
                                    <>
                                      {moment(
                                        new Date(entity?.data?.scheduledOn)
                                      ).format("MMM DD, YYYY , hh:mm A")}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {moment(
                                        entity?.data?.orderNowPickupDate
                                      ).format("MMM DD, YYYY hh:mm A")}{" "}
                                    </>
                                  )}
                                </>
                              )
                            ) : (
                              <>
                                Processing <Spinner size={"sm"} />
                              </>
                            )}
                          </>
                        )}

                        {!entity?.data?.location?.connectedToStream && (
                          <>
                            {"Dine In"}{" "}
                            {timing === "later" ? (
                              <>
                                {moment(
                                  new Date(entity?.data?.scheduledOn)
                                ).format("MMM DD, YYYY , hh:mm A")}{" "}
                              </>
                            ) : (
                              <>
                                {moment(
                                  entity?.data?.orderNowPickupDate
                                ).format("MMM DD, YYYY hh:mm A")}{" "}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  {!type && type !== "enquiry" ? (
                    <div className="order_placed_card">
                      <div className="mr-3">
                        <img style={{ height: "20px" }} src={CardIcon} alt="" />
                      </div>
                      {
                        <div>
                          Payment :{" "}
                          <b style={{ textTransform: "capitalize" }}>
                            {entity?.data?.paymentStatus === "paid"
                              ? "Approved"
                              : method === "delivery"
                              ? "Pay at delivery"
                              : "Pay at store"}
                          </b>
                        </div>
                      }
                    </div>
                  ) : (
                    <div className="order_placed_card">
                      <div className="mr-3">
                        <img style={{ height: "20px" }} src={CardIcon} alt="" />
                      </div>
                      {
                        <div>
                          Payment :{" "}
                          <b style={{ textTransform: "capitalize" }}>
                            N/A - Inquiry
                          </b>
                        </div>
                      }
                    </div>
                  )}
                  <div className="order_placed_card">
                    <div className="mr-3">
                      <img
                        style={{ height: "30px" }}
                        src={LocationAddress}
                        alt=""
                      />
                    </div>
                    <span style={{ color: "#666666", textAlign: "left" }}>
                      <span style={{ color: "#666666", textAlign: "left" }}>
                        {entity?.data?.location?.displayAddress}{" "}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="mt-3 shadow_mobile_cards">
                  {orderingMethod === "pickup" ? (
                    <>
                      <h1 style={{ fontWeight: "600" }}>Pickup Details</h1>
                      <div className="order_placed_card">
                        <span style={{ color: "#666666", textAlign: "left" }}>
                          {entity?.data?.location?.displayAddress}
                        </span>
                      </div>
                    </>
                  ) : orderingMethod === "dine-in" ? (
                    <></>
                  ) : (
                    <>
                      <h1 style={{ fontWeight: "600" }}>Delivery Details</h1>
                      <div className="order_placed_card">
                        <span style={{ color: "#666666", textAlign: "left" }}>
                          {entity?.data?.cateringDetails ? (
                            <span
                              style={{
                                fontWeight: "500",
                                maxWidth: "400px",

                                fontSize: 15,
                              }}
                              className="m-0"
                            >
                              {(entity?.data?.cateringDetails?.apartment
                                ?.length > 0
                                ? entity?.data?.cateringDetails?.apartment + ","
                                : "") + entity?.data?.cateringDetails?.address}
                            </span>
                          ) : (
                            <span
                              style={{
                                fontWeight: "500",
                                maxWidth: "400px",

                                fontSize: 15,
                              }}
                              className="m-0"
                            >
                              {(deliveryConfirmAddress?.apartmentNo?.length > 0
                                ? deliveryConfirmAddress.apartmentNo + ","
                                : "") +
                                deliveryConfirmAddress?.formatted_address}
                            </span>
                          )}
                        </span>
                      </div>
                    </>
                  )}
                  {authContext.user && (
                    <div
                      className="order_placed_card"
                      onClick={() => {
                        let url = new URL(restaurant?.url);
                        props.history.push(url.pathname + "/history");
                      }}
                    >
                      <span style={{ color: "#666666", textAlign: "left" }}>
                        <Button
                          style={{
                            backgroundColor: theme?.primary,
                            color: "#fff",
                            letterSpacing: 2,
                            fontWeight: "600",
                          }}
                        >
                          ORDER HISTORY
                        </Button>
                      </span>
                    </div>
                  )}
                </div>
                {entity?.data?.cateringDetails?.catering && (
                  <div className="mt-3 shadow_mobile_cards">
                    <h1 style={{ fontWeight: "600" }}>Event Details</h1>
                    <span style={{ color: "#666666", textAlign: "left" }}>
                      <span style={{ color: "#666666", textAlign: "left" }}>
                        {String(
                          entity?.data?.cateringDetails?.occasion
                        ).toUpperCase()}{" "}
                      </span>
                    </span>
                    <div
                      className="mr-2"
                      style={{ color: "#666666", textAlign: "left" }}
                    >
                      <span style={{ color: "#666666", textAlign: "left" }}>
                        <i className="fa fa-calendar mr-2" />
                      </span>
                      <span
                        className="mr-2"
                        style={{ color: "#666666", textAlign: "left" }}
                      >
                        {moment(entity?.data?.cateringDetails?.date).format(
                          "MM/DD/YYYY"
                        )}
                      </span>
                      <span> | </span>
                      <span style={{ color: "#666666", textAlign: "left" }}>
                        <i className="fa fa-user mr-2" />
                      </span>
                      <span style={{ color: "#666666", textAlign: "left" }}>
                        {String(
                          entity?.data?.cateringDetails?.count
                        ).toUpperCase()}
                        {" Persons "}
                      </span>
                    </div>
                  </div>
                )}
                {mappedPairs?.length > 0 && (
                  <div className="mt-3 shadow_mobile_cards">
                    <h1 style={{ fontWeight: "600" }}>More Info</h1>
                    <div>
                      {mappedPairs?.map((op, i) => {
                        return op?.map((item, index) => {
                          return (
                            <div>
                              <div className="bold">
                                {`${i + 1} - ${item?.q}`}
                              </div>
                              {typeof item?.a === "object" ? (
                                <div className="my-2">
                                  {item?.a?.map((o) => {
                                    return <div>{o}</div>;
                                  })}
                                </div>
                              ) : (
                                <div className="my-2">{item?.a}</div>
                              )}
                            </div>
                          );
                        });
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="items_wrapper_checkout">
                {!loadingOrder && (
                  <Card
                    className="px-2 py-2 post_checkout_card_mobile"
                    style={{
                      background: "#FFFFFF",
                      boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
                      borderRadius: "6px",
                    }}
                  >
                    {entity?.data?.items?.map((it, i) => {
                      let o = data?.data?.data?.find(
                        (itm) => itm?._id === it?.itemId
                      );

                      const allSubprods = Object.values(
                        o?.modifiers || {}
                      ).flatMap((modif) => modif?.subProducts);

                      const allSelectedModifiers = allSubprods.filter(
                        (subProd) => subProd?.defaultSelected
                      );

                      const missingSelected = compareArrays(
                        allSelectedModifiers,
                        it?.modifiers
                      );

                      const filteredBasedOnParentModifs =
                        missingSelected.filter((f) => {
                          const idx = it?.modifiers?.findIndex(
                            (t) =>
                              f.selectedParentValue.label === t.product_name ||
                              f?.selectedParentValue?.label == "" ||
                              !f?.selectedParentValue?.label
                          );
                          return idx !== -1;
                        });

                      const finalMissingSelected =
                        filteredBasedOnParentModifs.filter(
                          (obj, index, self) =>
                            index ===
                            self.findIndex(
                              (t) =>
                                t.product_id === obj.product_id &&
                                t.product_name === obj.product_name
                            )
                        );

                      return (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <h1
                              style={{
                                fontWeight: "500",
                                fontSize: 18,
                                color: "#000",
                              }}
                            >
                              {it?.name}
                            </h1>
                            <h3
                              style={{
                                fontSize: 20,
                                color: "#212121",
                                fontWeight: "600",
                              }}
                            >
                              {Number(it?.price) !== 0
                                ? `$${Number(it?.price * it?.qty).toFixed(2)}`
                                : ""}
                            </h3>
                          </div>
                          <div
                            className="py-2 rounded"
                            style={{
                              backgroundColor: "#ededed",
                              color: "#666666",
                              maxWidth: 100,
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            <span
                              className="mx-4"
                              style={{ fontWeight: "700", color: "#000" }}
                            >
                              {it?.qty}
                            </span>
                          </div>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {finalMissingSelected?.map((selected) => {
                              return (
                                <div className="d-flex text-left justify-content-between  align-items-center">
                                  <div>
                                    <div className={"normal"}>
                                      No {selected?.product_name}
                                    </div>
                                  </div>

                                  <div></div>
                                </div>
                              );
                            })}
                            <GroupedPizzaToppings it={it} data={it.modifiers} />

                            {groupByNameParent(
                              it?.modifiers?.filter(
                                (it) =>
                                  !it?.defaultSelected ||
                                  (it?.defaultSelected && it?.qty > 1)
                              )
                            ).map((modif) => {
                              return (
                                <>
                                  {restaurant?.showModifierName && (
                                    <div className={"bold text-left"}>
                                      {modif.name}
                                    </div>
                                  )}
                                  {modif.data.map((op) => {
                                    let newPrice;
                                    if (
                                      op?.qty &&
                                      op?.qty > 1 &&
                                      !op?.defaultSelected
                                    ) {
                                      newPrice = op?.price * op?.qty;
                                    } else if (
                                      op?.qty &&
                                      op?.qty > 1 &&
                                      op?.defaultSelected
                                    ) {
                                      const quantity = Number(op?.qty) - 1;
                                      newPrice = op?.price * quantity;
                                    } else newPrice = op?.price * 1;

                                    const modifierSelectdPrice =
                                      op?.selectedModifier?.label?.split(
                                        "$"
                                      )[1] === undefined
                                        ? 0
                                        : op?.selectedModifier?.label?.split(
                                            "$"
                                          )[1];

                                    return (
                                      <>
                                        {!op?.advancedPizzaOptions && (
                                          <>
                                            <div className="d-flex text-left justify-content-between  align-items-center">
                                              <div>
                                                <div
                                                  className={
                                                    op?.selectedModifier?.label
                                                      ? "bold"
                                                      : "normal"
                                                  }
                                                >
                                                  {op?.product_name}{" "}
                                                  {`${
                                                    (!op.defaultSelected &&
                                                      op?.qty > 1) ||
                                                    (op.defaultSelected &&
                                                      op?.qty > 2)
                                                      ? ` - x${
                                                          op?.defaultSelected
                                                            ? op?.qty - 1
                                                            : op?.qty
                                                        }`
                                                      : ""
                                                  }`}
                                                </div>
                                              </div>

                                              <div>
                                                {newPrice !== 0 && (
                                                  <div>
                                                    $
                                                    <>
                                                      {Number(
                                                        Number(
                                                          Number(newPrice) -
                                                            Number(
                                                              op?.selectedModifier?.label.split(
                                                                "$"
                                                              )[1] || 0
                                                            )
                                                        ) * it?.qty
                                                      ).toFixed(2)}
                                                    </>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            <div>
                                              {op?.selectedModifier?.label && (
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    width: "100%",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <div className="text-left">
                                                    -{" "}
                                                    {
                                                      op?.selectedModifier?.label
                                                        .split("$")[0]
                                                        .split(" -")[0]
                                                    }
                                                  </div>
                                                  {modifierSelectdPrice !==
                                                    0 && (
                                                    <div>
                                                      $
                                                      {Number(
                                                        modifierSelectdPrice *
                                                          it?.qty
                                                      ).toFixed(2)}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </div>
                          {it?.instruction && (
                            <div>
                              <h1
                                style={{
                                  fontWeight: "500",
                                  fontSize: 18,
                                  color: "#212121",
                                  textAlign: "left",
                                }}
                              >
                                Special Instructions
                              </h1>
                              <p
                                className="py-2 px-3"
                                style={{
                                  fontWeight: "500",
                                  fontSize: 15,
                                  color: "#666666",
                                  textAlign: "left",
                                  backgroundColor: "#ededed",
                                }}
                              >
                                {it?.instruction}
                              </p>
                            </div>
                          )}
                          {entity?.data?.items?.length - 1 !== i && <hr />}
                          {it?.note && (
                            <>
                              <h1
                                style={{
                                  fontWeight: "500",
                                  fontSize: 18,
                                  color: "#212121",
                                  textAlign: "left",
                                }}
                              >
                                Special Instructions
                              </h1>
                              <p
                                style={{
                                  fontWeight: "500",
                                  fontSize: 15,
                                  color: "#666666",
                                  textAlign: "left",
                                }}
                              >
                                {it?.note}
                              </p>
                            </>
                          )}
                        </div>
                      );
                    })}
                    <hr />
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: "black",
                        }}
                      >
                        <h3 style={{ fontWeight: "600" }}>Subtotal :</h3>
                        <div>
                          <h3 style={{ fontWeight: "600" }} className="mr-1">
                            ${entity?.data?.payment?.subTotal?.toFixed(2)}
                          </h3>
                        </div>
                      </div>
                      <div
                        id="linkToggler"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3 style={{ cursor: "pointer" }} className=" mt-1">
                          Tax & Fees <FaCaretDown size={20} />
                        </h3>
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            color: "black",
                          }}
                        >
                          <div>
                            {" "}
                            <h3 className="mr-1">
                              $
                              {(
                                parseFloat(entity?.data?.payment?.tax) +
                                parseFloat(
                                  locationDoc?.enableIhd &&
                                    restaurant.ihdFees === "partner"
                                    ? entity?.data?.payment?.tip
                                    : 0
                                )
                              ).toFixed(2)}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div>
                        <UncontrolledCollapse toggler="#linkToggler">
                          <div
                            style={{
                              fontSize: "16px",
                              backgroundColor: "inherit",
                            }}
                            className="py-2"
                          >
                            {entity?.data?.payment?.taxDetails?.map(
                              (taxName) => {
                                return (
                                  <div className="d-flex justify-content-between mb-2">
                                    <div>{taxName?.name}</div>
                                    <div>${taxName?.amount.toFixed(2)}</div>
                                  </div>
                                );
                              }
                            )}

                            {(orderingMethod === "pickup" ||
                              !locationDoc?.enableIhd ||
                              (locationDoc?.enableIhd &&
                                restaurant.ihdFees === "restaurant")) &&
                              entity?.data?.payment?.orderFee > 0 && (
                                <div className="d-flex justify-content-between mb-2">
                                  <div>Ordering Fee</div>
                                  <div>
                                    $
                                    {entity?.data?.payment?.orderFee.toFixed(2)}
                                  </div>
                                </div>
                              )}

                            {orderingMethod === "delivery" && (
                              <>
                                <div className="d-flex justify-content-between mb-2">
                                  <div>Delivery Fee</div>
                                  <div>
                                    $
                                    {locationDoc?.enableIhd &&
                                    restaurant?.ihdFees === "partner"
                                      ? (
                                          entity?.data?.payment?.deliveryFee +
                                          entity?.data?.payment?.orderFee
                                        ).toFixed(2)
                                      : entity?.data?.payment?.deliveryFee.toFixed(
                                          2
                                        )}
                                  </div>
                                </div>
                                {locationDoc?.enableIhd &&
                                  entity?.data?.payment?.tip > 0 && (
                                    <div className="d-flex justify-content-between mb-2">
                                      <div>Delivery Tip</div>
                                      <div>
                                        $
                                        {(
                                          entity?.data?.payment?.tip || 0
                                        )?.toFixed(2)}
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </UncontrolledCollapse>
                      </div>

                      <div className="bold" style={{ fontSize: 18 }}>
                        {(orderingMethod === "pickup" ||
                          !locationDoc?.enableIhd) && (
                          <div className="d-flex justify-content-between mt-2">
                            <h3>Tip :</h3>
                            <h3>
                              ${(entity?.data?.payment?.tip || 0)?.toFixed(2)}
                            </h3>
                          </div>
                        )}
                        {entity?.data?.payment?.loyaltyDiscount > 0 && (
                          <div className="d-flex justify-content-between mb-2">
                            <h3>Loyalty Discount :</h3>
                            <h3>
                              -$
                              {entity?.data?.payment?.loyaltyDiscount?.toFixed(
                                2
                              )}
                            </h3>
                          </div>
                        )}
                        {entity?.data?.payment?.discount > 0 && (
                          <div className="d-flex justify-content-between mb-2">
                            <h3>Discount :</h3>
                            <h3>
                              -$
                              {entity?.data?.payment?.discount?.toFixed(2)}
                            </h3>
                          </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h3
                              style={{
                                color: "black",
                              }}
                            >
                              Total :
                            </h3>
                          </div>

                          <div style={{ fontWeight: "600" }}>
                            <h3 style={{ color: "#000" }}>
                              ${entity?.data?.payment?.total.toFixed(2)}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </>
                  </Card>
                )}
              </div>
            </div>
          </OrderingLayout>
        </div>
      </div>
    </RestaurantWrapper>
  );
};

export default PostCheckout;
