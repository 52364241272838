import classnames from "classnames";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CateringPrepTimes from "../../components/CateringPrepTimes";
import CateringHours from "./CateringHours";
import DeliveryZone from "./DeliveryZoneCatering";
import { ErrorLable } from "../../AddLocation";

const deliveryZoneKey = () => (Math.random() + 1).toString(36).substring(7);

const Catering = ({
  values,
  handleChange,
  setFieldValue,
  restaurantDoc,
  location,
  errors,
  touched,
}) => {
  const [tabs, setTabs] = useState(0);

  const [activeDeliveryZone, setActiveDeliveryZone] = useState("");

  const toggleTabs = (tab) => {
    setTabs(tab);
  };

  const handleCopy = async () => {
    const splits = restaurantDoc?.restaurant?.url?.split("/");
    const urlString = new URL(restaurantDoc.restaurant.url);
    const host = urlString.host;
    const restaurantEndpoint = splits?.[splits?.length - 1];
    const url = `${
      host.startsWith("http") ? host : `http://${host}`
    }/ordering/${restaurantEndpoint}/delivery?id=${location?.data?._id}&url=${
      restaurantDoc?.restaurant?.url
    }`;
    await navigator.clipboard.writeText(url);
    toast.success("Copied");
  };

  const onDeliveryZoneTabChange = (k) => {
    const zones = values.cateringDeliveryZones || [];

    // check if custom prep times are enabled
    if (values?.customPrepTimesEnabled && zones.length === 1) {
      return toast.error("Please disable custom prep times to add more zones");
    }

    if (k === "add") {
      if (zones[zones.length - 1]?.extendWithIHD) {
        return toast.error("Please disable extend with IHD to add more zones");
      }

      if (zones.some((zone) => zone.deliveryOption === "listing")) {
        return toast.error("Delivery listing only works with single zone.");
      }

      const newZone = {
        key: deliveryZoneKey(),
        deliveryFeeSetting: "always",
        deliveryRadius: 10,
        deliveryOption: "geofence",
      };

      zones.push(newZone);

      setFieldValue("cateringDeliveryZones", [...zones]);

      setActiveDeliveryZone(newZone.key);

      console.log(activeDeliveryZone, newZone, "ZONES");
    } else {
      setActiveDeliveryZone(k);
    }
  };

  const handleZoneChange = (index, zone) => {
    const newZones = [...values.cateringDeliveryZones];
    newZones[index] = zone;
    setFieldValue("cateringDeliveryZones", [...newZones]);
  };

  const handleZoneDelete = (index) => {
    const newZones = [...values.cateringDeliveryZones];
    newZones.splice(index, 1);
    const activeKey = newZones[Math.max(index - 1, 0)]?.key;
    setFieldValue("cateringDeliveryZones", [...newZones]);
    setTimeout(() => setActiveDeliveryZone(activeKey), 0);
  };

  useEffect(() => {
    if (values.cateringDeliveryZones?.length > 0 && !activeDeliveryZone) {
      setActiveDeliveryZone(values?.cateringDeliveryZones[0]?.key);
    }
  }, [values.cateringDeliveryZones, activeDeliveryZone]);

  return (
    <>
      <FormGroup className="row" style={{ alignItems: "center" }}>
        <Label
          className="form-control-label text-left"
          htmlFor="example-text-input"
          md="4"
        >
          Enable Catering
        </Label>
        <Col md="8">
          <label className="custom-toggle">
            <input
              checked={values?.cateringShop}
              value={values?.cateringShop}
              onChange={handleChange("cateringShop")}
              type="checkbox"
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </Col>
      </FormGroup>
      {values?.cateringShop && (
        <Nav
          className="nav-fill mb-4 flex-column flex-sm-row"
          id="tabs-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 0}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 0,
              })}
              style={{ cursor: "pointer" }}
              onClick={() => toggleTabs(0)}
              role="tab"
            >
              <i className="ni ni-settings mr-2" />
              Settings
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 1,
              })}
              style={{ cursor: "pointer" }}
              onClick={() => toggleTabs(1)}
              role="tab"
            >
              <i className="ni ni-delivery-fast mr-2" />
              Delivery Radius
            </NavLink>
          </NavItem>
        </Nav>
      )}
      {values?.cateringShop && (
        <TabContent activeTab={"tabs" + tabs}>
          <TabPane tabId="tabs0">
            <div>
              <FormGroup>
                <label
                  className="form-control-label text-left"
                  htmlFor="cateringMethodSelect"
                >
                  Method
                </label>
                <Input
                  onChange={handleChange("cateringMethod")}
                  id="cateringMethodSelect"
                  type="select"
                  value={values?.cateringMethod}
                  className={`form-control ${
                    errors.cateringMethod && touched.cateringMethod
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value={""}>Select Method</option>
                  <option value={"both"}>Both</option>
                  <option value={"pickup"}>Pickup</option>
                  <option value={"delivery"}>Delivery</option>
                </Input>
                {errors.cateringMethod && touched.cateringMethod && (
                  <ErrorLable message={errors.cateringMethod} />
                )}
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label text-left"
                  htmlFor="orderFeeSelect"
                >
                  Order Fee
                </label>
                <Input
                  value={values?.cateringOrderFee}
                  onChange={handleChange("cateringOrderFee")}
                  id="orderFeeSelect"
                  type="select"
                  className={`form-control ${
                    errors.cateringOrderFee && touched.cateringOrderFee
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value={""}>Select</option>
                  <option value={"enable"}>Enable</option>
                  <option value={"disable"}>Disable</option>
                </Input>
                {errors.cateringOrderFee && touched.cateringOrderFee && (
                  <ErrorLable message={errors.cateringOrderFee} />
                )}
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label text-left"
                  htmlFor="orderFeeSelect"
                >
                  Minimum Head Count
                </label>
                <Input
                  value={values?.cateringMinHeadCount}
                  id="orderFeeSelect"
                  type="number"
                  onChange={handleChange("cateringMinHeadCount")}
                  className={`form-control ${
                    errors.cateringMinHeadCount && touched.cateringMinHeadCount
                      ? "is-invalid"
                      : ""
                  }`}
                ></Input>
                {errors.cateringMinHeadCount &&
                  touched.cateringMinHeadCount && (
                    <ErrorLable message={errors.cateringMinHeadCount} />
                  )}
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label text-left"
                  htmlFor="orderFeeSelect"
                >
                  Prep Times
                </label>
                <div>
                  <CateringPrepTimes
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                  />
                </div>
              </FormGroup>
            </div>
          </TabPane>
          <TabPane tabId="tabs1">
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
              activeKey={activeDeliveryZone}
              onSelect={(k) => onDeliveryZoneTabChange(k)}
            >
              {values?.cateringDeliveryZones?.map?.((zone, index) => (
                <Tab
                  key={zone.key}
                  eventKey={zone.key}
                  title={
                    <>
                      Zone {index + 1}
                      <button
                        className="btn btn-sm"
                        onClick={(e) => {
                          e.preventDefault();
                          handleZoneDelete(index);
                        }}
                      >
                        ✕
                      </button>
                    </>
                  }
                >
                  <DeliveryZone
                    latLng={values?.latLng}
                    zone={zone}
                    onChange={(d) => handleZoneChange(index, d)}
                    handleCopy={handleCopy}
                    location={location?.data}
                    showExtendWithIHD={false}
                    showDeliveryListing={
                      values?.cateringDeliveryZones?.length <= 1
                    }
                  />
                </Tab>
              ))}
              <Tab
                eventKey="add"
                title={
                  <button
                    className="btn btn-sm"
                    onClick={(e) => e.preventDefault()}
                  >
                    +
                  </button>
                }
              >
                add ui
              </Tab>
            </Tabs>
          </TabPane>
          {/* <TabPane tabId="tabs2">
            <CateringHours
              setFieldValue={setFieldValue}
              values={values}
              onSelect={(val) => console.log(val)}
            />
          </TabPane> */}
        </TabContent>
      )}
    </>
  );
};

export default Catering;
