/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Button, Modal, UncontrolledCollapse } from "reactstrap";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../hooks/useEntity";
import { useCartStore } from "../../../store";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import compareArrays from "../../../utils/compareArray";
import logger from "../../../utils/logger";
import GroupedPizzaToppings from "../menu/components/GroupPizzaToppings";
import groupByNameParent from "../../../utils/groupByParent";

export default function CartModal({ isOpen, toggle, history, tip }) {
  const { addToCart, changeQuantity } = useCart();
  const { cartItems } = useCartStore();
  const { find, entities: data } = useEntity("eat/ordering/items");
  const { restaurant } = useOrderingRestaurant();
  const {
    method,
    location,
    deliveryConfirmAddress,
    deliveryZone,
    deliveryMethod,
    cateringData,
    deliveryFee,
    timing,
    laterDate,
    laterTime,
    menuId,
  } = useOrdering();
  const { theme } = useTheme();
  const [billingDetails, setBillingDetails] = useState();

  const { create: getBilling, loading: billingLoading } = useEntity(
    "eat/ordering/billing"
  );

  async function getBillingDetails() {
    try {
      const res = await getBilling({
        data: [...cartItems],
        restaurant: restaurant?._id,
        method,
        location: location?._id,
        tip,
        address: deliveryConfirmAddress,
        deliveryZoneId: deliveryZone?._id,
        deliveryMethod,
        catering: cateringData?.catering,
        menuId,
      });
      setBillingDetails(res);
    } catch (error) {
      logger.push({ error, info: "CartModal" });
      toast.error(error.message);
    }
  }

  useEffect(() => {
    if (isOpen) {
      getBillingDetails().then((res) => console.log(res));
    }
  }, [cartItems, tip, isOpen, menuId]);

  useEffect(() => {
    let dateString;
    if (timing === "later") {
      dateString = new Date(laterDate);
      const splittedTime = laterTime.split(":");
      dateString.setHours(splittedTime[0], splittedTime[1]);
    }
    find({
      restaurant: restaurant?.userId?._id,
      location: location?._id,
      method: cateringData?.catering ? cateringData?.method : method,
      catering: cateringData?.catering,
      schedule: timing === "later" ? dateString : undefined,
      filterDSP: location?.connectedToStream ? "true" : "false",
    });
  }, [restaurant, location, method]);

  return (
    <Modal
      style={{ fontFamily: "Inter" }}
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div>
        <div
          className="px-3 py-3"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h5 className="modal-title" id="exampleModalLabel">
            Cart
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>{" "}
        <div className="px-3 py-3">
          <div
            style={{
              borderRadius: 6,
            }}
          >
            {cartItems?.length > 0 && (
              <div
                style={{
                  boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
                  background: "#FFFFFF",
                  borderRadius: 6,
                  padding: 10,
                }}
              >
                {cartItems?.map((it, i) => {
                  let o = data?.data?.data?.find((itm) => itm?._id === it?._id);

                  const quantityItem = cartItems.reduce((ac, ar) => {
                    if (ar?._id === o?._id) {
                      return ac + ar.qty;
                    } else return ac;
                  }, 0);

                  const allSubprods = Object.values(o?.modifiers || {}).flatMap(
                    (modif) => modif?.subProducts
                  );

                  const allSelectedModifiers = allSubprods.filter(
                    (subProd) => subProd?.defaultSelected
                  );

                  const missingSelected = compareArrays(
                    allSelectedModifiers,
                    it?.modifiers
                  );

                  const filteredBasedOnParentModifs = missingSelected.filter(
                    (f) => {
                      const idx = it?.modifiers?.findIndex(
                        (t) =>
                          f?.selectedParentValue?.label === t?.product_name ||
                          f?.selectedParentValue?.label === "" ||
                          !f?.selectedParentValue?.label
                      );
                      return idx !== -1;
                    }
                  );
                  const finalMissingSelected =
                    filteredBasedOnParentModifs.filter(
                      (obj, index, self) =>
                        index ===
                        self.findIndex(
                          (t) =>
                            t.product_id === obj.product_id &&
                            t.product_name === obj.product_name
                        )
                    );

                  return (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: "600",
                            fontSize: 18,
                            color: theme.primary,
                            textAlign: "left",
                          }}
                        >
                          {o?.name}
                        </h1>
                        <h3
                          style={{
                            fontSize: 20,
                            color: "#212121",
                            fontWeight: "600",
                          }}
                        >
                          {Number(o?.price) > 0
                            ? `$${Number(o?.price * it?.qty).toFixed(2)}`
                            : ""}
                        </h3>
                      </div>

                      <div className="plus-icon inner-plus rounded">
                        <Button
                          onClick={() => {
                            changeQuantity(i);
                          }}
                          size="sm"
                          className="btn-icon btn-2"
                          type="button"
                          style={{
                            margin: "none",
                            boxShadow: "none",
                            transform: "none",
                          }}
                        >
                          <span>
                            <i
                              className="fa fa-minus"
                              style={{ fontSize: "16px" }}
                            />
                          </span>
                        </Button>
                        <Button
                          style={{
                            margin: "none",
                            boxShadow: "none",

                            transform: "none",
                          }}
                          size="sm"
                        >
                          {it?.qty}
                        </Button>
                        <Button
                          size="sm"
                          style={{
                            margin: "none",
                            boxShadow: "none",

                            transform: "none",
                          }}
                          type="button"
                          onClick={() => {
                            if (
                              quantityItem >= o?.moqPerOrder &&
                              cateringData?.catering
                            ) {
                              toast(
                                `Max quantity per order is ${o?.moqPerOrder}`
                              );

                              return;
                            }
                            addToCart({ ...it });
                          }}
                        >
                          <span>
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: "16px" }}
                            />
                          </span>
                        </Button>
                      </div>
                      <div
                        className=" mt-3"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        <>
                          {finalMissingSelected?.map((selected) => {
                            return (
                              <div className="d-flex text-left justify-content-between  align-items-center">
                                <div>
                                  <div className={"normal"}>
                                    No {selected?.product_name}
                                  </div>
                                </div>

                                <div></div>
                              </div>
                            );
                          })}
                          <GroupedPizzaToppings it={it} data={it?.modifiers} />

                          {groupByNameParent(
                            it?.modifiers?.filter(
                              (it) =>
                                (!it?.defaultSelected || it?.qty > 1) &&
                                !it.advancedPizzaOptions
                            )
                          ).map((modif) => {
                            return (
                              <>
                                {restaurant?.showModifierName && (
                                  <div className={"bold text-left"}>
                                    {modif.name}
                                  </div>
                                )}
                                {modif.data.map((op) => {
                                  let newPrice;
                                  if (
                                    op?.qty &&
                                    op?.qty > 1 &&
                                    !op?.defaultSelected
                                  ) {
                                    newPrice = op?.price * op?.qty;
                                  } else if (
                                    op?.qty &&
                                    op?.qty > 1 &&
                                    op?.defaultSelected
                                  ) {
                                    const quantity = Number(op?.qty) - 1;
                                    newPrice = op?.price * quantity;
                                  } else newPrice = op?.price * 1;

                                  const modifierSelectdPrice =
                                    op?.selectedModifier?.label?.split(
                                      "$"
                                    )[1] === undefined
                                      ? 0
                                      : op?.selectedModifier?.label?.split(
                                          "$"
                                        )[1];

                                  return (
                                    <>
                                      {!op?.advancedPizzaOptions && (
                                        <>
                                          <div className="d-flex text-left justify-content-between  align-items-center">
                                            <div>
                                              <div
                                                className={
                                                  op?.selectedModifier?.label
                                                    ? "bold"
                                                    : "normal"
                                                }
                                              >
                                                {op?.product_name}{" "}
                                                {`${
                                                  (!op.defaultSelected &&
                                                    op?.qty > 1) ||
                                                  (op.defaultSelected &&
                                                    op?.qty > 2)
                                                    ? ` - x${
                                                        op?.defaultSelected
                                                          ? op?.qty - 1
                                                          : op?.qty
                                                      }`
                                                    : ""
                                                }`}
                                              </div>
                                            </div>

                                            <div>
                                              {newPrice !== 0 && (
                                                <div>
                                                  $
                                                  <>
                                                    {Number(
                                                      Number(
                                                        Number(newPrice) -
                                                          Number(
                                                            op?.selectedModifier?.label.split(
                                                              "$"
                                                            )[1] || 0
                                                          )
                                                      ) * it?.qty
                                                    ).toFixed(2)}
                                                  </>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div>
                                            {op?.selectedModifier?.label && (
                                              <div
                                                className="d-flex"
                                                style={{
                                                  width: "100%",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <div className="text-left">
                                                  -{" "}
                                                  {
                                                    op?.selectedModifier?.label
                                                      .split("$")[0]
                                                      .split(" -")[0]
                                                  }
                                                </div>
                                                {
                                                  <div>
                                                    $
                                                    {Number(
                                                      modifierSelectdPrice *
                                                        it?.qty
                                                    ).toFixed(2)}
                                                  </div>
                                                }
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            );
                          })}
                        </>
                      </div>
                      {it?.note && (
                        <>
                          <h1
                            style={{
                              fontWeight: "500",
                              fontSize: 18,
                              color: "#212121",
                              textAlign: "left",
                            }}
                          >
                            Special Instructions
                          </h1>
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: 15,
                              color: "#666666",
                              textAlign: "left",
                            }}
                          >
                            {it?.note}
                          </p>
                        </>
                      )}
                      {cartItems?.length - 1 !== i && <hr />}
                    </div>
                  );
                })}
              </div>
            )}

            {cartItems?.length > 0 ? (
              <>
                {billingLoading ? (
                  <div className="mt-3">
                    {" "}
                    <Skeleton height="20px" width="30%" />
                    <Skeleton
                      style={{ marginTop: 20 }}
                      height="20px"
                      width="30%"
                    />
                    <Skeleton
                      style={{ marginTop: 20 }}
                      height="20px"
                      width="30%"
                    />
                    <Skeleton
                      style={{ marginTop: 20 }}
                      height="20px"
                      width="30%"
                    />
                    <hr />
                    <Skeleton height="40px" width="100%" />
                  </div>
                ) : (
                  <>
                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3 style={{ fontWeight: "600" }}>Subtotal :</h3>
                      <div>
                        <h3 style={{ fontWeight: "600" }} className="mr-1">
                          ${Number(billingDetails?.data?.subTotal).toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div>
                      <div
                        id="linkToggler1"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3 style={{ cursor: "pointer" }} className=" mt-1">
                          Tax & Fees <FaCaretDown size={20} />
                        </h3>
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            color: "black",
                          }}
                        >
                          <div>
                            {" "}
                            <h3 className="mr-1">
                              $
                              {parseFloat(
                                billingDetails?.data?.tax +
                                  Number(
                                    location?.enableIhd && method === "delivery"
                                      ? deliveryFee
                                      : 0
                                  )
                              ).toFixed(2)}{" "}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <UncontrolledCollapse toggler="#linkToggler1">
                        <div
                          style={{
                            fontSize: "16px",
                            backgroundColor: "inherit",
                          }}
                          className="py-2"
                        >
                          {billingDetails?.data?.taxDetails?.map(
                            (taxName, index) => {
                              return (
                                <div
                                  key={index}
                                  className="d-flex justify-content-between mb-2"
                                >
                                  <div>{taxName?.name}</div>
                                  <div>${taxName?.amount.toFixed(2)}</div>
                                </div>
                              );
                            }
                          )}

                          {(method === "pickup" ||
                            !location?.enableIhd ||
                            (location.enableIhd &&
                              restaurant.ihdFees === "restaurant")) &&
                            billingDetails?.data?.orderFee > 0 && (
                              <div className="d-flex justify-content-between mb-2">
                                <div>Ordering Fee</div>
                                <div>
                                  ${billingDetails?.data?.orderFee.toFixed(2)}
                                </div>
                              </div>
                            )}

                          {method === "delivery" && (
                            <div className="d-flex justify-content-between mb-2">
                              <div>Delivery Fee</div>
                              <div>
                                $
                                {location?.enableIhd &&
                                restaurant?.ihdFees === "partner"
                                  ? (
                                      billingDetails?.data
                                        ?.accumatedDeliveryFee + deliveryFee
                                    )?.toFixed(2)
                                  : (
                                      billingDetails?.data?.deliveryFee +
                                      (location?.enableIhd ? deliveryFee : 0)
                                    ).toFixed(2)}
                              </div>
                            </div>
                          )}
                        </div>
                      </UncontrolledCollapse>
                    </div>
                    <div className="bold">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h3
                            style={{
                              color: "black",
                            }}
                          >
                            Total :
                          </h3>
                        </div>
                        <div>
                          <h3 style={{ color: "#000" }}>
                            $
                            {(
                              billingDetails?.data?.total +
                              Number(
                                location?.enableIhd && method === "delivery"
                                  ? deliveryFee
                                  : 0
                              )
                            )?.toFixed(2)}
                          </h3>
                        </div>
                      </div>
                    </div>

                    {window.location.pathname.split("/")[3] !== "checkout" && (
                      <div style={{ textAlign: "center" }}>
                        <hr />

                        <Button
                          className="mt-3 py-3"
                          block
                          disabled={billingDetails?.data?.disableOrdering}
                          style={{
                            backgroundColor: theme?.primary,
                            color: "white",
                            letterSpacing: "0.1em",
                            fontSize: 12,
                            borderRadius: 6,
                          }}
                          onClick={() => {
                            if (
                              window.location.pathname.split("/")[3] ===
                              "checkout"
                            ) {
                              toggle();
                            } else {
                              history.push("checkout", { fromFlow: true });
                            }
                          }}
                        >
                          PROCEED TO CHECKOUT
                        </Button>
                        {billingDetails?.data?.disableOrdering && (
                          <small>
                            NOTE: Minimum Subtotal should be $
                            {deliveryZone?.minimumDeliveryAmount}
                          </small>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="d-flex align-items-center flex-column mb-4">
                <lottie-player
                  src="https://assets1.lottiefiles.com/packages/lf20_fkmiz05n.json"
                  background="transparent"
                  speed="1"
                  style={{ width: 100 }}
                  autoplay
                ></lottie-player>
                <h2 style={{ textTransform: "uppercase" }}>
                  Good Food is always cooking
                </h2>
                <span className="text-muted">Your cart is empty</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
