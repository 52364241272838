import create from "zustand";
import { persist } from "zustand/middleware";
const url = window.location.href.replace(/(\/ordering\/\w+).*/, "$1");

export const useCartStore = create(
  persist(
    (set, get) => ({
      cartItems: [],
      coupon: null,
      addItems: (items) => set({ cartItems: items }),
      clearCart: () => set({ cartItems: [] }),
      setCoupon: (coupon) => set({ coupon }),
    }),
    {
      name: "CART:" + url,
      getStorage: () => localStorage,
    }
  )
);
