/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { GoCheckCircleFill } from "react-icons/go";
import { useLocation } from "react-router";
import { Button } from "reactstrap";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import toTimeZone from "../../../utils/toTimeZone";
import DeliveryAddressModal from "./modals/DeliveryAddressModal";

export default function LocationRow({
  history,
  data,
  length,
  index,
  highlightedLocation,
  v2 = false,
}) {
  const [showModal, setShowModal] = useState(false);
  const [geofencing, setGeofencing] = useState([]);
  const { restaurant } = useOrderingRestaurant();
  const myRef = useRef(null);

  const {
    method,
    setLocation,
    setTotalLocation,
    setTiming,
    location: orderingLocation,
  } = useOrdering();

  const [, setAddress] = useState();
  const { theme } = useTheme();
  const { clearCart } = useCart();
  const location = useLocation();
  const pathname = location.pathname;
  const urlParams = new URLSearchParams(window.location.search);

  const getFormattedTime = (time) => {
    const splitedTime = time?.split(":");
    const date = toTimeZone(new Date(), location.timezone);
    date.setHours(splitedTime?.[0]);
    date.setMinutes(splitedTime?.[1]);

    return date;
  };

  const onClick = {
    delivery: () => {
      setLocation({ ...data });
      setGeofencing(data?.pickupDelivery?.geofencing);
      setShowModal(true);
    },
    "dine-in": () => {
      if (data?.isClosedPickup) return;
      setLocation({ ...data });
      setTiming("now");
      history.push(`${v2 ? "/" : ""}table`);
    },
    catering: () => {
      setLocation({ ...data });

      history.push({
        pathname: `${pathname.split("location")[0]}${v2 ? "/" : ""}catering`,
      });
    },
    pickup: () => {
      setLocation({ ...data });
      if (
        restaurant?.ordering?.skipStepTimeM &&
        !restaurant?.ordering?.orderLater
      ) {
        setTiming("now");
        history.push({
          pathname: `${pathname.split("location")[0]}${v2 ? "/" : ""}menu`,
        });
      } else if (
        restaurant?.ordering?.skipStepTimeM &&
        !restaurant?.ordering?.orderNow
      )
        history.push({
          pathname: `${pathname.split("location")[0]}${v2 ? "/" : ""}later`,
        });
      else {
        history.push({
          pathname: `${pathname.split("location")[0]}${v2 ? "/" : ""}timing`,
        });
      }
    },
  };

  const handleClick = () => {
    clearCart();
    onClick[method]();
  };

  const shrinkDates = (newDate) => {
    const date = newDate?.split(":");
    if (date[1]?.split(" ")[0] === "00" && date[0].split("")[0] !== "0") {
      return `${date[0] + date[1]?.split(" ")[1]}`;
    } else if (
      date[1]?.split(" ")[0] === "00" &&
      date[0].split("")[0] === "0"
    ) {
      return `${date[0].split("")[1] + date[1]?.split(" ")[1]}`;
    } else {
      return newDate;
    }
  };

  useEffect(() => {
    setTotalLocation(length);
    if (length === 1 && restaurant?.ordering?.skipStep) {
      handleClick();
    }
  }, []);

  useEffect(() => {
    if (urlParams.get("modal") === "open" && method === "delivery") {
      setGeofencing(orderingLocation?.pickupDelivery?.geofencing);
      setShowModal(true);
    }
  }, [urlParams, method]);

  const rowClassName =
    highlightedLocation === data?._id ? "vibrate_location_row" : "";

  useEffect(() => {
    if (highlightedLocation === data?._id && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [highlightedLocation]);

  return (
    <>
      <div
        ref={myRef}
        onClick={handleClick}
        className={
          index !== length - 1 ? `mb-3 ${rowClassName}` : `mb-0 ${rowClassName}`
        }
        style={{
          backgroundColor: "#fff",
          borderRadius: "5px",
          border: `2px solid ${theme?.primary}`,
          cursor: "pointer",
        }}
      >
        <div className="p-3 location_card">
          <div
            style={{ maxWidth: "280px", minWidth: "25%" }}
            className="flex-1 text-left"
          >
            <h3
              style={{
                fontSize: "18px",
                fontWeigth: 600,
                textAlign: "left",
                color: "#212121",
              }}
            >
              {data?.name}{" "}
              {highlightedLocation === data?._id && (
                <GoCheckCircleFill color="green" />
              )}
            </h3>
            <span style={{ color: "#666666", textAlign: "left" }}>
              {data.displayAddress}
            </span>
          </div>

          <div
            className="flex-1 location_availability text-left"
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#fff",
              padding: 5,
              borderRadius: "5px",
              boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
              color: "#212121",
            }}
          >
            {(!data?.isClosedPickup &&
              (method === "pickup" ||
                method === "catering" ||
                method === "dine-in")) ||
            (!data?.isClosedDelivery && method === "delivery") ||
            (!data?.isClosedPickup && method === "pickup") ||
            !data?.isClosedPickup ||
            (!data?.isClosedDelivery && method === "catering") ? (
              <h4
                className="mb-2"
                style={{
                  color: "#258e00",
                }}
              >
                OPEN TODAY
              </h4>
            ) : (
              <h4
                className="mb-2"
                style={{
                  color: "#E23535",
                }}
              >
                {((method === "pickup" ||
                  method === "dine-in" ||
                  method === "catering") &&
                  data?.willPickupOpenToday) ||
                (method === "delivery" && data?.willDeliveryOpenToday)
                  ? "CURRENTLY CLOSED"
                  : "CLOSED TODAY"}
              </h4>
            )}

            <>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: "600",
                  color: "#212121",
                }}
              >
                Pickup :
              </span>
              <div
                className="text-left"
                style={{
                  flexDirection: "row",
                  display: "flex",

                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#666666",
                  }}
                >
                  {!data?.isClosedPickup || data?.willPickupOpenToday ? (
                    <div>
                      {data?.allSchedules?.map((schedule, idx) => {
                        if (
                          schedule?.pickup?.startTime === "" ||
                          schedule?.pickup?.endTime === ""
                        ) {
                          return <></>;
                        }
                        return (
                          <>
                            {shrinkDates(
                              moment(
                                getFormattedTime(schedule?.pickup?.startTime)
                              ).format("hh:mm a")
                            )}{" "}
                            -{" "}
                            {shrinkDates(
                              moment(
                                getFormattedTime(schedule?.pickup?.endTime)
                              ).format("hh:mm a")
                            )}
                            {idx === 0 &&
                              data?.allSchedules?.filter(
                                (s) =>
                                  s.pickup.startTime !== "" &&
                                  s.pickup.endTime !== ""
                              )?.length > 1 &&
                              " / "}
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <span style={{ flex: 1 }}>Unavailable</span>
                  )}
                </span>
              </div>
              {/* <div className="text-left">
                {!data?.isClosedPickup ? (
                  <span
                    style={{
                      fontSize: 14,
                      color: "grey",
                    }}
                  >
                    Wait: {data?.pickupDelivery?.pickupPrepTime} mins
                  </span>
                ) : (
                  <></>
                )}
              </div> */}
              {(data?.pickupDelivery?.enableDelivery ||
                data?.enableIhd ||
                method === "catering") && (
                <>
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      color: "#212121",
                      // marginTop: 10,
                    }}
                  >
                    Delivery :{" "}
                  </span>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#666666",
                      }}
                    >
                      {!data?.isClosedDelivery ||
                      data?.willDeliveryOpenToday ? (
                        <div style={{}}>
                          {data?.allSchedules?.map((schedule, idx) => {
                            if (
                              schedule?.delivery?.startTime === "" ||
                              schedule?.delivery?.endTime === ""
                            ) {
                              return <></>;
                            }
                            return (
                              <>
                                {shrinkDates(
                                  moment(
                                    getFormattedTime(
                                      schedule?.delivery?.startTime
                                    )
                                  ).format("hh:mm a")
                                )}
                                -{" "}
                                {shrinkDates(
                                  moment(
                                    getFormattedTime(
                                      schedule?.delivery?.endTime
                                    )
                                  ).format("hh:mm a")
                                )}
                                {idx === 0 &&
                                  data?.allSchedules?.filter(
                                    (s) =>
                                      s.delivery.startTime !== "" &&
                                      s.delivery.endTime !== ""
                                  )?.length > 1 &&
                                  " / "}
                              </>
                            );
                          })}
                        </div>
                      ) : (
                        <span style={{ flex: 1 }}>Unavailable</span>
                      )}
                    </span>
                  </div>
                </>
              )}
            </>
          </div>
        </div>

        <Button
          block
          disabled={method === "dine-in" && data?.isClosedPickup}
          onClick={handleClick}
          style={{
            backgroundColor: theme?.primary,
            color: "#fff",
            fontWeight: "400",
            border: "0px",
            fontSize: 16,
            marginTop: 10,
            padding: 8,
            textAlign: "center",
            cursor: "pointer",
            borderRadius: 0,
            transform: "none",
            textTransform: "uppercase",
          }}
        >
          Select
        </Button>
      </div>
      <DeliveryAddressModal
        isOpen={showModal}
        location={data._id}
        geofencing={geofencing}
        onAddressSelect={(val) => {
          setAddress(val);
          setShowModal(!showModal);
          if (
            restaurant?.ordering?.skipStepTimeM &&
            !restaurant?.ordering?.orderLater
          ) {
            setTiming("now");
            history.push({
              pathname: `${pathname.split("location")[0]}${v2 ? "/" : ""}menu`,
            });
          } else if (
            restaurant?.ordering?.skipStepTimeM &&
            !restaurant?.ordering?.orderNow
          )
            history.push({
              pathname: `${pathname.split("location")[0]}${v2 ? "/" : ""}later`,
            });
          else {
            history.push({
              pathname: `${pathname.split("location")[0]}${
                v2 ? "/" : ""
              }timing`,
            });
          }
        }}
        toggle={() => setShowModal(!showModal)}
      />
    </>
  );
}
