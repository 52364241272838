import "@djthoms/pretty-checkbox";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useTheme } from "../../../store/theme";
import { toast } from "react-toastify";
import CustomCheckbox from "../../../components/Inputs/CustomCheckbox";
import leftPizza from "../../../assets/left.svg";
import rightPizza from "../../../assets/right.svg";
import wholePizza from "../../../assets/whole.svg";
import useScreenSize from "../../../hooks/useScreenSize";

const AdvancedPizzaModifiers = ({
  subProd,
  handleAddOptions,
  handleRemoveOption,
  isSelected,
  index,
  advancedPizzaOptions,
  size: parentSize,
  type: parentType,
  countByParentId,
  parentChanged,
  selectedOptions,
  name,
  max,
  sortOrder = 0,
}) => {
  const { product_name } = subProd;

  const { theme } = useTheme();

  const [size, setSize] = useState(parentSize || "all");
  const [type, setType] = useState(parentType || "regular");
  const [finalPrice, setFinalPrice] = useState();
  const { width, height } = useScreenSize();

  const shouldShowPrice =
    (subProd.defaultSelected && type === "extra") || !subProd.defaultSelected;

  const isToppingSelected = (toppingType) => {
    return toppingType === type;
  };

  const isSizeSelected = (toppingSize) => {
    return toppingSize === size;
  };

  useEffect(() => {
    if (isSelected(subProd) && type !== "" && size !== "") {
      if (subProd?.defaultSelected && type !== "extra") {
        handleAddOptions({
          ...subProd,
          type,
          size,
          advancedPizzaOptions,
          name: subProd.name,
          parent: subProd.name,
          price: 0,
          extraPrice: 0,
          halfPrice: 0,
          sort: Number(sortOrder || 0),
        });
      } else {
        handleAddOptions({
          ...subProd,
          type,
          size,
          advancedPizzaOptions,
          name: subProd.name,
          parent: subProd.name,
          sort: Number(sortOrder || 0),
        });
      }
    }
  }, [size, type, subProd]);

  useEffect(() => {
    setFinalPrice(subProd.price);
  }, [subProd]);

  useEffect(() => {
    const { halfPrice, extraPrice, price } = subProd;
    if (size !== "" && type !== "") {
      if (type === "regular" && (size === "left" || size === "right")) {
        setFinalPrice(halfPrice);
      } else if (type === "regular" && size === "all") {
        setFinalPrice(price);
      } else if (type === "extra" && size === "all") {
        setFinalPrice(extraPrice);
      } else if ((type === "extra" && size === "left") || size === "right") {
        setFinalPrice(extraPrice / 2);
      }
    }
  }, [size, type]);

  return (
    <div key={index}>
      <>
        <div className="">
          <div
            className="d-flex align-items-center"
            style={{
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                zIndex: 0,
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                marginTop: 3,
              }}
            >
              <CustomCheckbox
                className="cb1"
                key={product_name}
                type={"checkbox"}
                id={product_name}
                name={product_name}
                value={JSON.stringify(subProd)}
                onChange={(e) => {
                  if (e.target.checked) {
                    const count = selectedOptions.reduce(
                      (count, item) =>
                        item.name === subProd?.name ? count + 1 : count,
                      0
                    );

                    if (Number(max) > 0 && count >= Number(max) && max) {
                      toast.error(`You can select ${max} maximum.`);
                      return;
                    }

                    setType("regular");
                    setSize("all");
                    if (subProd?.defaultSelected) {
                      handleAddOptions({
                        ...subProd,
                        type: "regular",
                        size: "all",
                        price: 0,
                        extraPrice: 0,
                        halfPrice: 0,
                        name: name,
                        parent: name,
                      });
                    } else {
                      handleAddOptions({
                        ...subProd,
                        type: "regular",
                        size: "all",
                        name: name,
                        parent: name,
                      });
                    }
                  } else {
                    subProd["unCheck"] = true;
                    handleRemoveOption(subProd);
                  }
                }}
                checked={isSelected(subProd)}
                style={{
                  marginBottom: 10,
                  fontSize: 15,
                  fontWeight: "400",
                }}
              />
              <label
                className="ml-2 my-0"
                style={{ cursor: "pointer" }}
                htmlFor={product_name}
              >
                {product_name}
              </label>
            </div>

            <div style={{ fontSize: 16 }}>
              {finalPrice !== 0 && shouldShowPrice
                ? `$${Number(finalPrice).toFixed(2)}`
                : ""}
            </div>
          </div>
        </div>

        {isSelected(subProd) && (
          <>
            {/* <div className="mb-2 mx-4 mt-1 flex">
              <Button
                onClick={() => {
                  setType("regular");
                }}
                style={{
                  backgroundColor: isToppingSelected("regular")
                    ? theme.primary
                    : "white",
                  color: isToppingSelected("regular") ? "white" : "black",
                }}
              >
                Regular
              </Button>
            </div> */}
            <div
              className="my-2 mx-2 p-1"
              style={{
                width: "max-content",
                display: "flex",
                alignItems: "center",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
              }}
            >
              <Button
                size="sm"
                onClick={() => {
                  setSize("left");
                }}
                style={{
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: isSizeSelected("left")
                    ? theme.primary
                    : "white",
                  color: isSizeSelected("left") ? "white" : "black",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <img src={leftPizza} alt="Left Pizza Slice" />
                {width < 550 ? "L" : "Left"}
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  setSize("all");
                }}
                style={{
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: isSizeSelected("all")
                    ? theme.primary
                    : "white",
                  color: isSizeSelected("all") ? "white" : "black",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <img src={wholePizza} alt="Whole Pizza" />{" "}
                {width < 550 ? "W" : "Whole"}
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  setSize("right");
                }}
                style={{
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: isSizeSelected("right")
                    ? theme.primary
                    : "white",
                  color: isSizeSelected("right") ? "white" : "black",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <img src={rightPizza} alt="Right Pizza Slice" />{" "}
                {width < 550 ? "R" : "Right"}
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  if (isToppingSelected("extra")) {
                    setType("regular");
                  } else {
                    setType("extra");
                  }
                }}
                style={{
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: isToppingSelected("extra")
                    ? theme.primary
                    : "white",
                  color: isToppingSelected("extra") ? "white" : "black",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ height: "28px" }}></div>{" "}
                  <div>{width < 550 ? "Extra" : "Extra"}</div>
                </div>
              </Button>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default AdvancedPizzaModifiers;
