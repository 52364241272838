import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { AuthContext } from "../../context";

const AuthnetTokenization = forwardRef(
  ({ paymentFormRef, onSuccess, formik }) => {
    const authContext = useContext(AuthContext);
    const [form, setForm] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [saveCard, setSaveCard] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    //Submitting Form

    useImperativeHandle(paymentFormRef, () => ({
      async handleSubmit() {
        try {
          if (
            !form.state.card_cvc.isValid ||
            !form.state.card_exp.isValid ||
            !form.state.card_name.isValid ||
            !form.state.card_number.isValid
          ) {
            swal("Error", "Please enter valid details", "error");
            formik.setSubmitting(false);
            return;
          }
          form.submit("/post", {}, (status, response) => {
            if (status === 200) {
              onSuccess({ ...response?.json, saveCard: saveCard });
            }
          });
        } catch (error) {
          formik.setSubmitting(false);
          formik.logger.push({ error, info: "JupiterTokenization" });
          swal("Error", JSON.stringify(error), "error");
        }
      },
    }));

    useEffect(() => {
      if (!isInitialized) {
        const script = document.createElement("script");
        script.src =
          "https://js.verygoodvault.com/vgs-collect/2.14.0/vgs-collect.js";
        script.async = true;

        document.body.appendChild(script);
        script.addEventListener("load", () => setIsLoading(false));
      }
    }, [isInitialized]);

    useEffect(() => {
      if (!isLoading && !isInitialized) {
        setForm(
          window.VGSCollect.create(
            "tntdvxlc4nu",
            "sandbox",
            function (state) {}
          )
        );
      }
    }, [isLoading]);

    useEffect(() => {
      if (form && !isInitialized) {
        const css = {
          border: "1px solid #ccc",
          boxSizing: "border-box",
          paddingLeft: 10,

          "&:focus": {
            border: "1px solid #eee",
          },

          backgroundColor: "#EDEDED",
        };

        form.field("#cc-name .fake-input", {
          type: "text",
          name: "card_name",
          placeholder: "Joe Business",
          validations: ["required"],
          css: css,
        });

        form.field("#cc-number .fake-input", {
          type: "card-number",
          name: "card_number",
          successColor: "#4F8A10",
          errorColor: "#D8000C",
          placeholder: "4111 1111 1111 1111",
          validations: ["required", "validCardNumber"],
          css: css,
          showCardIcon: true,
        });

        form.field("#cc-cvc .fake-input", {
          type: "card-security-code",
          name: "card_cvc",
          placeholder: "344",
          validations: ["required", "validCardSecurityCode"],
          css: css,
        });

        form.field("#cc-expiration-date .fake-input", {
          type: "card-expiration-date",
          name: "card_exp",
          placeholder: "01 / 24",
          validations: ["required", "validCardExpirationDate"],
          css: css,
          yearLength: 2,
        });
        setIsInitialized(true);
      }
    }, [form]);

    if (isLoading)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: 200,
            alignItems: "center",
          }}
        >
          <Spinner size="lg" />
        </div>
      );

    return (
      <div>
        <div className=" mt-4">
          <div className="form-row">
            <div className="text-left col-md-6 mt-2">
              <label htmlFor="inputAddress2">Card Number</label>
              <div id="cc-number" className="form-group">
                <div className="form-control-static">
                  <span className="fake-input "></span>
                </div>
              </div>
            </div>
            <div className="text-left col-md-6 mt-2">
              <label htmlFor="inputAddress2">Card Holder Name</label>
              <div id="cc-name" className="form-group">
                <div className="form-control-static">
                  <span className="fake-input"></span>
                </div>
              </div>
            </div>{" "}
            <div className="text-left col-md-6 mt--3">
              <label htmlFor="inputAddress2">CVV</label>
              <div id="cc-cvc" className="form-group">
                <div className="form-control-static">
                  <span className="fake-input"></span>
                </div>
              </div>
            </div>{" "}
            <div className="text-left col-md-6 mt--3">
              <label htmlFor="inputAddress2">Expires</label>
              <div id="cc-expiration-date" className="form-group">
                <div className="form-control-static">
                  <span className="fake-input"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {authContext?.user && (
          <div className="custom-control custom-checkbox ml-2">
            <input
              className="custom-control-input"
              id="customCheck1"
              type="checkbox"
              onChange={(e) => setSaveCard(e.target.checked)}
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Save this card for future checkout
            </label>
          </div>
        )}
      </div>
    );
  }
);

export default AuthnetTokenization;
