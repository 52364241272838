import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ListGroup, Modal } from "reactstrap";
import ProductCard from "../../../components/ProductCard";
import { AuthContext } from "../../../context";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import logger from "../../../utils/logger";
import EmptyPlaceholder from "../menu/components/EmptyPlaceholder";
import ModalHeader from "./modals/ModalHeader";
import ViewDishModal from "./ViewDishModal";
import useOrdering from "../../../store/ordering";

export default function UsualDishesModal({ isOpen, toggle }) {
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);
  const { restaurant } = useOrderingRestaurant();
  const { location } = useOrdering();
  const [dish, setDish] = useState({});
  const [openDish, setOpenDish] = useState(false);
  const { addToCart } = useCart();

  const { entities, find } = useEntity("eat/ordering/usuals");

  useEffect(() => {
    find({
      customerId: authContext?.user?.customer?._id,
      restaurant: restaurant?.userId?._id,
      filterDSP: location?.connectedToStream ? "true" : "false",
    });
  }, []);

  return (
    <Modal
      className="modal-dialog-centered"
      style={{
        fontFamily: "Inter",
        maxWidth: "600px",

        maxHeight: "700px",
      }}
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader title="  Order My Usual" toggle={toggle} />
      <div className="popular-modal">
        <ListGroup className="mx-3 my-2" flush>
          {entities?.data?.map((d, i) => {
            return (
              <ProductCard
                key={i}
                onClick={() => {
                  setDish(d);
                  setOpenDish(true);
                }}
                maxText={50}
                theme={theme}
                data={d}
              />
            );
          })}
        </ListGroup>
        {entities?.data?.length <= 0 && (
          <div className="mb-4">
            <EmptyPlaceholder />
          </div>
        )}
      </div>
      {Object.keys(dish).length > 0 ? (
        <ViewDishModal
          theme={theme}
          onAddToCart={(op, notes, qty) => {
            try {
              addToCart(
                {
                  _id: dish?._id,
                  qty,
                  modifiers: op,
                  note: notes,
                  availableQty: dish?.qty,
                },
                true
              );
            } catch (error) {
              logger.push({ error, info: "MainMenuSectionViewDish" });
              toast.error(error.message);
            }
          }}
          isOpen={openDish}
          dish={dish}
          toggle={() => setOpenDish(!openDish)}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
}
