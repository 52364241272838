import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import { AuthContext } from "../../context";
import { useCartStore } from "../../store";
import useOrdering from "../../store/ordering";
import { useTheme } from "../../store/theme";
import { Axiom } from "@axiomhq/js";
import { useOrderingRestaurant } from "../../store/ordering/useOrderingRestaurant";

const axiom = new Axiom({
  token: "xaat-4e49a24a-ae12-414c-84d1-c81c23ad3151",
});

const CARD_STYLE = {
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

const CardForm = ({
  clientSecret,
  onSuccess,
  stripeCustomerId,
  formik,
  guestFormik,
  guestErrors,
  guestRef,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);
  const { activeTab, gateway } = useOrdering();
  const { restaurant } = useOrderingRestaurant();
  const { cartItems } = useCartStore();

  const [loading, setLoading] = useState(false);
  const [saveCard, setSaveCard] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (restaurant?.partner?._id === "64bad1c18bbd2ca3e60331a7") {
      axiom.ingest(
        "stripe-logs",
        [
          {
            message: "Stripe payment initiated",
            customer: authContext?.user?.customer?.email || "guest",
            time: new Date(),
          },
        ],
        { timestampField: true }
      );
    }

    if (!stripe || !elements) {
      if (restaurant?.partner?._id === "64bad1c18bbd2ca3e60331a7") {
        axiom.ingest(
          "stripe-logs",
          [
            {
              error: "Stripe is not loaded error",
              customer: authContext?.user?.customer?.email || "guest",
              time: new Date(),
              partner: restaurant?.partner?._id,
            },
          ],
          { timestampField: true }
        );
      }

      setLoading(false);
      return;
    }

    if (activeTab === "guest") {
      guestFormik();
      if (Object.keys(guestErrors).length !== 0) {
        guestRef.current.scrollIntoView();
        setLoading(false);
        return;
      }
    }

    const cardElement = elements.getElement(
      CardNumberElement,
      CardCvcElement,
      CardExpiryElement
    );

    if (restaurant?.partner?._id === "64bad1c18bbd2ca3e60331a7") {
      axiom.ingest(
        "stripe-logs",
        [
          {
            message: "Card elements fetched for stripe",
            customer: authContext?.user?.customer?.email || "guest",
            time: new Date(),
            partner: restaurant?.partner?._id,
          },
        ],
        { timestampField: true }
      );
    }

    cardElement.on("change", function (event) {
      document.getElementById("card-errors");
      let errorMessage = null;
      if (event.error) {
        errorMessage = event.error.message;
        setLoading(false);
        return;
      }
      toast.error(errorMessage);
    });

    if (restaurant?.partner?._id === "64bad1c18bbd2ca3e60331a7") {
      axiom.ingest(
        "stripe-logs",
        [
          {
            message: "Card elements validated for stripe",
            customer: authContext?.user?.customer?.email || "guest",
            time: new Date(),
            partner: restaurant?.partner?._id,
          },
        ],
        { timestampField: true }
      );
    }

    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(
            CardNumberElement,
            CardCvcElement,
            CardExpiryElement
          ),
        },
        save_payment_method: true,
      });

    if (restaurant?.partner?._id === "64bad1c18bbd2ca3e60331a7") {
      axiom.ingest(
        "stripe-logs",
        [
          {
            message: "Payment Intent fetched from server",
            customer: authContext?.user?.customer?.email || "guest",
            time: new Date(),
            context: JSON.stringify(paymentIntent),
            partner: restaurant?.partner?._id,
          },
        ],
        { timestampField: true }
      );
    }

    if (stripeError) {
      toast.error(stripeError.message);
      if (restaurant?.partner?._id === "64bad1c18bbd2ca3e60331a7") {
        axiom.ingest(
          "stripe-logs",
          [
            {
              error: "Stripe error occured",
              customer: authContext?.user?.customer?.email || "guest",
              time: new Date(),
              context: JSON.stringify(stripeError),
              partner: restaurant?.partner?._id,
            },
          ],
          { timestampField: true }
        );
      }

      setLoading(false);
      return;
    }

    if (activeTab === "guest") {
      if (restaurant?.partner?._id === "64bad1c18bbd2ca3e60331a7") {
        axiom.ingest(
          "stripe-logs",
          [
            {
              message: "Stripe payment confirmed guest",
              customer: authContext?.user?.customer?.email || "guest",
              time: new Date(),
              partner: restaurant?.partner?._id,
            },
          ],
          { timestampField: true }
        );
      }

      onSuccess({ ...paymentIntent, saveCard, stripeCustomerId });
    }

    if (paymentIntent.status === "succeeded" && activeTab !== "guest") {
      if (restaurant?.partner?._id === "64bad1c18bbd2ca3e60331a7") {
        axiom.ingest(
          "stripe-logs",
          [
            {
              message: "Stripe payment confirmed",
              customer: authContext?.user?.customer?.email || "guest",
              time: new Date(),
              context: JSON.stringify({
                ...paymentIntent,
                saveCard,
                stripeCustomerId,
              }),
              partner: restaurant?.partner?._id,
            },
          ],
          { timestampField: true }
        );
      }

      onSuccess({ ...paymentIntent, saveCard, stripeCustomerId });
    }
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <form id="payment-form" onSubmit={handleSubmit}>
          <div
            style={{
              width: "100%",
              borderRadius: 6,
            }}
          >
            <div>
              <div className="mb-2" style={{ fontWeight: "500" }}>
                Card Number
              </div>
              <div
                style={{
                  border: `1px solid #eee`,
                  padding: "5px 10px",
                  borderRadius: 8,
                }}
              >
                <CardNumberElement options={CARD_STYLE} />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                width: "100%",
                borderRadius: 6,
              }}
            >
              <div>
                <div className="mb-2" style={{ fontWeight: "500" }}>
                  CVC
                </div>
                <div
                  style={{
                    border: `1px solid #eee`,
                    padding: "5px 10px",
                    borderRadius: 8,
                  }}
                >
                  <CardCvcElement options={CARD_STYLE} />
                </div>
              </div>
            </div>
            <div className="mx-3"></div>
            <div
              style={{
                width: "100%",
                borderRadius: 6,
              }}
            >
              <div>
                <div className="mb-2" style={{ fontWeight: "500" }}>
                  Expiry
                </div>
                <div
                  style={{
                    border: `1px solid #eee`,
                    padding: "5px 10px",
                    borderRadius: 8,
                  }}
                >
                  <CardExpiryElement options={CARD_STYLE} />
                </div>
              </div>
            </div>
          </div>
        </form>

        {authContext?.user && (
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              id="customCheck1"
              type="checkbox"
              onChange={(e) => setSaveCard(e.target.checked)}
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Save this card for future checkout
            </label>
          </div>
        )}

        <Button
          block
          disabled={
            loading ||
            formik?.values?.billing?.disableOrdering ||
            cartItems?.length <= 0 ||
            activeTab === "sign-in" ||
            activeTab === "register" ||
            (formik.values.payment_method === "saved-card" &&
              formik.values.selected_card === "") ||
            ((formik.values.payment_method === "new-card" ||
              formik.values.payment_method === "saved-card") &&
              !gateway) ||
            formik.isSubmitting
          }
          className="mt-4"
          style={{
            backgroundColor: theme.primary,
            color: "white",
            letterSpacing: "0.1em",
            textTransform: "uppercase",
          }}
          type="submit"
        >
          {loading ? <Spinner size={"sm"} /> : "Place Order"}
        </Button>
      </form>
    </>
  );
};

export default CardForm;
