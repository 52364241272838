import { useCartStore } from "../../store";

export const useCart = () => {
  const { addItems, clearCart: emptyCart, cartItems } = useCartStore();

  function addToCart(item, fromModal = false) {
    if (!fromModal) {
      item.qty = 1;
    }

    const itemIndex = cartItems.findIndex((cartItem) => {
      if (cartItem._id !== item._id || cartItem.note !== item.note) {
        return false;
      }

      if (cartItem.modifiers.length !== item.modifiers.length) {
        return false;
      }

      return cartItem.modifiers?.every((modifier) => {
        return item.modifiers?.some((itemModifier) => {
          if (
            itemModifier?.selectedModifier?.label ===
              modifier?.selectedModifier?.label &&
            modifier?.product_id === itemModifier?.product_id
          ) {
            if (
              modifier.advancedPizzaOptions &&
              itemModifier.advancedPizzaOptions
            ) {
              if (
                modifier.size === itemModifier.size &&
                modifier.type === itemModifier.type
              ) {
                return true;
              } else return false;
            }
            if (modifier.qty === itemModifier?.qty) {
              return true;
            } else return false;
            return true;
          }
          return false;
        });
      });
    });

    if (itemIndex === -1) {
      addItems([
        ...cartItems,
        {
          _id: item._id,
          qty: item.qty,
          modifiers: item.modifiers,
          note: item.note || "",
        },
      ]);
    } else {
      cartItems[itemIndex].qty = cartItems[itemIndex].qty + item.qty;
      addItems([...cartItems]);
    }
  }

  function removeFromCart(idx) {
    let itemlist = cartItems;
    itemlist.splice(idx, 1);
    addItems([...itemlist]);
  }

  function changeQuantity(idx) {
    let item = cartItems[idx];
    if (item.qty > 1) {
      cartItems[idx].qty = cartItems[idx].qty - 1;
      addItems([...cartItems]);
    } else if (item?.qty === 1) {
      removeFromCart(idx);
    }
  }
  function clearCart() {
    emptyCart();
  }

  return {
    addToCart,
    removeFromCart,
    clearCart,
    changeQuantity,
  };
};
