/* eslint-disable react-hooks/exhaustive-deps */
import classnames from "classnames";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import swal from "sweetalert";
import TimelineHeader from "../../components/Header/SimpleHeader";
import PartnerContext from "../../context/partner-context";
import { useEntity } from "../../hooks/useEntity";
import { useUploadImage } from "../../hooks/useUploadImage";
import logger from "../../utils/logger";
import { validationSchemaEditPartner } from "../../utils/schemas/EditPartner";
import Email from "./tabs/Email";
import General from "./tabs/General";
import Payments from "./tabs/Payments";
import Privacy from "./tabs/Privacy";

export default function EditPartner(props) {
  const { upload } = useUploadImage();
  const {
    updateEntity,
    findOne,
    loading: isLoading,
    entity,
  } = useEntity("partner");

  const [tabs, setTabs] = useState(1);
  const [headerLogo, setHeaderLogo] = useState("");

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  useEffect(() => {
    if (props?.location?.state) {
      findOne(props.location.state);
    }
  }, [props.location.state]);

  let data = entity?.data;

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setValues,
  } = useFormik({
    initialValues: {
      partnerUrl: "",
      name: "",
      phone: "",
      email: "",
      orderFee: "",
      firstTierUpto: "",
      secondTierUpto: "",
      thirdTierUpto: "",
      fourthTierUpto: "",
      firstTierOnlineOrder: "",
      secondTierOnlineOrder: "",
      thirdTierOnlineOrder: "",
      fourthTierOnlineOrder: "",
      sendGridEmail: "",
      sendGridKey: "",
      paymentUrl: "",
      apiUser: "",
      paymentPassword: "",
      jupiterBasic: "",
      vaultId: "",
      tilledSecretKey: "",
      tilledPublishableKey: "",
      privacy: "",
      terms: "",
      headerLogo: "",
      timezone: "",
      address: "",
      password: "",
      fixedValue: 0,
      orderWebhook: "",
      customerWebhook: "",
      partnerMode: "",
      notificationEmail: "",
      enableFoodTronix: false,
    },
    validationSchema: validationSchemaEditPartner,

    onSubmit: async (values) => {
      let headerPath = "";

      if (headerLogo) {
        headerPath = await upload({
          file: headerLogo,
          owner: "partner",
          resize: false,
        });
      }

      const doc = {
        url: values?.partnerUrl,
        name: values?.name,
        phone: values?.phone,
        headerLogo: !headerPath
          ? entity?.data?.partner?.headerLogo
          : headerPath,

        email: values?.email,
        role: "partner",
        address: values.address,
        emailApi: {
          email: values.sendGridEmail,
          key: values.sendGridKey,
        },
        privacy: values.privacy,
        terms: values.terms,
        paymentConfig: {
          apiUrl: values?.paymentUrl,
          apiUser: values?.apiUser,
          apiPassword: values?.paymentPassword,
          jupiterBasic: values?.jupiterBasic,
          vaultId: values?.vaultId,
          tilledSecretKey: values?.tilledSecretKey,
          tilledPublishableKey: values?.tilledPublishableKey,
        },
        orderFee: values.orderFee,
        enableFoodTronix: values.enableFoodTronix,
        fixedValue: values?.fixedValue,
        timezone: values?.timezone,
        password: values?.password,
        tiers: {
          firstTier: {
            upto: values.firstTierUpto,
            onlineOrder: values.firstTierOnlineOrder,
          },
          secondTier: {
            upto: values.secondTierUpto,
            onlineOrder: values.secondTierOnlineOrder,
          },
          thirdTier: {
            upto: values.thirdTierUpto,
            onlineOrder: values.thirdTierOnlineOrder,
          },
          forthTier: {
            upto: values.fourthTierUpto,
            onlineOrder: values.fourthTierOnlineOrder,
          },
        },
        orderWebhook: values.orderWebhook,
        customerWebhook: values.customerWebhook,
        partnerMode: values.partnerMode,
        notificationEmail: values.notificationEmail,
      };

      try {
        await updateEntity(data?._id, {
          ...doc,
        });
        toast.success("Partner updated Successfully");
        props.history.goBack();
      } catch (error) {
        logger.push({ error, info: "EditPartner" });
        swal("Failed", "Record Not Updated", "error");
      }
    },
  });
  console.log(errors);
  useEffect(() => {
    if (entity) {
      setValues({
        partnerUrl: data?.partner?.url,
        name: data?.partner?.name,
        phone: data?.partner?.phone,
        email: data?.email,
        sendGridEmail: data?.partner?.emailApi?.email,
        sendGridKey: data?.partner?.emailApi?.key,
        paymentUrl: data?.partner?.paymentConfig?.apiUrl,
        apiUser: data?.partner?.paymentConfig?.apiUser,
        paymentPassword: data?.partner?.paymentConfig?.apiPassword,
        jupiterBasic: data?.partner?.paymentConfig?.jupiterBasic,
        vaultId: data?.partner?.paymentConfig?.vaultId,
        privacy: data?.partner?.privacy,
        terms: data?.partner?.terms,
        headerLogo: data?.partner?.headerLogo,
        timezone: data?.partner?.timezone,
        address: data?.partner?.address,
        orderFee: entity?.data?.partner?.orderFee,
        firstTierUpto: entity?.data?.partner?.tiers?.firstTier?.upto,
        secondTierUpto: entity?.data?.partner?.tiers?.secondTier?.upto,
        thirdTierUpto: entity?.data?.partner?.tiers?.thirdTier?.upto,
        fourthTierUpto: entity?.data?.partner?.tiers?.forthTier?.upto,
        firstTierOnlineOrder:
          entity?.data?.partner?.tiers?.firstTier?.onlineOrder,
        secondTierOnlineOrder:
          entity?.data?.partner?.tiers?.secondTier?.onlineOrder,
        thirdTierOnlineOrder:
          entity?.data?.partner?.tiers?.thirdTier?.onlineOrder,
        fourthTierOnlineOrder:
          entity?.data?.partner?.tiers?.forthTier?.onlineOrder,
        fixedValue: entity?.data?.partner?.fixedValue || 0,
        password: entity?.data?.password,
        orderWebhook: entity?.data?.partner?.orderWebhook,
        customerWebhook: entity?.data?.partner?.customerWebhook,
        partnerMode: entity?.data?.partner?.partnerMode,
        notificationEmail: entity?.data?.partner?.notificationEmail,
        tilledSecretKey: entity?.data?.partner?.paymentConfig.tilledSecretKey,
        tilledPublishableKey:
          entity?.data?.partner?.paymentConfig.tilledPublishableKey,
        enableFoodTronix: entity?.data?.partner?.enableFoodTronix,
      });
    }
  }, [entity]);

  if (isLoading)
    return (
      <>
        <TimelineHeader
          name="Edit Partner"
          parentName="Partners"
          showBtn={true}
        ></TimelineHeader>
        <Card className="mt--6 mx-4">
          <CardBody>
            <div
              style={{
                padding: 20,
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <center>
                <Spinner size="lg" color="primary" type="border" />
              </center>
            </div>
          </CardBody>
        </Card>
      </>
    );

  return (
    <>
      <TimelineHeader name="Edit Partner" parentName="Partners" showBtn={true}>
        <Button
          className="btn-primary text-white"
          color="primary"
          size="md"
          onClick={handleSubmit}
        >
          {isSubmitting ? <Spinner size="sm" /> : "Save"}
        </Button>
        <Button
          className="btn-neutral text-black"
          color="default"
          size="md"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Cancel
        </Button>
      </TimelineHeader>

      <div
        className="mt--6 mx-4 card p-4"
        style={{ zIndex: 999, position: "relative", minHeight: "80vh" }}
      >
        <Nav
          className="nav-fill flex-column flex-md-row "
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-book mr-2" />
              General
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-envelope mr-2" />
              Integrations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 3}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 3,
              })}
              onClick={(e) => toggleNavs(e, 3)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-money mr-2" />
              Payments
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 4}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 4,
              })}
              onClick={(e) => toggleNavs(e, 4)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-info-circle mr-2" />
              Privacy And Terms
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent className="mt-5" activeTab={"tabs" + tabs}>
          <PartnerContext.Provider value={entity?.data}>
            <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
              <General
                data={data}
                errors={errors}
                handleChange={handleChange}
                values={values}
                touched={touched}
                header={(e) => setHeaderLogo(e)}
                setFieldValue={setFieldValue}
              />
            </TabPane>

            <TabPane tabId="tabs2">
              <Email
                errors
                touched
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                values={values}
              />
            </TabPane>
            <TabPane tabId="tabs3">
              <Payments
                errors
                touched
                handleChange={handleChange}
                values={values}
              />
            </TabPane>
            <TabPane tabId="tabs4">
              <Privacy
                errors
                touched
                setFieldValue={setFieldValue}
                values={values}
                handleChange={handleChange}
              />
            </TabPane>
          </PartnerContext.Provider>
        </TabContent>
      </div>
    </>
  );
}
