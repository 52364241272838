import React from "react"

const TableRowRestaurantOrdersReport = ({ data }) => {
  return (
    <tr className="text-left">
      <td>{data?.orderNum}</td>
      <td>{data?.items.map((it) => it.name).join(",")}</td>
      <td>${data?.total?.toFixed(2)}</td>
      <td>${data?.subTotal?.toFixed(2)}</td>
      <td>${data?.tax?.toFixed(2)}</td>
      <td>${data?.tip?.toFixed(2)}</td>
      <td>${data?.deliveryFee?.toFixed(2)}</td>
      <td>${data?.payment?.orderFee?.toFixed(2)}</td>
    </tr>
  )
}

export default TableRowRestaurantOrdersReport
