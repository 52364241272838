import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import SuperTable from "../../../widgets/SuperTable";
import { truncateString } from "../../../utils/truncate";

const GatewayRow = ({ data: t }) => {
  return (
    <tr className="text-left">
      <td>{t.topic}</td>
      <td>{truncateString(t.message, 100)}</td>
    </tr>
  );
};

const Headers = ["Topic", "Message"];

export default function MQTTLogs(props) {
  const {
    find: findGateway,
    entities: gateways,
    loading: isLoading,
  } = useEntity("mqtt-orders");
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { restaurant } = useAdminStore();

  useEffect(() => {
    findGateway({ restaurant });
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  return (
    <>
      {!isLoading ? (
        <SuperTable
          sort={handleSort}
          showCheckboxes={false}
          data={gateways?.data}
          onPageChange={handlePageChange}
          OnRowPerPageChange={handleRowsPerPageChange}
          total={gateways?.length}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          loading={isLoading}
          history={props.history}
          row={GatewayRow}
          columns={Headers}
          isLoading={isLoading}
          emptyMessage="Currently no gateways"
        />
      ) : (
        <div
          style={{
            padding: 20,
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <Spinner size="lg" color="primary" type="border" />
          </center>
        </div>
      )}
    </>
  );
}
