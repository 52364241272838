/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useTheme } from "../../store/theme";
import getSortedArray from "../../utils/getSortedArray";

const HorizontalList = ({
  items,
  onChangeItem,
  selected,
  cats,
  hasExtraCats,
}) => {
  const [selectedItemId, setSelectedItemId] = useState(selected);
  const { theme } = useTheme();

  const scrollContainerStyle = {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    flex: 2,
    maxWidth: 780,
  };

  const itemStyle = {
    flex: "0 0 auto",
    marginRight: "10px",
    marginBottom: "10px",
  };

  const handleItemClick = (itemId) => {
    setSelectedItemId(itemId);
  };

  useEffect(() => {
    onChangeItem(selectedItemId);
  }, [selectedItemId]);

  const array = hasExtraCats
    ? [{ name: "Menu", _id: null }, ...getSortedArray(items, "sort")]
    : [...getSortedArray(items, "sort")];

  useEffect(() => {
    if (!selected) {
      setSelectedItemId(array[0]?._id);
      onChangeItem(array[0]?._id);
    }
  }, []);

  return (
    <ListGroup
      style={scrollContainerStyle}
      horizontal
      className="mt-4 hide_scroll"
    >
      {array.map((item) => {
        return (
          <ListGroup.Item
            className="mr-2 px-4 py-2 rounded text-center"
            onClick={() => handleItemClick(item?._id)}
            key={item._id}
            style={{
              cursor: "pointer",
              backgroundColor:
                item._id === selectedItemId ? `${theme.primary}` : `white`,
              border:
                item._id === selectedItemId
                  ? `2px solid ${theme.primary}`
                  : `2px solid ${theme.primary}`,

              color: item._id === selectedItemId ? "white" : theme.primary,
              fontSize: 15,
              fontWeight: 600,
              ...itemStyle,
            }}
          >
            {item?.name}
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default HorizontalList;
