import { Col, Row, Table } from "reactstrap";
import GroupedPizzaToppings from "../../../ordering/menu/components/GroupPizzaToppings";
import { getAdvancedPizzaPrice } from "../../../../utils/getAdvancedPizzaPrice";
import { useEffect } from "react";
import { useEntity } from "../../../../hooks/useEntity";
import compareArrays from "../../../../utils/compareArray";

export default function Products({ data }) {
  const { find, entities: allItems } = useEntity("eat/ordering/items");

  useEffect(() => {
    find({
      location: data?.location?._id,
    });
  }, [data]);

  return (
    <Row className="card-wrapper text-left mt--4">
      <Col md={12} lg="12" sm={12}>
        <Table responsive bordered className="text-right">
          <thead className="thead-light">
            <tr className="text-left">
              <th>Product</th>
              <th>Special Instructions</th>
              <th>Modifiers</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {data?.items?.map((d, i) => {
              let o = allItems?.data?.data?.find(
                (itm) => itm?._id === d?.itemId
              );

              const allSubprods = Object.values(o?.modifiers || {}).flatMap(
                (modif) => modif?.subProducts
              );

              const allSelectedModifiers = allSubprods.filter(
                (subProd) => subProd?.defaultSelected
              );
              let basePrice = d?.price;

              // console.log(it?.modifiers);

              const missingSelected = compareArrays(
                allSelectedModifiers,
                d?.modifiers
              );

              const filteredBasedOnParentModifs = missingSelected.filter(
                (f) => {
                  const idx = d?.modifiers?.findIndex(
                    (t) =>
                      f.selectedParentValue.label === t.product_name ||
                      f?.selectedParentValue?.label == ""
                  );
                  return idx !== -1;
                }
              );

              const finalMissingSelected = filteredBasedOnParentModifs.filter(
                (obj, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      t.product_id === obj.product_id &&
                      t.product_name === obj.product_name
                  )
              );

              d?.modifiers?.map((selected) => {
                if (!selected?.advancedPizzaOptions) {
                  basePrice =
                    parseFloat(basePrice) +
                    parseFloat(selected?.price || 0) *
                      parseFloat(selected.qty || 1);
                }
              });

              return (
                <tr className="text-left">
                  <td>{d?.name}</td>
                  <td>
                    {d?.instruction?.length > 0
                      ? d?.instruction
                      : "No instructions"}
                  </td>
                  <td>
                    {d?.modifiers?.length > 0 ? (
                      <>
                        <GroupedPizzaToppings
                          viewOrder={true}
                          it={d}
                          data={d?.modifiers}
                        />

                        <div className="text-left">
                          {finalMissingSelected?.map((selected) => {
                            return `No ${selected.product_name} `;
                          })}
                          {d?.modifiers
                            ?.filter(
                              (m) =>
                                !m.defaultSelected ||
                                (m.defaultSelected && m.qty > 1)
                            )
                            ?.map((item) => {
                              if (!item.advancedPizzaOptions) {
                                return `${item.product_name} ($${(
                                  item?.price || 0
                                )?.toFixed(2)}) ${item?.qty > 1 ? "-" : ""}  ${
                                  item?.qty > 1
                                    ? item?.defaultSelected
                                      ? `X${item?.qty - 1}`
                                      : `${`X${item?.qty}`}`
                                    : ""
                                } ,`;
                              }
                            })}
                        </div>
                      </>
                    ) : (
                      <div className="text-muted text-left">No modifiers</div>
                    )}
                  </td>
                  <td>{d?.qty}</td>
                  <td className="text-right">${d?.price?.toFixed(2)}</td>
                  <td className="text-right">
                    $
                    {Number(
                      Number(getAdvancedPizzaPrice(d?.modifiers)) +
                        Number(basePrice * d?.qty)
                    ).toFixed(2)}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="text-right" colSpan="5">
                Sub Total:
              </td>
              <td>${data?.payment?.subTotal?.toFixed(2)}</td>
            </tr>
            <tr>
              <td className="text-right" colSpan="5">
                Order Fee
              </td>
              <td>${data.payment.orderFee?.toFixed(2)}</td>
            </tr>
            {data.method === "delivery" && data.payment.deliveryFee > 0 && (
              <tr>
                <td className="text-right" colSpan="5">
                  Delivery Fee
                </td>
                <td>${data.payment.deliveryFee?.toFixed(2)}</td>
              </tr>
            )}
            {(data.method === "catering" ||
              data?.cateringDetails?.method === "delivery") &&
              data.payment.deliveryFee > 0 && (
                <tr>
                  <td className="text-right" colSpan="5">
                    Delivery Fee
                  </td>
                  <td>${data.payment.deliveryFee?.toFixed(2)}</td>
                </tr>
              )}
            {data?.payment?.taxDetails?.length === 0 &&
              data?.payment?.tax > 0 && (
                <tr>
                  <td className="text-right" colSpan="5">
                    Tax:
                  </td>
                  <td>${data?.payment?.tax?.toFixed(2) || 0}</td>
                </tr>
              )}
            {data?.payment?.serviceCharge > 0 && (
              <tr>
                <td className="text-right" colSpan="5">
                  Service Charge:
                </td>
                <td>${data?.payment?.serviceCharge?.toFixed(2) || 0}</td>
              </tr>
            )}
            {data.payment.taxDetails.map((op) => {
              return (
                <tr>
                  <td className="text-right" colSpan="5">
                    {op.name}
                  </td>
                  <td>${op?.amount?.toFixed(2)}</td>
                </tr>
              );
            })}

            {data?.payment?.tip?.toFixed(2) > 0 && (
              <tr>
                <td className="text-right" colSpan="5">
                  Tip:
                </td>
                <td>${data?.payment?.tip?.toFixed(2) || 0}</td>
              </tr>
            )}
            {data?.payment?.discount?.toFixed(2) > 0 && (
              <tr>
                <td className="text-right" colSpan="5">
                  Discount
                </td>
                <td>-${data?.payment?.discount?.toFixed(2)}</td>
              </tr>
            )}
            {data?.payment?.loyaltyDiscount?.toFixed(2) > 0 && (
              <tr>
                <td className="text-right" colSpan="5">
                  Loyalty Discount
                </td>
                <td>-${data?.payment?.loyaltyDiscount?.toFixed(2)}</td>
              </tr>
            )}

            <tr>
              <td className="text-right" colSpan="5">
                Total
              </td>
              <td>${data?.payment?.total?.toFixed(2)}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}
