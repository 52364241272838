/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { Badge, Button, CardText, Col, Row, Spinner } from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import RefundModal from "../components/RefundModal";

export default function OrderDetails({ data, history }) {
  const [modalOpen, setModalOpen] = useState(false);

  const { create: processRefund, loading } = useEntity("payment/refund");

  const { findOne, entity } = useEntity("eat/ordering/order-alias");

  const { create: reprint } = useEntity("order/reprint");

  const { find: getOrderPrintStatus, entities: printStatus } = useEntity(
    "order/legacy-print-status"
  );
  const { create: processVoid, loading: voidLoading } =
    useEntity("payment/void");

  const [isRefundResult, setIsRefundResultt] = useState(false);

  const { create: isRefund, loading: refundStateLoading } =
    useEntity("order/isRefund");

  const handleProcessRefund = async (amount) => {
    try {
      await processRefund({ orderId: data?._id, amount });
      swal("Success", "Refund Approved", "success");
      setModalOpen(false);
      history.goBack();
    } catch (error) {
      swal({ text: "Error while processing refund.", icon: "error" });
    }
  };

  useEffect(() => {
    isRefund({ orderId: data?._id })
      .then((res) => {
        console.log("res", res);
        setIsRefundResultt(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (data?.location?.forwardPrint && process.env.NODE_ENV === "production") {
      getOrderPrintStatus({
        restaurantId: data?.restaurant?._id,
        partnerId: data.partner,
        orderId: data.orderNum,
      });
    }
  }, [data]);

  useEffect(() => {
    findOne(data?._id);
  }, []);

  return (
    <>
      {" "}
      {refundStateLoading ? (
        <div
          style={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner color="primary" />
        </div>
      ) : (
        <CardText className="mb-4">
          {[
            {
              key: "Order ID",
              value: "#" + data?.orderNum,
            },
            {
              key: "Store name",
              value: data?.restaurant?.restaurant?.name,
            },
            {
              key: "Store URL",
              value: data?.restaurant?.restaurant?.url,
            },
            {
              key: "Customer",
              value: data?.customer?.name,
            },

            {
              key: "Email",
              value: data?.customer?.email,
            },
            {
              key: "Telephone",
              value: data?.customer?.phone,
            },
            {
              key: "Push Notification",
              value: data?.notified ? (
                <Badge color="success">Sent</Badge>
              ) : (
                <Badge color="warning">Failed</Badge>
              ),
            },
            {
              key: "Total",
              value: "$" + data?.payment?.total?.toFixed(2),
            },
            {
              key: "Order Status",
              value: <Badge color="success">{data?.status}</Badge>,
            },
            {
              key: "Payment Status",
              value: (
                <Badge
                  color={
                    data?.payment?.status === "approved" ? "success" : "warning"
                  }
                >
                  {data?.payment?.status
                    ? data?.payment?.status
                    : "Needs Payment"}
                </Badge>
              ),
            },
            {
              key: "Order Placed",
              value: moment(data?.createdAt).format("MM/DD/YYYY hh:mm A"),
            },
            data?.location?.enableIhd
              ? {
                  key: "Pickup At",
                  value:
                    data.orderTiming === "now"
                      ? moment(data?.orderNowPickupDate).format(
                          "MM/DD/YYYY hh:mm A"
                        )
                      : moment(data?.scheduledOn).format("MM/DD/YYYY hh:mm A"),
                }
              : null,
            data?.location?.enableIhd
              ? {
                  key: "Ready At",
                  value:
                    data.orderTiming === "now"
                      ? moment(
                          data?.location?.enableIhd
                            ? data?.ihdDeliveryTime
                            : data?.orderNowPickupDate
                        ).format("MM/DD/YYYY hh:mm A")
                      : moment(data?.scheduledOn).format("MM/DD/YYYY hh:mm A"),
                }
              : null,
            {
              key: "Order Method",
              value: String(data?.method).toUpperCase(),
            },
            data?.method === "dine-in"
              ? {
                  key: "Table No.",
                  value: data?.tableNo,
                }
              : null,
            {
              key: "Location",
              value: data?.location?.name,
            },
            {
              key: "Address",
              value:
                data?.method === "delivery"
                  ? (data?.deliveryAddress?.apartmentNo?.length > 0
                      ? data?.deliveryAddress?.apartmentNo + " "
                      : "") + data?.deliveryAddress?.formatted_address
                  : data?.location?.address,
            },
            (data?.payment?.status === "approved" ||
              data?.payment?.status === "succeeded" ||
              data?.payment?.status === "paid") &&
            !refundStateLoading
              ? {
                  key: isRefundResult ? "Refund" : "Void",
                  value: (
                    <Button
                      disabled={voidLoading}
                      onClick={async () => {
                        if (isRefundResult) {
                          setModalOpen(true);
                        } else {
                          try {
                            await processVoid({ orderId: data?._id });
                            swal({ text: "Void successful", icon: "success" });
                            history.goBack();
                          } catch (error) {
                            swal({
                              text: "Error while processing void.",
                              icon: "error",
                            });
                          }
                        }
                      }}
                      size="sm"
                      color="primary"
                    >
                      {isRefundResult ? (
                        "Refund"
                      ) : voidLoading ? (
                        <Spinner size={"sm"} />
                      ) : (
                        "Void"
                      )}
                    </Button>
                  ),
                }
              : false,
            {
              key: "Print Status",
              value: (
                <>
                  <Badge
                    color={
                      data?.printStatus === "printed" ||
                      (printStatus?.success &&
                        printStatus?.print_status !== "0")
                        ? "success"
                        : "warning"
                    }
                  >
                    {data?.printStatus === "printed" ||
                    (printStatus?.success && printStatus?.print_status !== "0")
                      ? "Success"
                      : "Failed"}
                  </Badge>
                  <Button
                    style={{
                      marginLeft: 10,
                    }}
                    onClick={async () => {
                      try {
                        await reprint({ orderId: data?._id });
                        swal({
                          text: "Order added to queue.",
                          icon: "success",
                        });
                        // history.goBack();
                      } catch (error) {
                        swal({
                          text: "Error while reprint.",
                          icon: "error",
                        });
                      }
                    }}
                    size="sm"
                    color="secondary"
                  >
                    Click to reprint
                  </Button>
                </>
              ),
            },
            {
              key: "Order Details",
              value: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button disabled={entity?.data?.message} size="sm">
                    <a
                      href={`https://7ord.co/o/${entity?.data?.alias}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click to open
                    </a>
                  </Button>
                  <small>{entity?.data?.message}</small>
                </div>
              ),
            },
            data?.note?.length > 0
              ? {
                  key: "Special Instructions",
                  value: data?.note,
                }
              : false,
            data?.moreInfoData && Object.keys(data?.moreInfoData)?.length > 0
              ? {
                  key: <b> More Info</b>,
                  value: "",
                }
              : false,
            data?.moreInfoData && Object.keys(data?.moreInfoData)?.length > 0
              ? {
                  key: (
                    <div>
                      {Object.keys(data?.moreInfoData)?.map((more) => {
                        return <div>{more} </div>;
                      })}
                    </div>
                  ),
                  value: (
                    <div>
                      {Object.values(data?.moreInfoData)?.map((more) => {
                        return <div>{more}</div>;
                      })}
                    </div>
                  ),
                }
              : false,
          ]
            .filter((r) => r)
            .map((d) => {
              return (
                <Row className="my-1">
                  <Col md={2}>
                    <span style={{ fontWeight: 500 }}>{d.key}</span>
                  </Col>
                  <Col md={8}>
                    <span>{d.value}</span>
                  </Col>
                </Row>
              );
            })}
        </CardText>
      )}
      <RefundModal
        open={modalOpen}
        refundAmount={data?.payment?.total?.toFixed(2)}
        toggle={() => setModalOpen(false)}
        orderId={data?._id}
        refundProcess={handleProcessRefund}
        loading={loading}
      />
    </>
  );
}
