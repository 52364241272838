export default function checkItemCount(array, parentValues) {
  const parentNames = parentValues?.map((p) => p?.label);
  console.log("GOMEN", parentNames);

  const count = array?.filter(
    (ar) =>
      parentNames?.includes(ar?.selectedParentValue.label) &&
      ar?.defaultSelected
  );

  return count.length === 0 ? count.length : count.length + 1;
}
