/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import swal from "sweetalert";
import AuthnetTokenization from "../../../../components/Authnet/AuthnetTokenization";
import JupiterTokenization from "../../../../components/Jupiter/JupiterTokenization";
import TilledTokenization from "../../../../components/Tilled/TilledTokenization";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import apiCaller from "../../../../api/apiCaller";

import BridgepayTokenization from "../../../../components/Bridgepay/BridgepayTokenization";

import StripeTokenization from "../../../../components/Stripe/StripeContainer";

import { useCartStore } from "../../../../store";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import CardsTable from "./CardsTable";

import CardsTableBridgePay from "./CardsTableBridgepay";
import CardsTableStripe from "./CardsTableStripe";

const CustomButton = ({ isActive, onClick, title }) => {
  const { theme } = useTheme();
  const { restaurant } = useOrderingRestaurant();
  return (
    <Button
      className="pay_method_btn"
      onClick={onClick}
      disabled={restaurant?.restaurant?.payment === "online"}
      style={{
        backgroundColor: isActive ? theme?.primary : "white",
        color: isActive ? "white" : "#666666",
        border: isActive ? theme?.primary : "1px solid #666666",
        textTransform: "uppercase",
        letterSpacing: "0.1em",
        fontWeight: "400",
      }}
    >
      {title}
    </Button>
  );
};

const PaymentMethodsCheckout = ({
  handleSelectCard,
  handleChangeMethod,
  paymentFormRef,
  onTokenizationSuccess,
  formik,
  guestFormik,
  guestErrors,
  guestRef,
  isSaveCard,
  authMethod,
}) => {
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);
  const { find: findCards, entities: cards } = useEntity("eat/card");
  const { method } = useOrdering();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const { restaurant } = useOrderingRestaurant();
  const { cartItems } = useCartStore();

  const { gateway, location } = useOrdering();
  const [activeTab, setActiveTab] = useState(
    method === "pickup" || method === "delivery" || method === "dine-in"
      ? "pay-there"
      : "new-card"
  );

  useEffect(() => {
    handleChangeMethod(activeTab);
  }, []);

  const shouldShowPayThere =
    ((method === "pickup" || method === "delivery" || method === "dine-in") &&
      (authContext.user || restaurant?.enableGuestPaythere)) ||
    (location?.walkupOrderingPaymentMethods?.includes("pay-there") &&
      method === "walkup");

  const shouldShowNewCard =
    (method === "walkup" &&
      location?.walkupOrderingPaymentMethods?.includes("pay-online")) ||
    (method !== "walkup" && (authContext.user || authMethod === "guest"));

  useEffect(() => {
    if (
      restaurant?.ordering &&
      restaurant?.ordering?.disableAmex &&
      shouldShowNewCard
    ) {
      swal("We are not accepting Amex cards", "", "info");
    }
  }, [restaurant?.ordering?.disableAmex]);

  useEffect(() => {
    if (method === "walkup") {
      const mtd = shouldShowNewCard ? "new-card" : "pay-there";
      setActiveTab(mtd);
      handleChangeMethod(mtd);
    }
  }, [shouldShowNewCard, shouldShowPayThere, method]);

  useEffect(() => {
    if (authContext.user) {
      findCards({
        provider: gateway?.gateway,
      });
    }
  }, []);

  useEffect(() => {
    if (cards?.data?.length > 0) {
      let card = cards?.data[selectedIndex];
      handleSelectCard(card);
    }
  }, [selectedIndex, cards]);

  useEffect(() => {
    if (
      !authContext?.user &&
      !restaurant.enableGuestPaythere &&
      method !== "walkup"
    ) {
      setActiveTab("new-card");
      handleChangeMethod("new-card");
    }
  }, []);

  useEffect(() => {
    if (restaurant.payment === "online") {
      setActiveTab("new-card");
      handleChangeMethod("new-card");
    }
  }, []);

  useEffect(() => {
    if (
      method === "delivery" &&
      authContext?.user &&
      restaurant?.payment !== "online"
    ) {
      handleChangeMethod("pay-there");
    }
  }, [method, authContext, restaurant]);

  useEffect(() => {
    if (activeTab === "saved-card" && gateway?.gateway === "stripe") {
      const getPaymentIntent = async () => {
        //create payload
        const payload = {
          amount: formik.values.billing.total,
          gatewayId: gateway?._id,
          partnerId: restaurant?.partner?.partner?._id,
          userId: authContext?.user?._id,
          line_items: cartItems,
        };

        // create intent
        const response = await apiCaller("/stripe/create-intent", {
          method: "POST",
          body: payload,
        });

        //set client secret and customerId
        setClientSecret(response?.data?.client_secret);
        setStripeCustomerId(response?.data?.stripeCustomerId);
      };

      getPaymentIntent();
    }
  }, [activeTab, gateway]);

  useEffect(() => {
    if (!authContext.user && authMethod !== "guest") {
      if (shouldShowPayThere) {
        setActiveTab("pay-there");
        handleChangeMethod("pay-there");
      } else {
        setActiveTab("");
        handleChangeMethod("");
      }
    } else if (!authContext.user && authMethod === "guest") {
      if (
        shouldShowPayThere &&
        (restaurant?.payment === "both" || restaurant?.payment === "pay-there")
      ) {
        setActiveTab("pay-there");
        handleChangeMethod("pay-there");
      } else {
        setActiveTab("new-card");
        handleChangeMethod("new-card");
      }
    } else if (authContext.user) {
      if (
        shouldShowPayThere &&
        (restaurant?.payment === "both" || restaurant?.payment === "pay-there")
      ) {
        setActiveTab("pay-there");
        handleChangeMethod("pay-there");
      } else {
        setActiveTab("new-card");
        handleChangeMethod("new-card");
      }
    } else {
      setActiveTab("new-card");
      handleChangeMethod("new-card");
    }
  }, [authContext, authMethod]);

  const TokenisationComponent = useMemo(
    () => () => {
      if (gateway?.gateway === "jupiter") {
        return (
          <JupiterTokenization
            paymentFormRef={paymentFormRef}
            formik={formik}
            onSuccess={(data) => {
              onTokenizationSuccess(data);
            }}
          />
        );
      } else if (gateway?.gateway === "tilled") {
        return (
          <TilledTokenization
            paymentFormRef={paymentFormRef}
            formik={formik}
            onSuccess={(data) => {
              onTokenizationSuccess(JSON.stringify(data));
            }}
          />
        );
      } else if (gateway?.gateway === "authorize.net") {
        // gateway === authorize.net
        return (
          <AuthnetTokenization
            paymentFormRef={paymentFormRef}
            formik={formik}
            onSuccess={(data) => {
              onTokenizationSuccess(JSON.stringify(data));
            }}
          />
        );
      } else if (gateway?.gateway === "bridgepay") {
        return (
          <BridgepayTokenization
            paymentFormRef={paymentFormRef}
            formik={formik}
            onSuccess={(data) => {
              console.log(data, "DATA FROM BRIDGE PAY TOKEN");
              onTokenizationSuccess(data);
            }}
            guestFormik={guestFormik}
            guestRef={guestRef}
            guestErrors={guestErrors}
            isSaveCard={(va) => isSaveCard(va)}
          />
        );
      } else
        return (
          <StripeTokenization
            paymentFormRef={paymentFormRef}
            formik={formik}
            onSuccess={(data) => {
              onTokenizationSuccess(data);
            }}
            guestFormik={guestFormik}
            guestRef={guestRef}
            guestErrors={guestErrors}
          />
        );
    },
    [formik.values?.billing?.total, guestErrors, gateway]
  );

  return (
    <div
      style={{
        boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#fff",
        borderRadius: "6px",
      }}
      className="px-3 py-3"
    >
      <div className="d-flex align-items-center justify-content-between">
        <h1
          style={{
            fontWeight: "600",
            color: theme.primary,
          }}
        >
          Payment
        </h1>
        <h1
          style={{
            fontWeight: "500",
            color: "gray",
            fontSize: 12,
          }}
        >
          *All cards are securely tokenized
        </h1>
      </div>
      <hr style={{ margin: 0, padding: 0 }} />
      <div
        className="mt-3 card_buttons_group"
        style={{ backgroundColor: "#fff" }}
      >
        {shouldShowPayThere && restaurant?.payment !== "online" && (
          <CustomButton
            isActive={activeTab === "pay-there"}
            title="Pay there"
            onClick={() => {
              setActiveTab("pay-there");
              if (restaurant?.restaurant?.payment !== "online") {
                handleChangeMethod("pay-there");
              }
            }}
          />
        )}
        {shouldShowNewCard && restaurant?.payment !== "pay-there" && (
          <CustomButton
            isActive={activeTab === "new-card"}
            title="New card"
            onClick={() => {
              setActiveTab("new-card");
              if (restaurant?.restaurant?.payment !== "online") {
                handleChangeMethod("new-card");
              }
            }}
          />
        )}
        {restaurant?.payment !== "pay-there" && authContext.user && (
          <>
            <CustomButton
              isActive={activeTab === "saved-card"}
              title="Saved Card"
              onClick={() => {
                setActiveTab("saved-card");
                if (restaurant?.restaurant?.payment !== "online") {
                  handleChangeMethod("saved-card");
                }
              }}
            />
          </>
        )}
      </div>
      <div className="mt-4">
        {activeTab === "saved-card" && (
          <>
            {gateway?.gateway === "stripe" ? (
              <CardsTableStripe
                showRadio
                cards={cards}
                setSelectedIndex={(val) => {
                  setSelectedIndex(val);
                }}
                selectedIndex={selectedIndex}
                clientSecret={clientSecret}
                stripeCustomerId={stripeCustomerId}
                gateway={gateway}
                onSuccess={onTokenizationSuccess}
                formik={formik}
              />
            ) : gateway?.gateway === "bridgepay" ? (
              <CardsTableBridgePay
                showRadio
                cards={cards}
                setSelectedIndex={(val) => {
                  setSelectedIndex(val);
                }}
                selectedIndex={selectedIndex}
                gateway={gateway}
                onSuccess={onTokenizationSuccess}
                formik={formik}
              />
            ) : (
              <CardsTable
                showRadio
                cards={cards}
                setSelectedIndex={(val) => {
                  setSelectedIndex(val);
                }}
                selectedIndex={selectedIndex}
              />
            )}
          </>
        )}

        {activeTab === "new-card" && <TokenisationComponent />}
        {activeTab === "pay-there" && (
          <span
            style={{ fontWeight: "600" }}
            className="mx-1 d-flex align-items-center flex-column"
          >
            {restaurant?.payment !== "online" ? (
              <h3>Pay at Restaurant</h3>
            ) : (
              <h3>Pay at Restaurant is not available</h3>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default PaymentMethodsCheckout;
