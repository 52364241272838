const hosts = {
  staging: "https://new.stagingmyordering.com/api",
  production: "https://app.myordering.online/api",
  // local: "https://app.myordering.online/api",
  development: "https://thebitedev.com/api",
  local: "http://localhost:5002/api",
  test: `https://${process.env.REACT_APP_HOST}/api`,
  productionv2: `https://${process.env.REACT_APP_HOST}/api`,
};

const fronend_url = {
  staging: "stagingmyordering.com",
  production: "app.myordering.online",
  development: "thebitedev.com",
  local: "localhost",
  test: process.env.REACT_APP_HOST,
  productionv2: process.env.REACT_APP_HOST,
};

const streamPOSUrls = {
  staging:
    "https://dev.oauthgateway.com/redirect/biteheist-dev/biteheist?code=",
  production:
    "https://stream.oauthgateway.com/redirect/myordering/myordering?code=",
  development:
    "https://dev.oauthgateway.com/redirect/biteheist-dev/biteheist?code=",
  local: "https://dev.oauthgateway.com/redirect/biteheist-dev/biteheist?code=",
  // Copied from STAGING
  test: "https://dev.oauthgateway.com/redirect/biteheist-dev/biteheist?code=",
};

const streamDSPUrls = {
  staging:
    "https://dev.oauthgateway.com/redirect/biteheist-dev/biteheist-dsp?code=",
  production:
    "https://stream.oauthgateway.com/redirect/myordering/myordering-dsp?code=",
  development:
    "https://dev.oauthgateway.com/redirect/biteheist-dev/biteheist-dsp?code=",
  local:
    "https://dev.oauthgateway.com/redirect/biteheist-dev/biteheist-dsp?code=",
  // Copied from STAGING
  test: "https://dev.oauthgateway.com/redirect/biteheist-dev/biteheist-dsp?code=",

  productionv2:
    "https://stream.oauthgateway.com/redirect/myordering/myordering-dsp?code=",
};

const firebaseConfig = {
  apiKey: "AIzaSyAhAyR45Y1F4PC2wZ4H-h7nFZVT0j7_Bhk",
  authDomain: "todo-60d90.firebaseapp.com",
  projectId: "todo-60d90",
  storageBucket: "todo-60d90.appspot.com",
  messagingSenderId: "702936396504",
  appId: "1:702936396504:web:913028a7852dbdebd59dad",
  vapidKey:
    "BHmZexMYH91gRAj8bw2qwEGhaS413AMQNA6pVxQ6z-qiMzTKv2XuY-j5rqnZv_eWVhENEXAu8rnkJXk0PrapkH4",
};

const jupiterConfig = {
  //TODO:values to be replaced for production
  production: {
    vaultId: "tntaxfujub5",
    environment: "live",
    tokenizationEndpoint: "/v1/transactions/creditcard/tokenization/",
    sessionHost:
      "https://platform.jupiterhq.com/v1/transactions/creditcard/tokenization/",
  },
  staging: {
    vaultId: "tntne5koztu",
    environment: "sandbox",
    tokenizationEndpoint: "/v1/transactions/creditcard/tokenization/",
    sessionHost:
      "https://sandbox-platform.jupiterhq.com/v1/transactions/creditcard/tokenization/",
  },
  development: {
    vaultId: "tntne5koztu",
    environment: "sandbox",
    tokenizationEndpoint: "/v1/transactions/creditcard/tokenization/",
    sessionHost:
      "https://sandbox-platform.jupiterhq.com/v1/transactions/creditcard/tokenization/",
  },
  local: {
    vaultId: "tntne5koztu",
    environment: "sandbox",
    tokenizationEndpoint: "/v1/transactions/creditcard/tokenization/",
    sessionHost:
      "https://sandbox-platform.jupiterhq.com/v1/transactions/creditcard/tokenization/",
  },
  // Copied from STAGING
  test: {
    vaultId: "tntne5koztu",
    environment: "sandbox",
    tokenizationEndpoint: "/v1/transactions/creditcard/tokenization/",
    sessionHost:
      "https://sandbox-platform.jupiterhq.com/v1/transactions/creditcard/tokenization/",
  },
  productionv2: {
    vaultId: "tntaxfujub5",
    environment: "live",
    tokenizationEndpoint: "/v1/transactions/creditcard/tokenization/",
    sessionHost:
      "https://platform.jupiterhq.com/v1/transactions/creditcard/tokenization/",
  },
};

export const env = process.env.REACT_APP_ENV || "local";
export const version = process.env.REACT_APP_VERSION || "";

if (env === "development" || env === "local") {
  console.log("ENVIRONEMNTS", env);
}

export const HOST = hosts[env.concat(version)] || hosts.local;
export const URL = fronend_url[env.concat(version)] || fronend_url.local;
export const DATA_IMPORTER_URL =
  env === "local"
    ? `http://localhost:5005/x/data-import`
    : env === "staging"
    ? `https://stagingmyordering.com/x/data-import`
    : env === "production"
    ? `https://app.myordering.online/x/data-import`
    : ``;

export const BridgePayTokenPayUrl =
  env === "production"
    ? `https://www.bridgepaynetsecuretx.com/WebSecurity/TokenPay/js/tokenPay.js`
    : "https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js";

export const TokenPayHost =
  env === "production"
    ? `https://www.bridgepaynetsecuretx.com/Bridgepay.WebSecurity/echo.aspx`
    : `https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/echo.aspx`;

export const FIRE_BASE_CONFIG = firebaseConfig;
export const JUPITER =
  jupiterConfig[env.concat(version)] || jupiterConfig.local;
export const CRYPTOJS_SECRET =
  "MYS&*&(&&(*^(*(*%$%$#$%^*&#$%*fvgc hjb$%er*&#$%&";
export const STREAM_POS_URL =
  streamPOSUrls[env.concat(version)] || streamPOSUrls.local;
export const STREAM_DSP_URL =
  streamDSPUrls[env.concat(version)] || streamPOSUrls.local;
