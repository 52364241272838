import { Button, Col, Container, Input, Row } from "reactstrap";
import { HOST } from "../../../../config";

import { toast } from "react-toastify";
import { useRestaurant } from "../../../../hooks/restaurant/useRestaurant";
import { useEntity } from "../../../../hooks/useEntity";
import { useContext, useEffect } from "react";
import { useAdminStore } from "../../../../store/admin";
import { AuthContext } from "../../../../context";
import { isMobile } from "react-device-detect";

export default function StreamPOSIntegration({
  errors,
  touched,
  values,
  handleChange,
}) {
  const { restaurant } = useAdminStore();
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { findOne, entity: settings } = useEntity("restaurant");

  useEffect(() => {
    findOne(authRest || restaurant);
  }, []);

  const handleLoginClick = async () => {
    const apiUrl = `${HOST}/stream-sso/v1/sso`;
    const requestBody = {
      email: settings?.data?.restaurant?.email,
      full_name: settings?.data?.restaurant?.username,
      phone_number: "+1" + settings?.data?.restaurant?.phone,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (!responseData?.url || responseData?.url === undefined) {
        toast.error("Failed to login");
        return;
      }

      if (isMobile) {
        window.location.href = responseData.url;
      } else {
        window.open(responseData.url, "_blank");
      }

      // Handle the response as needed
      console.log(responseData);
    } catch (error) {
      // Handle errors
      console.error("Error during API call:", error);
    }
  };

  console.log("RESTAURANT", restaurant);
  return (
    <div style={{ zIndex: 999, position: "relative" }}>
      <Container fluid>
        <Row className="mt-3">
          <Col md="6">
            <Row className="text-left">
              <Col
                md="6"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Enable Stream POS
              </Col>
              <Col md="6">
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={values?.enableStreamPOS}
                    value={values?.enableStreamPOS}
                    onChange={handleChange("enableStreamPOS")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md="6">
            <Row className="text-left">
              <Col
                md="6"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Enable Stream DSP
              </Col>
              <Col md="6">
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={values?.enableStreamDSP}
                    value={values?.enableStreamDSP}
                    onChange={handleChange("enableStreamDSP")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
        {(values?.enableStreamPOS || values?.enableStreamDSP) && (
          <Row className="mt-3">
            <Col md="6">
              <Row>
                <Col md="6" className="text-left">
                  <Button size="sm" onClick={handleLoginClick}>
                    Login To Stream
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
