/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { FaCaretDown } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { UncontrolledCollapse } from "reactstrap";
import { useCart } from "../../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../../hooks/useEntity";
import { useCartStore } from "../../../../store";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import compareArrays from "../../../../utils/compareArray";
import groupByNameParent from "../../../../utils/groupByParent";
import GroupedPizzaToppings from "../../menu/components/GroupPizzaToppings";
import { toast } from "react-toastify";

const CartSection = ({
  billingDetails,
  method,
  loading,
  couponDoc,
  allItems,
}) => {
  const { find, entities } = useEntity("eat/ordering/items");
  const { cartItems } = useCartStore();
  const { restaurant } = useOrderingRestaurant();
  const {
    location,
    method: orderingMethod,
    laterDate,
    laterTime,
    deliveryFee,
    timing,
    cateringData,
  } = useOrdering();

  const { changeQuantity, addToCart } = useCart();
  const { theme } = useTheme();

  useEffect(() => {
    let dateString;
    if (timing === "later") {
      dateString = new Date(laterDate);
      const splittedTime = laterTime.split(":");
      dateString.setHours(splittedTime[0], splittedTime[1]);
    }
    find({
      restaurant: restaurant?.userId?._id,
      location: location?._id,
      method: orderingMethod,
      catering: cateringData?.catering ? "true" : "false",
      filterDSP: location?.connectedToStream ? "true" : "false",
      schedule: timing === "later" ? dateString : undefined,
    });
  }, []);

  return (
    <div>
      <div>
        <div className="py-3 pr-4">
          <div>
            <>
              <h1
                style={{
                  fontWeight: "600",
                  color: theme.primary,
                }}
              >
                Order Summary
              </h1>
              <hr className="mb-3" style={{ padding: 0, margin: 0 }} />
              {cartItems?.length > 0 && (
                <div
                  style={{
                    background: "#FFFFFF",
                  }}
                >
                  {cartItems?.map((it, i) => {
                    let o = allItems?.find((itm) => itm._id === it._id);
                    const allSubprods = Object.values(
                      o?.modifiers || {}
                    ).flatMap((modif) => modif?.subProducts);

                    const allSelectedModifiers = allSubprods.filter(
                      (subProd) => subProd?.defaultSelected
                    );

                    const missingSelected = compareArrays(
                      allSelectedModifiers,
                      it?.modifiers
                    );
                    const filteredBasedOnParentModifs = missingSelected.filter(
                      (f) => {
                        const idx = it?.modifiers?.findIndex(
                          (t) =>
                            f.selectedParentValue.label === t.product_name ||
                            f?.selectedParentValue?.label === "" ||
                            !f?.selectedParentValue?.label
                        );
                        return idx !== -1;
                      }
                    );

                    const finalMissingSelected =
                      filteredBasedOnParentModifs.filter(
                        (obj, index, self) =>
                          index ===
                          self.findIndex(
                            (t) =>
                              t.product_id === obj.product_id &&
                              t.product_name === obj.product_name
                          )
                      );

                    const quantityItem = cartItems.reduce((ac, ar) => {
                      if (ar?._id === o?._id) {
                        return ac + ar.qty;
                      } else return ac;
                    }, 0);

                    return (
                      <div className=" mb-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h1
                            style={{
                              fontWeight: "500",
                              fontSize: 18,
                              color: "#000",
                            }}
                          >
                            {o?.name}
                          </h1>
                          <h3
                            style={{
                              fontSize: 20,
                              color: "#212121",
                              fontWeight: "600",
                            }}
                          >
                            {Number(o?.price) !== 0
                              ? `$${Number(o?.price * it?.qty).toFixed(2)}`
                              : ""}
                          </h3>
                        </div>
                        <div
                          className="py-1 rounded"
                          style={{
                            backgroundColor: "#ededed",
                            color: "#666666",
                            maxWidth: 100,
                            textAlign: "center",
                          }}
                        >
                          <span
                            onClick={() => {
                              changeQuantity(i);
                            }}
                            style={{ fontWeight: "700", cursor: "pointer" }}
                          >
                            -
                          </span>
                          <span
                            className="mx-4"
                            style={{ fontWeight: "700", color: "#000" }}
                          >
                            {it?.qty}
                          </span>

                          <span
                            onClick={() => {
                              if (
                                quantityItem >= o?.moqPerOrder &&
                                cateringData?.catering
                              ) {
                                toast(
                                  `Max quantity per order is ${o?.moqPerOrder}`
                                );

                                return;
                              }
                              addToCart({ ...it });
                            }}
                            style={{
                              fontWeight: "700",
                              cursor: "pointer",
                              opacity:
                                o?.catering && it?.qty >= o?.moqPerOrder
                                  ? 0.4
                                  : 1,
                            }}
                          >
                            +
                          </span>
                        </div>
                        <div
                          className="mt-3"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          {finalMissingSelected?.map((selected) => {
                            return (
                              <div className="d-flex text-left justify-content-between  align-items-center">
                                <div>
                                  <div className={"normal"}>
                                    No {selected?.product_name}
                                  </div>
                                </div>

                                <div></div>
                              </div>
                            );
                          })}
                          <GroupedPizzaToppings it={it} data={it?.modifiers} />

                          {groupByNameParent(
                            it?.modifiers?.filter(
                              (it) =>
                                (!it?.defaultSelected || it?.qty > 1) &&
                                !it.advancedPizzaOptions
                            )
                          ).map((modif) => {
                            return (
                              <>
                                {restaurant?.showModifierName && (
                                  <div className={"bold text-left"}>
                                    {modif.name}
                                  </div>
                                )}
                                {modif.data.map((op) => {
                                  let newPrice;
                                  if (
                                    op?.qty &&
                                    op?.qty > 1 &&
                                    !op?.defaultSelected
                                  ) {
                                    newPrice = op?.price * op?.qty;
                                  } else if (
                                    op?.qty &&
                                    op?.qty > 1 &&
                                    op?.defaultSelected
                                  ) {
                                    const quantity = Number(op?.qty) - 1;
                                    newPrice = op?.price * quantity;
                                  } else newPrice = op?.price * 1;

                                  const modifierSelectdPrice =
                                    op?.selectedModifier?.label?.split(
                                      "$"
                                    )[1] === undefined
                                      ? 0
                                      : op?.selectedModifier?.label?.split(
                                          "$"
                                        )[1];

                                  return (
                                    <>
                                      {!op?.advancedPizzaOptions && (
                                        <>
                                          <div className="d-flex text-left justify-content-between  align-items-center">
                                            <div>
                                              <div
                                                className={
                                                  op?.selectedModifier?.label
                                                    ? "bold"
                                                    : "normal"
                                                }
                                              >
                                                {op?.product_name}{" "}
                                                {`${
                                                  (!op.defaultSelected &&
                                                    op?.qty > 1) ||
                                                  (op.defaultSelected &&
                                                    op?.qty > 2)
                                                    ? ` - x${
                                                        op?.defaultSelected
                                                          ? op?.qty - 1
                                                          : op?.qty
                                                      }`
                                                    : ""
                                                }`}
                                              </div>
                                            </div>

                                            <div>
                                              {newPrice !== 0 && (
                                                <div>
                                                  $
                                                  <>
                                                    {Number(
                                                      Number(
                                                        Number(newPrice) -
                                                          Number(
                                                            op?.selectedModifier?.label.split(
                                                              "$"
                                                            )[1] || 0
                                                          )
                                                      ) * it?.qty
                                                    ).toFixed(2)}
                                                  </>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div>
                                            {op?.selectedModifier?.label && (
                                              <div
                                                className="d-flex"
                                                style={{
                                                  width: "100%",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <div className="text-left">
                                                  -{" "}
                                                  {
                                                    op?.selectedModifier?.label
                                                      .split("$")[0]
                                                      .split(" -")[0]
                                                  }
                                                </div>
                                                {modifierSelectdPrice !== 0 && (
                                                  <div>
                                                    $
                                                    {Number(
                                                      modifierSelectdPrice *
                                                        it?.qty
                                                    ).toFixed(2)}
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            );
                          })}
                        </div>
                        {it?.note && (
                          <div>
                            <h1
                              style={{
                                fontWeight: "500",
                                fontSize: 18,
                                color: "#212121",
                                textAlign: "left",
                              }}
                            >
                              Special Instructions
                            </h1>
                            <p
                              className="py-2 px-3"
                              style={{
                                fontWeight: "500",
                                fontSize: 15,
                                color: "#666666",
                                textAlign: "left",
                                backgroundColor: "#ededed",
                              }}
                            >
                              {it?.note}
                            </p>
                          </div>
                        )}
                        {cartItems?.length - 1 !== i && <hr />}
                      </div>
                    );
                  })}
                </div>
              )}
              <hr
                className="mb-3"
                style={{ padding: 0, margin: 0, marginTop: 30 }}
              />
              {cartItems?.length > 0 ? (
                <>
                  {!loading ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: "black",
                        }}
                      >
                        <h3 style={{ fontWeight: "600" }}>Subtotal :</h3>
                        <div>
                          <h3 style={{ fontWeight: "600" }} className="mr-1">
                            ${billingDetails?.subTotal?.toFixed(2)}
                          </h3>
                        </div>
                      </div>
                      <div
                        id="buttonToggler"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <h3 className="mt-1">
                          Tax & Fees <FaCaretDown size={18} />
                        </h3>
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            color: "black",
                          }}
                        >
                          <h3 className="mr-1">
                            $
                            {billingDetails?.appliedCoupon &&
                            couponDoc?.couponType === "free_delivery"
                              ? location?.enableIhd &&
                                restaurant?.ihdFees === "partner"
                                ? Number(
                                    billingDetails?.tax +
                                      Number(
                                        location?.enableIhd &&
                                          method === "delivery"
                                          ? deliveryFee +
                                              (billingDetails?.tip || 0)
                                          : 0
                                      )
                                  ).toFixed(2)
                                : Number(
                                    billingDetails?.tax +
                                      Number(
                                        location?.enableIhd &&
                                          method === "delivery"
                                          ? deliveryFee
                                          : 0
                                      )
                                  ).toFixed(2)
                              : (
                                  billingDetails?.tax +
                                  Number(
                                    location?.enableIhd && method === "delivery"
                                      ? restaurant.ihdFees === "partner"
                                        ? deliveryFee +
                                          (billingDetails?.tip || 0)
                                        : deliveryFee
                                      : 0
                                  )
                                )?.toFixed(2)}
                          </h3>
                        </div>
                      </div>
                      <UncontrolledCollapse toggler="#linkToggler,#buttonToggler">
                        <div>
                          {billingDetails?.taxDetails?.map((taxName) => {
                            return (
                              <div className="d-flex justify-content-between mb-2">
                                <div>{taxName?.name}</div>
                                <div>${taxName?.amount?.toFixed(2)}</div>
                              </div>
                            );
                          })}

                          {(method === "pickup" ||
                            !location?.enableIhd ||
                            (location.enableIhd &&
                              restaurant.ihdFees === "restaurant")) &&
                            billingDetails?.orderFee > 0 && (
                              <div className="d-flex justify-content-between mb-2">
                                <div>Ordering Fee</div>
                                <div>
                                  ${billingDetails?.orderFee.toFixed(2)}
                                </div>
                              </div>
                            )}

                          {method === "delivery" && (
                            <>
                              {(couponDoc?.couponType !== "free_delivery" ||
                                !billingDetails.appliedCoupon) && (
                                <div className="d-flex justify-content-between mb-2">
                                  <div>Delivery Fee</div>
                                  <div>
                                    $
                                    {location?.enableIhd &&
                                    restaurant?.ihdFees === "partner"
                                      ? (
                                          billingDetails?.accumatedDeliveryFee +
                                          deliveryFee
                                        )?.toFixed(2)
                                      : (
                                          billingDetails?.deliveryFee +
                                          (location?.enableIhd
                                            ? deliveryFee
                                            : 0)
                                        ).toFixed(2)}
                                  </div>
                                </div>
                              )}
                              {billingDetails?.appliedCoupon &&
                                couponDoc?.couponType === "free_delivery" && (
                                  <div className="d-flex justify-content-between mb-2">
                                    <div>Delivery Fee</div>
                                    <div>
                                      $
                                      {location?.enableIhd &&
                                      restaurant?.ihdFees === "partner"
                                        ? (
                                            billingDetails?.accumatedDeliveryFee +
                                            deliveryFee
                                          )?.toFixed(2)
                                        : (
                                            billingDetails?.deliveryFee +
                                            (location?.enableIhd
                                              ? deliveryFee
                                              : 0)
                                          ).toFixed(2)}
                                    </div>
                                  </div>
                                )}
                              {location?.enableIhd &&
                                restaurant?.ihdFees === "partner" &&
                                billingDetails?.tip > 0 && (
                                  <div className="d-flex justify-content-between mb-2">
                                    <div>Delivery Tip</div>
                                    <div>
                                      ${(billingDetails?.tip || 0)?.toFixed(2)}
                                    </div>
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                      </UncontrolledCollapse>
                      <div className="bold mt-2" style={{ fontSize: 15 }}>
                        {billingDetails?.appliedCoupon?._id && (
                          <div className="d-flex justify-content-between mb-2">
                            <h3>Coupon Discount :</h3>
                            <h3>
                              - $
                              {billingDetails?.appliedCoupon?.discount?.toFixed(
                                2
                              )}
                            </h3>
                          </div>
                        )}
                        {billingDetails?.loyaltyDiscount > 0 && (
                          <div className="d-flex justify-content-between mb-2">
                            <h3>Loyalty Discount :</h3>
                            <h3>
                              -${billingDetails?.loyaltyDiscount?.toFixed(2)}
                            </h3>
                          </div>
                        )}
                        {(method === "pickup" ||
                          !location?.enableIhd ||
                          (location.enableIhd &&
                            restaurant.ihdFees === "restaurant")) &&
                          !cateringData?.enquiry && (
                            <div className="d-flex justify-content-between mt-2">
                              <h3>Tip :</h3>
                              <h3>${(billingDetails?.tip || 0)?.toFixed(2)}</h3>
                            </div>
                          )}
                        <hr style={{ padding: 0, margin: 0 }} />
                        <div className="d-flex justify-content-between mt-4 align-items-center">
                          <div>
                            <h3
                              style={{
                                color: "black",
                              }}
                            >
                              Total :
                            </h3>
                          </div>

                          <div style={{ fontWeight: "600" }}>
                            <h3 style={{ color: "#000" }}>
                              $
                              {(
                                billingDetails?.total +
                                Number(
                                  location?.enableIhd && method === "delivery"
                                    ? deliveryFee
                                    : 0
                                )
                              ).toFixed(2)}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Skeleton height="30px" width="100%" />
                      <Skeleton
                        style={{ marginTop: 20 }}
                        height="30px"
                        width="80%"
                      />
                      <Skeleton
                        style={{ marginTop: 20 }}
                        height="30px"
                        width="50%"
                      />
                      <Skeleton
                        style={{ marginTop: 20 }}
                        height="30px"
                        width="20%"
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex align-items-center flex-column">
                  <lottie-player
                    src="https://assets1.lottiefiles.com/packages/lf20_fkmiz05n.json"
                    background="transparent"
                    speed="1"
                    style={{ width: 100 }}
                    autoplay
                  ></lottie-player>
                  <h2 style={{ textTransform: "uppercase" }}>
                    Good Food is always cooking
                  </h2>
                  <span className="text-muted">Your cart is empty</span>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartSection;
