/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import SecureInput from "../../../components/Inputs/SecureInput";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { useRestaurantStore } from "../../../store/restaurant";
import TableWrapper from "../../../widgets/TableWrapper";

export default function AddGateway(props) {
  const [locations, setLocations] = useState([]);
  const [options] = useState([]);
  const [, setFinalLoc] = useState([]);
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const { restaurant: restaurantDoc } = useRestaurantStore();

  const location = useLocation();

  const {
    create: createGateway,
    findOne,
    updateEntity,
    entity: gateway,
  } = useEntity("gateway");

  const { entities: data, find, loading } = useEntity("location");
  useEffect(() => {
    find({
      resturant: restaurant || authRest,
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      gateway: "",
      currency: "USD",
      apiLoginId: "",
      locations: [],
      subMerchantId: "",
      transactionKey: "",
      enableEnhancedSecurity: false,
      tilledAccountId: "",
      tilledPublishableKey: "",
      stripeSecretKey: "",
      stripePublishableKey: "",
      tokenPayPublicKey: "",
      tokenPayPrivateKey: "",
      merchantAccountCode: "",
      bridgepayUsername: "",
      bridgepayPassword: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        let res;
        if (location.state) {
          res = await updateEntity(location.state, {
            ...values,
            subMerchantId: values?.subMerchantId?.trim(),
            apiLoginId: values?.apiLoginId?.trim(),
            transactionKey: values?.transactionKey?.trim(),
            enableEnhancedSecurity: values.enableEnhancedSecurity,
            token:
              restaurantDoc?.restaurant?.partner?.paymentConfig?.jupiterBasic,
            tilledPublishableKey:
              restaurantDoc?.restaurant?.partner?.paymentConfig
                ?.tilledPublishableKey,
            tilledSecretKey:
              restaurantDoc?.restaurant?.partner?.paymentConfig
                ?.tilledSecretKey,
            locations: values?.locations?.map((loc) => loc?.value),
            resturant: authRest || restaurant,
            tilledAccountId: values?.tilledAccountId?.trim(),
            stripeSecretKey: values?.stripeSecretKey,
            stripePublishableKey: values?.stripePublishableKey,
            tokenPayPrivateKey: values?.tokenPayPrivateKey,
            tokenPayPublicKey: values.tokenPayPublicKey,
            merchantAccountCode: values?.merchantAccountCode,
            bridgepayUsername: values?.bridgepayUsername,
            bridgepayPassword: values?.bridgepayPassword,
          });
        } else {
          res = await createGateway({
            ...values,
            subMerchantId: values?.subMerchantId?.trim(),
            apiLoginId: values?.apiLoginId?.trim(),
            transactionKey: values?.transactionKey?.trim(),
            enableEnhancedSecurity: values.enableEnhancedSecurity,
            token:
              restaurantDoc?.restaurant?.partner?.paymentConfig?.jupiterBasic,
            tilledPublishableKey:
              restaurantDoc?.restaurant?.partner?.paymentConfig
                ?.tilledPublishableKey,
            tilledSecretKey:
              restaurantDoc?.restaurant?.partner?.paymentConfig
                ?.tilledSecretKey,
            locations: values?.locations?.map((loc) => loc.value),
            resturant: authRest || restaurant,
            tilledAccountId: values?.tilledAccountId?.trim(),
            stripeSecretKey: values?.stripeSecretKey,
            stripePublishableKey: values?.stripePublishableKey,
            tokenPayPrivateKey: values?.tokenPayPrivateKey,
            tokenPayPublicKey: values.tokenPayPublicKey,
            merchantAccountCode: values?.merchantAccountCode,
            bridgepayUsername: values?.bridgepayUsername,
            bridgepayPassword: values?.bridgepayPassword,
          });
        }
        if (res?.code === "SUCCESS") {
          toast.success(location.state ? "Gateway updated" : "Gateway added");
          props.history.push({
            pathname: "/resturants/settings",
            state: 7,
            search: location.search,
          });
          resetForm();
          setLocations([]);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    findOne(location.state);
  }, []);

  useEffect(() => {
    if (gateway) {
      const locationData = data?.data?.data?.map?.((loc) => {
        if (gateway?.data?.locations?.includes(loc._id)) {
          return { label: loc.address, value: loc._id };
        }
      });

      const obj = {
        name: gateway?.data?.name,
        description: gateway?.data?.description,
        gateway: gateway?.data?.gateway,
        currency: "USD",
        apiLoginId: gateway?.data?.apiLoginId,
        locations: locationData?.filter((loc) => loc),
        subMerchantId: gateway?.data?.subMerchantId,
        transactionKey: gateway?.data?.transactionKey,
        enableEnhancedSecurity: gateway?.data?.enableEnhancedSecurity,
        tilledAccountId: gateway?.data?.tilledAccountId,
        tilledPublishableKey: gateway?.data?.tilledPublishableKey,
        tilledSecretKey: gateway?.data?.tilledSecretKey,
        stripeSecretKey: gateway?.data?.stripeSecretKey,
        stripePublishableKey: gateway?.data?.stripePublishableKey,
        tokenPayPrivateKey: gateway?.data?.tokenPayPrivateKey,
        tokenPayPublicKey: gateway?.data?.tokenPayPublicKey,
        merchantAccountCode: gateway?.data?.merchantAccountCode,
        bridgepayUsername: gateway?.data?.bridgepayUsername,
        bridgepayPassword: gateway?.data?.bridgepayPassword,
      };

      formik.setValues({
        ...obj,
      });
    }
  }, [gateway]);

  const handleChangeLoc = (location) => {
    formik.setFieldValue("locations", location);
  };

  useEffect(() => {
    if (locations) {
      locations?.map((item) => {
        setFinalLoc((prev) => [...prev, item?.value]);
      });
    }
  }, [locations]);

  if (loading) {
    return (
      <div className="m-6">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <>
      <TimelineHeader
        name="Add Gateway"
        parentName="Payment Gateways"
        showBtn={true}
      >
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
          color="primary"
          size="md"
        >
          {formik.isSubmitting ? (
            <Spinner size="sm" color="primary" />
          ) : (
            "Save Changes"
          )}
        </Button>
        <Button
          onClick={() => {
            props.history.push({
              pathname: "/resturants/settings",
              state: { activeTab: 7 },
              search: location.search,
            });
          }}
          className="btn-neutral"
          color="default"
          size="md"
        >
          Back
        </Button>
      </TimelineHeader>
      <TableWrapper customStyles="px-4 py-3">
        <Form autocomplete="off">
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="3"
            >
              Name
            </Label>
            <Col md="9">
              <Input
                onChange={formik.handleChange("name")}
                value={formik.values.name}
                type="text"
              ></Input>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="3"
            >
              Description
            </Label>
            <Col md="9">
              <Input
                onChange={formik.handleChange("description")}
                value={formik.values.description}
                type="text"
              ></Input>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="3"
            >
              Gateway
            </Label>
            <Col md="9">
              <Input
                onChange={formik.handleChange("gateway")}
                value={formik.values.gateway}
                type="select"
              >
                <option value={""}>Select gateway</option>
                <option value="authorize.net">Authorize.net</option>
                <option value="jupiter">Jupiter HQ</option>
                <option value="tilled">Tilled</option>
                <option value="stripe">Stripe</option>
                <option value="bridgepay">BridgePay</option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="3"
            >
              Select Currency
            </Label>
            <Col md="9">
              <Input
                onChange={formik.handleChange("currency")}
                value={formik.values.currency}
                type="select"
              >
                <option value="USD">USD</option>
                <option value="CAD">CAD</option>
              </Input>
            </Col>
          </FormGroup>
          {formik.values.gateway === "jupiter" && (
            <>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="3"
                >
                  Token
                </Label>
                <Col md="9">
                  <Input
                    disabled
                    onChange={formik.handleChange("token")}
                    value={
                      restaurantDoc?.restaurant?.partner?.paymentConfig
                        ?.jupiterBasic
                    }
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="3"
                >
                  Submerchant ID
                </Label>
                <Col md="9">
                  <Input
                    onChange={formik.handleChange("subMerchantId")}
                    value={formik.values.subMerchantId}
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="3"
                >
                  Enchanced Security
                </Label>

                <Col md="1" className="mt-2">
                  <label className="custom-toggle">
                    <input
                      onChange={(e) => {
                        formik.setFieldValue(
                          "enableEnhancedSecurity",
                          e.target.checked
                        );
                      }}
                      checked={formik.values.enableEnhancedSecurity}
                      value={formik.values.enableEnhancedSecurity}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </FormGroup>
            </>
          )}

          {formik.values.gateway === "authorize.net" && (
            <>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="3"
                >
                  API Login ID
                </Label>
                <Col md="9">
                  <Input
                    onChange={formik.handleChange("apiLoginId")}
                    value={formik.values.apiLoginId}
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="3"
                >
                  Transaction Key
                </Label>
                <Col md="9">
                  <SecureInput
                    onChange={formik.handleChange("transactionKey")}
                    value={formik.values.transactionKey}
                    type="text"
                  ></SecureInput>
                </Col>
              </FormGroup>
            </>
          )}

          {formik.values.gateway === "tilled" && (
            <>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="tilledAccountId"
                  md="3"
                >
                  Account ID
                </Label>
                <Col md="9">
                  <Input
                    id="tilledAccountId"
                    onChange={formik.handleChange("tilledAccountId")}
                    value={formik.values.tilledAccountId}
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>
            </>
          )}

          {formik.values.gateway === "stripe" && (
            <>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="stripeSecretKey"
                  md="3"
                >
                  Stripe Secret Key
                </Label>
                <Col md="9">
                  <Input
                    id="stripeSecretKey"
                    onChange={formik.handleChange("stripeSecretKey")}
                    value={formik.values.stripeSecretKey}
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="stripeSecretKey"
                  md="3"
                >
                  Stripe Publishable Key
                </Label>
                <Col md="9">
                  <Input
                    id="stripePublishableKey"
                    onChange={formik.handleChange("stripePublishableKey")}
                    value={formik.values.stripePublishableKey}
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>
            </>
          )}

          {formik.values.gateway === "bridgepay" && (
            <>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="tokenPayPrivateKey"
                  md="3"
                >
                  Username
                </Label>
                <Col md="9">
                  <Input
                    id="bridgepayUsername"
                    onChange={formik.handleChange("bridgepayUsername")}
                    value={formik.values.bridgepayUsername}
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="tokenPayPrivateKey"
                  md="3"
                >
                  Password
                </Label>
                <Col md="9">
                  <Input
                    id="bridgepayPassword"
                    onChange={formik.handleChange("bridgepayPassword")}
                    value={formik.values.bridgepayPassword}
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="tokenPayPrivateKey"
                  md="3"
                >
                  Merchant Account Code
                </Label>
                <Col md="9">
                  <Input
                    id="merchantAccountCode"
                    onChange={formik.handleChange("merchantAccountCode")}
                    value={formik.values.merchantAccountCode}
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="tokenPayPrivateKey"
                  md="3"
                >
                  Token Pay Private Key
                </Label>
                <Col md="9">
                  <Input
                    id="tokenPayPrivateKey"
                    onChange={formik.handleChange("tokenPayPrivateKey")}
                    value={formik.values.tokenPayPrivateKey}
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="tokenPayPublicKey"
                  md="3"
                >
                  Token Pay Public Key
                </Label>
                <Col md="9">
                  <Input
                    id="tokenPayPublicKey"
                    onChange={formik.handleChange("tokenPayPublicKey")}
                    value={formik.values.tokenPayPublicKey}
                    type="text"
                  ></Input>
                </Col>
              </FormGroup>
            </>
          )}

          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="3"
            >
              Location
            </Label>
            {options ? (
              <Col md="9" className="text-left mt-2">
                <Select
                  onChange={handleChangeLoc}
                  isSearchable={false}
                  isMulti
                  value={formik.values.locations}
                  options={data?.data?.data?.map((d) => {
                    return { label: d.address, value: d._id };
                  })}
                />
              </Col>
            ) : (
              <Spinner size="sm" />
            )}
          </FormGroup>
        </Form>
      </TableWrapper>
      <div style={{ height: 100 }} />
    </>
  );
}
