import { useCallback, useEffect, useState } from "react";
import { FaDollarSign } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import useOrdering from "../../../../store/ordering";
import { useTheme } from "../../../../store/theme";

const Tip = ({ setTip, total, disabled, tip, billingLoading }) => {
  const { theme } = useTheme();
  const [activeTab, setActiveTab] = useState(0);
  const { setTip: createTip, tip: tippedAmount } = useOrdering();
  const [tipLocal, setTipLocal] = useState(0);

  useEffect(() => {
    if (activeTab === 4 && tippedAmount.other) {
      createTip({
        amount: tip,
        other: true,
        percentage: false,
      });
    }
  }, [tip, activeTab, total]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const handleChangeTip = (e) => {
    if (parseFloat(e.target.value).toString().length <= 3)
      setTip(parseFloat(e.target.value));
    else toast.error("Please enter tip less than 999");
  };

  const optimizedFn = useCallback(debounce(handleChangeTip), []);

  const handleApplyTip = () => {
    if (activeTab === 4) {
      setTip("");
      createTip({ other: true, amount: 0, percentage: null });
    } else {
      createTip({
        amount: (total * activeTab) / 100,
        activeTab,
        other: false,
      });
      setTip((total * activeTab) / 100);
    }
  };

  useEffect(() => {
    handleApplyTip();
  }, [activeTab, total]);

  function inpNum(e) {
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]*\.?[0-9]*$|[\b]/)) e.preventDefault();
  }

  return (
    <>
      <div
        className="px-4 py-4 single_card_checkout"
        style={{
          background: " #FFFFFF",
          boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
          borderRadius: 6,
        }}
      >
        <h1
          style={{
            fontWeight: "600",
            color: theme.primary,
          }}
        >
          Tip
        </h1>
        <div className="inline_components">
          <div
            className="mt-3"
            style={{ display: "flex", flexWrap: "wrap", flex: 1.5 }}
          >
            {[15, 18, 20].map((percentage) => {
              return (
                <Button
                  size="sm"
                  disabled={disabled}
                  onClick={() => {
                    setActiveTab(percentage);
                  }}
                  style={{
                    fontSize: "14px",
                    backgroundColor:
                      activeTab === percentage ||
                      tippedAmount.percentage === percentage
                        ? theme?.primary
                        : "white",
                    color:
                      activeTab === percentage ||
                      tippedAmount.percentage === percentage
                        ? "white"
                        : disabled
                        ? "grey"
                        : theme.primary,
                    border:
                      activeTab === percentage ||
                      tippedAmount.percentage === percentage
                        ? "none"
                        : `1px solid ${theme.primary}`,
                  }}
                >
                  {percentage}%
                </Button>
              );
            })}
            <Button
              disabled={disabled}
              size="sm"
              onClick={() => {
                setActiveTab(4);
              }}
              style={{
                fontSize: "14px",
                backgroundColor:
                  activeTab === 4 || tippedAmount.other
                    ? theme?.primary
                    : "white",
                color:
                  activeTab === 4 || tippedAmount.other
                    ? "white"
                    : disabled
                    ? "grey"
                    : theme.primary,
                border:
                  activeTab === 4 || tippedAmount.other
                    ? "none"
                    : `1px solid ${theme.primary}`,
              }}
            >
              Other
            </Button>
          </div>
          <div
            className="mt-3 px-2 py-3 d-flex justify-content-between align-items-center"
            style={{ background: "#f1f1f1", flex: 1, width: "100%" }}
          >
            {!disabled && (
              <>
                <input
                  style={{
                    border: "none",
                    background: "transparent",
                    outline: "none",
                    flex: 1,
                  }}
                  step="0.01"
                  type="number"
                  min="0"
                  onKeyDown={(e) => {
                    inpNum(e);
                  }}
                  placeholder="Enter the tip amount"
                  disabled={disabled || activeTab !== 4}
                  onChange={(e) => {
                    setTipLocal(e.target.value);
                    optimizedFn(e);
                  }}
                  value={
                    activeTab === 4
                      ? tipLocal
                      : tippedAmount.amount && tippedAmount.amount.toFixed(2)
                  }
                />
                {/* {(tippedAmount.amount > 0 || tip > 0) && (
                    <span
                      onClick={() => {
                        clearTip();
                        setTip("");
                        setTipLocal("");
                      }}
                    >
                      <FaTimes />
                    </span>
                  )} */}
                <span className="mx-1">
                  <FaDollarSign />
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tip;
