import * as Yup from "yup"

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const validationSchemaACustomerRegister = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .min(2)
    .required("Required"),
  email: Yup.string()
    .email("Please enter valid email")
    .trim()
    .required("Required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(1, "Cannot be negative")
    .max(10, "Cannot be more than 10")
    .required("Required"),
  password: Yup.string().trim().required("Required"),

  smsForCouponAndInfo: Yup.boolean().required("Required"),
  emailForCouponAndInfo: Yup.boolean().required("Required"),
})
