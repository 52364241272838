/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import RestaurantWrapper from "../../../components/Layout/RestaurantWrapper";
import MobileNavMenu from "../../../components/Navbar/MobileNavMenu";
import Notification from "../../../components/Popups/Notification";
import { useEntity } from "../../../hooks/useEntity";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import MenuSidebar from "../menu/components/MenuSidebar";
import "./components/Checkout.css";
import CheckoutMain from "./components/CheckoutMain";
import { isMobile } from "react-device-detect";
import useScreenSize from "../../../hooks/useScreenSize";

const Checkout = (props) => {
  const [cat, setCat] = useState();
  const [catName, setCatName] = useState();
  const [homeModal, setHomeModal] = useState(false);
  const [isMobileSearch, setIsMobileSearch] = useState(false);
  const { theme } = useTheme();

  const {
    location,
    method,
    setGateway,
    gateway,
    cateringData,
    laterDate,
    laterTime,
    timing,
  } = useOrdering();
  const { restaurant } = useOrderingRestaurant();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tip, setTip] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { width } = useScreenSize();

  const { find: findMenu, entities: menu } = useEntity(
    "eat/ordering/ordering-menu"
  );

  const { findOne, entity } = useEntity("eat/ordering/gateway");

  useEffect(() => {
    if (!gateway) {
      setGateway(entity?.data);
    }
  }, [entity]);

  useEffect(() => {
    if (!gateway) {
      findOne(location?._id);
    }
  }, [location]);

  // useEffect(() => {
  //   if (Object.keys(restaurant || {}).length === 0) {
  //     const url = window.location.href?.replace(/\/checkout$/, "");
  //     let hostUrl = new URL(url);
  //     props.history.push(hostUrl.pathname);
  //     return;
  //   }
  //   if (
  //     (!location || !method || !timing) &&
  //     Object.keys(restaurant).length > 0
  //   ) {
  //     let url = new URL(restaurant?.url);
  //     props.history.push(url.pathname);
  //   }
  // }, []);

  // useEffect(() => {
  //   const fromFlow = props?.location?.state?.fromFlow || false;

  //   if (!fromFlow) {
  //     window.location.href = window.location.href.replace("/checkout", "");
  //   }
  // }, [props.location.state]);

  useEffect(() => {
    let dateString;
    if (timing === "later") {
      dateString = new Date(laterDate);
      const splittedTime = laterTime.split(":");
      dateString.setHours(splittedTime[0], splittedTime[1]);
    }
    findMenu({
      location: location?._id,
      method: method,
      isMobile: width < 600 ? "true" : "false",
      catering: cateringData?.catering ? "true" : "false",
      restaurant: restaurant?.userId?._id,
      schedule: timing === "later" ? dateString : undefined,
      filterDSP: location?.connectedToStream ? "true" : "false",
    });
  }, [location, method]);

  useEffect(() => {
    setHomeModal(true);
  }, [restaurant]);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      setIsMobileSearch(event.detail.mobileSearch);
    };

    document.addEventListener("mobileSeachEnabled", handleCustomEvent);

    return () => {
      document.removeEventListener("mobileSeachEnabled", handleCustomEvent);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <RestaurantWrapper>
      <div style={{ maxWidth: "1440px", margin: "auto" }}>
        <div
          className="mobile_top_nav"
          style={{
            marginBottom:
              isMobileSearch && screenWidth < 600
                ? 140
                : !isMobileSearch && screenWidth < 600
                ? 60
                : screenWidth <= 1200
                ? 60
                : 0,
          }}
        >
          <MobileNavMenu
            searchString={(val) => {}}
            history={props.history}
            setState={(val) => setSidebarOpen(val)}
            tip={tip}
          />
        </div>

        <div className="main_menu_screen_wrapper">
          <div className="hide_tab_checkout">
            <MenuSidebar
              data={[]}
              classname="hide_top_section"
              onClick={(val) => setCat(val)}
              catName={(val) => setCatName(val)}
              catSelected={catName}
              searchString={(val) => {}}
              history={props.history}
            />
          </div>
          <div style={{ flex: 4 }}>
            <CheckoutMain
              sidebarOpen={sidebarOpen}
              history={props.history}
              setTipAmount={(val) => setTip(val)}
              cat={cat}
              allItems={menu?.data?.data?.flatMap((cat) => cat?.items)}
              menuId={menu?.data?._id}
            />
          </div>
        </div>
        {restaurant?.pop_up_alerts?.checkout_popup?.checkout_popup && (
          <Notification
            popupTitle={restaurant?.pop_up_alerts?.checkout_popup?.title}
            popupHeading={restaurant?.pop_up_alerts?.checkout_popup?.title}
            popupDescription={
              restaurant?.pop_up_alerts?.checkout_popup?.message
            }
            toggle={() => setHomeModal(!homeModal)}
            open={homeModal}
            origin="home"
            theme={theme}
          />
        )}
      </div>
    </RestaurantWrapper>
  );
};

export default Checkout;
