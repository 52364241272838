import { Button, Card, CardBody } from "reactstrap";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import { useEffect } from "react";

const TableScreen = (props) => {
  const history = props.history;
  const { theme } = useTheme();
  const { setTableNo, tableNo, setMethod, setCateringData } = useOrdering();
  const { restaurant } = useOrderingRestaurant();
  let pathname = window.location.pathname.replace(/\/+$/, "");
  pathname = pathname.split("/");

  useEffect(() => {
    setTableNo("");
    setCateringData({});
  }, []);

  return (
    <OrderingLayout
      title={restaurant?.ordering?.dineInTitle || "Dine in"}
      isCenter={true}
      history={history}
      classname="main_ordering_card_wide"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      ></div>
      <div>
        <div
          className="px-4 text-center"
          style={{ fontSize: 16, color: "#66666" }}
        >
          {restaurant?.ordering?.dineInText ||
            "Please enter your table number. Which should be somewhere on your table, so we can bring you the right food."}
        </div>
        <CardBody>
          <div className="dine_in_ordering_input">
            <div style={{ flex: 1, backgroundColor: "#ededed" }}>
              <input
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "#ededed",
                  width: "100%",
                }}
                className="py-3 px-2 rounded"
                type="text"
                placeholder="Enter table number"
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[0-9]*$/;
                  if (regex.test(value)) {
                    if (Number(value) > 10000) return;
                    setTableNo(value);
                  }
                }}
                value={tableNo}
              />
            </div>
            <Button
              className="dine_in_button"
              disabled={tableNo === ""}
              style={{ backgroundColor: theme?.primary, color: "white" }}
              onClick={() => {
                history.push("/" + pathname[1] + "/" + pathname[2] + `/menu`, {
                  fromFlow: true,
                });
              }}
            >
              Start Order
            </Button>
          </div>
        </CardBody>
      </div>
    </OrderingLayout>
  );
};

export default TableScreen;
