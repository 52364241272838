/* eslint-disable array-callback-return */
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
// reactstrap components
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import * as Yup from "yup";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import TableWrapper from "../../../widgets/TableWrapper";
import AddChildModifier from "./components/AddChildModifierModal";
import { getAlphabeticalOrderArray } from "../../../utils/getAlphbeticalSortedArray";

const AddModifiers = (props) => {
  const authContext = useContext(AuthContext);

  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;

  const { restaurant } = useAdminStore();

  const { create: createModifierGroup } = useEntity("modifier-group");

  const { create: createModifier, updateEntity: updateModifier } =
    useEntity("modifier");

  const { create: bulkUpdate } = useEntity("modifier/sort-order");

  const { create: cloneModifier } = useEntity("modifier/clone");

  const { create: deleteModifier } = useEntity("modifier/delete");

  const { updateEntity } = useEntity("modifier-group");

  const { findOne, entity } = useEntity("modifier-group");

  const [childModal, setChildModal] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);

  const [hasChildModifier, setHasChildModifier] = useState(false);

  const [seed, setSeed] = useState(0);

  const [editIndex, setEditIndex] = useState(null);

  const [clonedModifs, setClonedModifs] = useState([]);

  const formik = useFormik({
    initialValues: {
      restaurant: authRest || restaurant,
      name: "",
      type: "",
      printedText: "",
      modifiers: [],
      isInit: false,
      advancedPizzaModifiers: false,
      modifierLock: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Modifier name is required"),
      type: Yup.string().trim().required("Type is required"),
    }),

    onSubmit: async (values) => {
      try {
        if (values?.type !== "checkbox" && values?.advancedPizzaModifiers) {
          values.advancedPizzaModifiers = false;
        }

        const bulkOpPayload = getAlphabeticalOrderArray(
          values?.modifiers,
          "name"
        )?.map((mod, index) => {
          return { sort: index, _id: mod?._id, restaurant: mod?.restaurant };
        });

        if (bulkOpPayload.length > 0 && !entity) {
          await bulkUpdate({ sortOrder: bulkOpPayload });
        }

        console.log(values, "ASDKASLKDAS");

        if (!entity || props.location.state.clone) {
          await createModifierGroup({
            ...values,
            min: "",
            max: "",
            subProducts: values?.modifiers?.map((m) => m?._id),
            source: "local",
            type:
              hasChildModifier &&
              (values.type === "select" || values.type === "radio")
                ? "checkbox"
                : values.type,
          });
          toast.success("Modifier created successfully");
        } else {
          await updateEntity(entity?.data?._id, {
            ...values,
            subProducts: values?.modifiers?.map((m) => m?._id),
            type:
              hasChildModifier &&
              (values.type === "select" || values.type === "radio")
                ? "checkbox"
                : values.type,
          });
          toast.success("Modifier updated successfully");
        }
        props.history.goBack();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    setValues,
    setFieldValue,
  } = formik;

  const modifierFormik = useFormik({
    initialValues: {
      name: "",
      printedText: "",
      defaultSelected: false,
      price: "",
      halfPrice: "",
      extraPrice: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const modif = formik?.values?.modifiers || [];

      const result = await createModifier({
        ...values,
        source: "local",
        restaurant: authRest || restaurant,
      });

      modif.push({
        ...result?.data,
      });
      setFieldValue("modifiers", modif);
      resetForm();
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Modifier name is required"),
      printedText: Yup.string().trim(),
    }),
  });

  useEffect(() => {
    if (props.location.state && !props.location.state.clone) {
      findOne(props.location.state);
    }
  }, [props.location.state]);

  useEffect(() => {
    values.modifiers?.map((prod) => {
      if (prod?.subModifiers?.length > 0) {
        setHasChildModifier(true);
      }
    });
  }, [seed, values]);

  useEffect(() => {
    if (entity && !values?.isInit) {
      entity?.data?.subProducts?.map((subProd) => {
        if (subProd?.subModifiers?.length > 0) {
          setHasChildModifier(true);
        }
      });

      setValues({
        restaurant: authRest || restaurant,
        name: entity?.data?.name,
        printedText: entity?.data?.printedText,
        sortOrder: entity?.data?.sortOrder,
        type: entity?.data?.type,
        modifiers: entity?.data?.subProducts,
        advancedPizzaModifiers: entity?.data?.advancedPizzaModifiers,
        isInit: true,
        modifierLock: entity?.data?.modifierLock,
      });
    }
  }, [entity]);

  useEffect(() => {
    if (props?.location?.state?.data) {
      setValues({
        restaurant: props.location.state.data.restaurant,
        name: "",
        printedText: props.location.state.data.printedText,
        sortOrder: props.location.state.data.sortOrder,
        type: props.location.state.data.type,
        modifiers: clonedModifs,
        advancedPizzaModifiers:
          props.location.state.data?.advancedPizzaModifiers,
        isInit: true,
      });
    }
  }, [clonedModifs]);

  useEffect(() => {
    if (props?.location?.state?.data) {
      cloneModifier({ id: props?.location?.state?.data?._id })
        .then((res) => {
          console.log("CLONED DATA", res);
          setClonedModifs(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [props?.location?.state?.data]);

  const handleChildModifierComplete = async (data) => {
    if (!data.name) {
      setChildModal(false);
      return;
    }
    values.modifiers[activeIndex]?.subModifiers?.push(data);
    values.modifiers.map((modif) => {
      if (modif?.subModifiers?.length > 0) {
        updateModifier(modif._id, { ...modif, parentName: values?.name }).then(
          (updated) => console.log(updated)
        );
      }
    });
    setValues(values);
    setChildModal(false);
    setSeed(seed + 1);
  };

  const handleRemoveModifier = async (index) => {
    const modif = values?.modifiers;
    await deleteModifier({
      id: modif[index]?._id,
      parentId: entity?.data?._id,
    });
    modif.splice(index, 1);
    setFieldValue("modifiers", modif);
  };

  const handleUpdateModifier = async (index) => {
    const modif = values?.modifiers;
    await updateModifier(modif[index]?._id, {
      ...modif[index],
      parentModifier: entity?.data?._id,
    });
  };

  return (
    <>
      <AddChildModifier
        isVisible={childModal}
        toggle={handleChildModifierComplete}
        onClose={() => {
          setChildModal(false);
        }}
      />
      <div style={{ minHeight: "100vh" }}>
        <TimelineHeader
          path="/resturants/modifiers"
          name={!props.location.state ? "Add Modifiers" : "Edit Modifiers"}
          parentName="Modifiers"
          showBtn={true}
        >
          <>
            <Button
              size="md"
              className="btn-icon btn-2"
              color="primary"
              type="submit"
              onClick={handleSubmit}
            >
              {isSubmitting ? <Spinner size="sm" /> : "Save Changes"}
            </Button>
          </>

          <Button
            className="btn-neutral text-black"
            color="default"
            size="md"
            onClick={() => {
              props.history.goBack();
            }}
          >
            Back
          </Button>
        </TimelineHeader>
        <TableWrapper customStyles="py-3 px-4">
          <Form className="mt-2 text-left">
            <FormGroup>
              <Row className="text-left">
                <Col md="3">
                  <label
                    style={{
                      fontSize: 15,
                      fontWeight: "700",
                    }}
                    htmlFor="optionName"
                  >
                    Modifier Group Name
                  </label>
                </Col>
                <Col md="9">
                  <Input
                    id="name"
                    multiple=""
                    type="text"
                    onChange={handleChange("name")}
                    value={values?.name}
                  ></Input>
                  {formik.errors.name && formik.touched.name && (
                    <small className={`${"text-red"}`}>
                      {formik.errors.name}
                    </small>
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="3">
                  <label
                    style={{ fontSize: 15, fontWeight: "700" }}
                    htmlFor="optionName"
                  >
                    Custom Printed Text
                  </label>
                </Col>
                <Col md="9">
                  <Input
                    id="printedText"
                    multiple=""
                    type="text"
                    onChange={handleChange("printedText")}
                    value={values?.printedText}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="3">
                  <label
                    style={{ fontSize: 15, fontWeight: "700" }}
                    htmlFor="optionName"
                  >
                    Type
                  </label>
                </Col>
                <Col md="9">
                  <Input
                    id="price"
                    multiple=""
                    type="select"
                    onChange={handleChange("type")}
                    value={hasChildModifier ? "checkbox" : values?.type}
                  >
                    <option value="">Select Option Type</option>
                    <option value={"radio"}>Radio</option>
                    <option value={"checkbox"}>Checkbox</option>
                    <option value={"select"}>Select</option>
                  </Input>
                  {formik.errors.type && formik.touched.type && (
                    <small className={`${"text-red"}`}>
                      {formik.errors.type}
                    </small>
                  )}
                </Col>
              </Row>
            </FormGroup>

            {formik.values.type === "checkbox" && (
              <>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col md="3" className="text-left">
                      <label
                        style={{
                          fontSize: 15,
                          fontWeight: "600",
                        }}
                      >
                        Advanced Pizza Modifiers
                      </label>
                    </Col>
                    <Col md="9">
                      <label className="custom-toggle">
                        <input
                          onChange={handleChange("advancedPizzaModifiers")}
                          checked={values.advancedPizzaModifiers}
                          type="checkbox"
                          value={values?.advancedPizzaModifiers}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </>
            )}

            <>
              <FormGroup>
                <Row className="align-items-center">
                  <Col md="3" className="text-left">
                    <label
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                      }}
                    >
                      Lock Modifier
                    </label>
                  </Col>
                  <Col md="9">
                    <label className="custom-toggle">
                      <input
                        onChange={handleChange("modifierLock")}
                        checked={values.modifierLock}
                        type="checkbox"
                        value={values?.modifierLock}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </Row>
              </FormGroup>
            </>

            <Table bordered responsive>
              <thead className="thead-light text-left">
                <tr>
                  <th>Modifier Name</th>
                  <th>Custom Printed Text</th>
                  {values?.modifierLock && (
                    <>
                      <th>Price</th>
                      {values?.advancedPizzaModifiers && (
                        <>
                          <th>Half Price</th>
                          <th>Extra Price</th>
                        </>
                      )}
                    </>
                  )}
                  <th>Action</th>
                </tr>
                <tr>
                  <td>
                    <Input
                      type="text"
                      size={"md"}
                      placeholder="Name"
                      style={{ height: 38 }}
                      onChange={modifierFormik.handleChange("name")}
                      value={modifierFormik.values.name}
                      className={`form-control ${
                        modifierFormik.errors.name &&
                        modifierFormik.touched.name
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {modifierFormik.errors.name &&
                      modifierFormik.touched.name && (
                        <span className="text-danger mt-3">
                          {modifierFormik.errors.name}
                        </span>
                      )}
                  </td>
                  <td>
                    <Input
                      type="text"
                      size={"md"}
                      placeholder="Printed Text"
                      style={{ height: 38 }}
                      onChange={modifierFormik.handleChange("printedText")}
                      value={modifierFormik.values.printedText}
                      className={`form-control ${
                        modifierFormik.errors.printedText &&
                        modifierFormik.touched.printedText
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {modifierFormik.errors.printedText &&
                      modifierFormik.touched.printedText && (
                        <span className="text-danger mt-3">
                          {modifierFormik.errors.printedText}
                        </span>
                      )}
                  </td>
                  {values?.modifierLock && (
                    <>
                      <td>
                        <Input
                          type="number"
                          size={"md"}
                          placeholder="Price"
                          style={{ height: 38 }}
                          onChange={modifierFormik.handleChange("price")}
                          value={modifierFormik.values.price}
                          className={`form-control ${
                            modifierFormik.errors.price &&
                            modifierFormik.touched.price
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {modifierFormik.errors.price &&
                          modifierFormik.touched.price && (
                            <span className="text-danger mt-3">
                              {modifierFormik.errors.price}
                            </span>
                          )}
                      </td>
                      <>
                        {values?.advancedPizzaModifiers && (
                          <>
                            <td>
                              <Input
                                type="number"
                                size={"md"}
                                placeholder="Half Price"
                                style={{ height: 38 }}
                                onChange={modifierFormik.handleChange(
                                  "halfPrice"
                                )}
                                value={modifierFormik.values.halfPrice}
                                className={`form-control ${
                                  modifierFormik.errors.halfPrice &&
                                  modifierFormik.touched.halfPrice
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {modifierFormik.errors.halfPrice &&
                                modifierFormik.touched.halfPrice && (
                                  <span className="text-danger mt-3">
                                    {modifierFormik.errors.halfPrice}
                                  </span>
                                )}
                            </td>
                            <td>
                              <Input
                                type="number"
                                size={"md"}
                                placeholder="Extra Price"
                                style={{ height: 38 }}
                                onChange={modifierFormik.handleChange(
                                  "extraPrice"
                                )}
                                value={modifierFormik.values.extraPrice}
                                className={`form-control ${
                                  modifierFormik.errors.extraPrice &&
                                  modifierFormik.touched.extraPrice
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {modifierFormik.errors.extraPrice &&
                                modifierFormik.touched.extraPrice && (
                                  <span className="text-danger mt-3">
                                    {modifierFormik.errors.extraPrice}
                                  </span>
                                )}
                            </td>
                          </>
                        )}
                      </>
                    </>
                  )}
                  <td className="d-flex justify-center">
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      onClick={modifierFormik.handleSubmit}
                    >
                      <i className="fa fa-plus" />
                    </Button>
                  </td>
                </tr>
                {formik.errors.modifiers && (
                  <small className={`${"text-red"}`}>
                    {formik.errors.modifiers}
                  </small>
                )}
                {getAlphabeticalOrderArray(values?.modifiers, "name")?.map(
                  (modif, index) => {
                    return (
                      <tr>
                        <td>
                          <Row>
                            <Input
                              type="text"
                              disabled={editIndex !== index}
                              size={"md"}
                              style={{ height: 38 }}
                              value={modif.name}
                              onChange={(e) => {
                                let modifier = values.modifiers;
                                modifier[editIndex].name = e.target.value;
                                setFieldValue("modifiers", [...modifier]);
                              }}
                            />
                          </Row>
                          {modif?.subModifiers?.length > 0 && (
                            <Row className="my-2">
                              <span
                                style={{ fontSize: 10, fontWeight: "bold" }}
                              >
                                Sub Modifiers
                              </span>
                            </Row>
                          )}
                          <Row>
                            {modif?.subModifiers?.map((subModif, i) => (
                              <Button
                                className="my-1"
                                size="sm"
                                color="secondary"
                                onClick={() => {
                                  values.modifiers[index]?.subModifiers?.splice(
                                    i,
                                    1
                                  );
                                  values.modifiers.map((modif) => {
                                    updateModifier(modif._id, modif).then(
                                      (updated) => console.log(updated)
                                    );
                                  });
                                  if (
                                    values.modifiers[index]?.subModifiers
                                      .length === 0
                                  ) {
                                    setHasChildModifier(false);
                                  }
                                  setSeed(seed + 1);
                                  setValues(values);
                                }}
                              >
                                <span>{subModif.name}</span>
                                <Badge color="danger">
                                  <span className="fa fa-close"></span>
                                </Badge>
                              </Button>
                            ))}
                          </Row>
                        </td>
                        <td>
                          <Input
                            type="text"
                            size={"md"}
                            placeholder="Custom Printed Text"
                            disabled={editIndex !== index}
                            style={{ height: 38 }}
                            value={modif.printedText}
                            onChange={(e) => {
                              let modifier = values.modifiers;
                              modifier[editIndex].printedText = e.target.value;
                              setFieldValue("modifiers", [...modifier]);
                            }}
                          />
                        </td>
                        {values?.modifierLock && (
                          <>
                            <td>
                              <Input
                                type="number"
                                size={"md"}
                                placeholder="Price"
                                disabled={editIndex !== index}
                                style={{ height: 38 }}
                                value={modif.price}
                                onChange={(e) => {
                                  let modifier = values.modifiers;
                                  modifier[editIndex].price = e.target.value;
                                  setFieldValue("modifiers", [...modifier]);
                                }}
                              />
                            </td>

                            {values.advancedPizzaModifiers && (
                              <>
                                <td>
                                  <Input
                                    type="number"
                                    size={"md"}
                                    placeholder="Half Price"
                                    disabled={editIndex !== index}
                                    style={{ height: 38 }}
                                    value={modif.halfPrice}
                                    onChange={(e) => {
                                      let modifier = values.modifiers;
                                      modifier[editIndex].halfPrice =
                                        e.target.value;
                                      setFieldValue("modifiers", [...modifier]);
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    type="number"
                                    size={"md"}
                                    placeholder="Extra Price"
                                    disabled={editIndex !== index}
                                    style={{ height: 38 }}
                                    value={modif.extraPrice}
                                    onChange={(e) => {
                                      let modifier = values.modifiers;
                                      modifier[editIndex].extraPrice =
                                        e.target.value;
                                      setFieldValue("modifiers", [...modifier]);
                                    }}
                                  />
                                </td>
                              </>
                            )}
                          </>
                        )}

                        <td className="d-flex justify-center">
                          {!values.advancedPizzaModifiers && (
                            <Button
                              className="btn btn-primary"
                              color="primary"
                              onClick={() => {
                                setActiveIndex(index);
                                setChildModal(true);
                              }}
                            >
                              Add Child
                            </Button>
                          )}
                          <Button
                            className="btn btn-primary"
                            color="secondary"
                            onClick={async () => {
                              if (index === editIndex) {
                                await handleUpdateModifier(index);
                                setEditIndex(null);
                              } else {
                                setEditIndex(index);
                              }
                            }}
                          >
                            {editIndex === index ? (
                              <i className="fa fa-save" />
                            ) : (
                              <i className="fa fa-pencil" />
                            )}
                          </Button>
                          <Button
                            className="btn btn-primary"
                            color="danger"
                            onClick={async () => {
                              await handleRemoveModifier(index);
                            }}
                          >
                            <i className="fa fa-trash" />
                          </Button>
                        </td>
                      </tr>
                    );
                  }
                )}
              </thead>
            </Table>
          </Form>
        </TableWrapper>
      </div>
    </>
  );
};

export default AddModifiers;
