import classnames from "classnames";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
/* eslint-disable react-hooks/exhaustive-deps */
// reactstrap components
import { useLocation } from "react-router-dom";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useUploadImage } from "../../../hooks/useUploadImage";
import { useAdminStore } from "../../../store/admin";
import logger from "../../../utils/logger";
import { validationSchemaAddItem } from "../../../utils/schemas/AddItem";
import TableWrapper from "../../../widgets/TableWrapper";
import { initialAvailability } from "./AddCategory";
import AddEditModifiersTab from "./AddEditModifiersTab";
import AvailablityTabItem from "./tabs/AvailablityTabItem";
import GeneralTabItem from "./tabs/GeneralTabItem";
import { useRestaurantStore } from "../../../store/restaurant";

const AddItem = (props) => {
  const locationID = useLocation();
  const { upload } = useUploadImage();
  const [tabs, setTabs] = useState(1);
  const [image, setImage] = useState();
  const authContext = useContext(AuthContext);

  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant: restaurantID } = useAdminStore();
  const { create: AddItem } = useEntity("item");

  const {
    find: findOptions,
    loading: isLoading,
    entities: options,
  } = useEntity("modifier-group");

  const { find: findTag, entities: tags } = useEntity("tag");

  const {
    find: findCat,
    loading: isLoadingCat,
    entities: category,
  } = useEntity("category");
  const {
    find: findLoc,
    loading: isLoadingLoc,
    entities: location,
  } = useEntity("location");

  const { updateEntity } = useEntity("item");
  const { findOne, entity } = useEntity("item");
  const { find, entities } = useEntity("tax-rate");
  const { find: findItems, entities: items } = useEntity("item");
  const { restaurant: restaurantDoc } = useRestaurantStore();

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    setValues,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      restaurant: authRest || restaurantID,
      productName: "",
      override: false,
      customName: "",
      description: "",
      tag: [],
      status: true,
      price: 0,
      categories: [],
      preparationTime: 0,
      assignedToLocation: [{ label: "All", value: "all" }],
      isAvailableDaily: true,
      availability: [],
      printerId: null,
      options: [],
      image: "",
      taxRate: "",
      plu: "",
      modifiers: {},
      isAlchohol: false,
      isCombo: false,
      subProducts: [],
      isSuggested: false,
      method: "all",
      comboPrice: 0,
      glutenFree: false,
      vegetarian: false,
      vegan: false,
      spicyLevel: "",
      parents: [],
      allLocationsSelected: false,
      catering: false,
      moqPerOrder: 0,
      feed: 0,
    },
    validationSchema: validationSchemaAddItem,
    onSubmit: async (values, { resetForm }) => {
      if (values.isCombo && values.subProducts?.length === 0) {
        toast.error("Please select combo products to continue");
        return;
      }
      let imagePath = "";

      if (image) {
        imagePath = await upload({
          file: image,
          owner: "restaurant",
        });
      }

      const parentArray = Object.values(values?.modifiers || {})
        ?.map((mod) => {
          if (mod?.enableParentModifier) {
            return mod?.parentModifierId;
          }
        })
        .filter((op) => op !== undefined);

      values.parents = Array.from(new Set(parentArray));

      const allSubProductsHaveLength = checkSubProductsLength(values.modifiers);

      const hasAtleastOneRequiredModifiers = hasRequiredModifiers(values);

      const locationValues = values?.assignedToLocation?.map(
        (val) => val?.value
      );

      if (locationValues.includes("all")) {
        values.allLocationsSelected = true;
      } else {
        values.allLocationsSelected = false;
      }

      if (!allSubProductsHaveLength) {
        toast.error("Submodifiers are required");
        setTabs(2);
      } else if (!hasAtleastOneRequiredModifiers) {
        toast.error("Atleast one modifier should be required");
        setTabs(2);
      } else {
        const data = {
          restaurant: authRest || restaurantID,
          name: values.productName,
          customName: values.customName,
          printerId: values.printerId,
          description: values.description,
          tag: values?.tag?.map((it) => {
            return it?.value;
          }),
          status: values.status,
          price: values?.price || 0,
          categories: values?.categories?.map((it) => {
            return it?.value;
          }),
          preparationTime: values.preparationTime,
          sortOrder: values.sortOrder,
          assignedToLocations: values?.assignedToLocation?.map((it) => {
            return it?.value;
          }),
          modifiers: values?.modifiers,
          isAvailableDaily: values.isAvailableDaily,
          availability: values.isAvailableDaily
            ? initialAvailability
            : values.availability,
          imageUrl: imagePath ? imagePath : values?.image,
          loyaltyPoint: Math.ceil(values?.price),
          taxRate: values?.taxRate || entities?.data?.[0]?._id,
          plu: values?.plu,
          isAlchohol: values?.isAlchohol,
          isCombo: values?.isCombo,
          subProducts: values?.subProducts?.map((it) => {
            return it?.value;
          }),
          isSuggested: values?.isSuggested,
          method: values?.method,
          glutenFree: values?.glutenFree,
          vegetarian: values?.vegetarian,
          spicyLevel: values?.spicyLevel,
          parents: values?.parents,
          vegan: values?.vegan,
          allLocationsSelected: values.allLocationsSelected,
          catering: values?.catering,
          moqPerOrder: values?.moqPerOrder,
          feed: values?.feed,
          override: values?.override,
        };

        try {
          if (!locationID?.state || locationID?.state?.clone) {
            await AddItem({ ...data, source: "local" });
            toast("Item Created Successfully");
            props.history.goBack();
          } else {
            await updateEntity(locationID?.state, { ...data });
            toast("Item Updated Successfully");
            props.history.goBack();
          }
          resetForm();
        } catch (error) {
          logger.push({ error, info: "AddItem" });
          toast.error(error.message);
        }
      }
    },
  });

  const checkSubProductsLength = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const subProducts = obj[key].subProducts;
        if (!Array.isArray(subProducts) || subProducts.length === 0) {
          return false;
        }
      }
    }
    return true;
  };

  const hasRequiredModifiers = (item) => {
    if (item.price <= 0) {
      for (const modifier of Object.values(item.modifiers)) {
        if (modifier.isRequired) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    findOptions({ restaurant: authRest || restaurantID });
    findCat({
      restaurant: authRest || restaurantID,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });

    find({
      resturant: authRest || restaurantID,
    });
    findItems({
      restaurant: authRest || restaurantID,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
    findTag({ restaurant: authRest || restaurantID });
    if (!locationID?.state?.clone && locationID?.state) {
      findOne(locationID?.state);
    }
  }, [locationID.state]);

  useEffect(() => {
    findLoc({
      resturant: authRest || restaurantID,
      categories: values.categories,
    });
  }, [values.categories]);

  useEffect(() => {
    if (entity) {
      const modifiedData = Object?.entries(
        entity?.data?.modifiers || {}
      )?.reduce((acc, [key, value]) => {
        return { ...acc, [key]: { ...value, modifierId: key } };
      }, {});

      setValues({
        restaurant: authRest || restaurantID,
        productName: entity?.data?.name,
        description: entity?.data?.description,
        status: entity?.data?.status,
        price: entity?.data?.price || 0,
        calories: entity?.data?.calories,
        override: entity?.data?.override,
        preparationTime: entity?.data?.preparationTime,
        printerId: entity?.data?.printerId,
        customName: entity?.data?.customName,
        assignedToLocation: entity?.data?.assignedToLocations?.map(
          (location) => {
            return {
              label: location?.name,
              value: location?._id,
            };
          }
        ),
        tag: entity?.data?.tag?.map((location) => {
          return {
            label: location?.name,
            value: location?._id,
          };
        }),
        modifiers: modifiedData || {},
        availability: entity?.data?.availability,
        isAvailableDaily: entity?.data?.isAvailableDaily,
        isAlchohol: entity?.data?.isAlchohol,
        isSuggested: entity?.data?.isSuggested,
        image: entity?.data?.imageUrl,
        plu: entity?.data?.plu,
        loyaltyPoint: entity?.data?.price,
        taxRate: entity?.data?.taxRate,
        isCombo: entity?.data?.isCombo,
        glutenFree: entity?.data?.glutenFree,
        vegetarian: entity?.data?.vegetarian,
        spicyLevel: entity?.data?.spicyLevel,
        vegan: entity?.data?.vegan,
        method: entity?.data?.method,
        categories: entity?.data?.categories?.map((modifier) => {
          return {
            label: modifier?.categoryName,
            value: modifier?._id,
          };
        }),
        subProducts: entity?.data?.subProducts?.map((modifier) => {
          return {
            label: modifier?.name,
            value: modifier?._id,
            price: modifier?.price,
          };
        }),
        allLocationsSelected: entity?.data?.allLocationsSelected,
        catering: entity?.data?.catering,
        moqPerOrder: entity?.data?.moqPerOrder || 0,
        feed: entity?.data?.feed || 0,
      });
      if (entity?.data?.allLocationsSelected) {
        setFieldValue(
          "assignedToLocation",
          [{ name: "All", _id: "all" }]?.map((location) => {
            return {
              label: location?.name,
              value: location?._id,
            };
          })
        );
      }
    }
  }, [entity]);

  useEffect(() => {
    if (props?.location?.state?.data) {
      const modifiedData = Object?.entries(
        props.location.state.data?.modifiers || {}
      )?.reduce((acc, [key, value]) => {
        return { ...acc, [key]: { ...value, modifierId: key } };
      }, {});
      setValues({
        productName: "",
        customName: "",
        restaurant: authRest || restaurantID,
        description: props.location.state.data?.description,
        tag: props?.location?.state?.data?.tag?.map((t) => {
          return {
            label: t?.name,
            value: t?._id,
          };
        }),

        status: props.location.state.data?.status,
        price: props.location.state.data?.price,
        calories: props.location.state.data?.calories,
        preparationTime: props.location.state.data?.preparationTime,
        assignedToLocation: props.location.state.data?.assignedToLocations?.map(
          (location) => {
            return {
              label: location?.name,
              value: location?._id,
            };
          }
        ),
        modifiers: modifiedData,
        availability: props.location.state.data?.availability,
        isAvailableDaily: props.location.state.data?.isAvailableDaily,
        isAlchohol: props.location.state.data?.isAlchohol,
        isSuggested: props.location.state.data?.isSuggested,
        image: props.location.state.data?.imageUrl,
        plu: props.location.state.data?.plu,
        loyaltyPoint: props.location.state.data?.price,
        taxRate: props.location.state.data?.taxRate?._id,
        isCombo: props.location.state.data?.isCombo,
        method: props.location.state.data?.method,
        categories: props.location.state.data?.categories?.map((modifier) => {
          return {
            label: modifier?.categoryName,
            value: modifier?._id,
          };
        }),
        subProducts: props.location.state.data?.subProducts?.map((modifier) => {
          return {
            label: modifier?.name,
            value: modifier?._id,
            price: modifier?.price,
          };
        }),
        glutenFree: props.location.state.data?.glutenFree,
        vegetarian: props.location.state.data?.vegetarian,
        spicyLevel: props.location.state.data?.spicyLevel,
        vegan: props.location.state.data?.vegan,
        allLocationsSelected: props.location.state.data?.allLocationsSelected,
        catering: props.location.state.data?.catering,
        moqPerOrder: props.location.state.data?.moqPerOrder,
        feed: props?.location?.state?.data?.feed,
      });
    }
  }, [props?.location?.state?.data]);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  console.log(values.modifiers, "modifiers");

  return (
    <div>
      <TimelineHeader
        name={props.location?.state ? "Edit Item" : "Add Item"}
        parentName="Item"
        path="/resturants/items"
        showBtn={true}
      >
        <>
          <Button
            size="md"
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            {isSubmitting ? <Spinner size="sm" color="white" /> : "Save"}
          </Button>
        </>

        <Button
          color="neutral"
          size="md"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Back
        </Button>
      </TimelineHeader>
      <TableWrapper customStyles="px-4 py-3">
        {!isLoading && !isLoadingCat && !isLoadingLoc ? (
          <>
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={tabs === 1}
                  className={classnames("mb-sm-3 mb-md-0 mt-2", {
                    active: tabs === 1,
                  })}
                  onClick={(e) => toggleNavs(e, 1)}
                  href="#pablo"
                  role="tab"
                >
                  <i className="fa fa-book mr-2" />
                  General
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabs === 2}
                  className={classnames("mb-sm-3 mb-md-0 mt-2", {
                    active: tabs === 2,
                  })}
                  onClick={(e) => toggleNavs(e, 2)}
                  href="#pablo"
                  role="tab"
                >
                  <i className="fa fa-print mr-2" />
                  Modifiers
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabs === 3}
                  className={classnames("mb-sm-3 mb-md-0 mt-2", {
                    active: tabs === 3,
                  })}
                  onClick={(e) => toggleNavs(e, 3)}
                  href="#pablo"
                  role="tab"
                >
                  <i className="fa fa-print mr-2" />
                  Availablity
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="mt-5" activeTab={"tabs" + tabs}>
              <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
                <GeneralTabItem
                  category={category?.data?.data}
                  location={
                    location?.data?.data?.length > 0 ? location?.data?.data : []
                  }
                  options={options?.data}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  setImage={(img) => setImage(img)}
                  taxRate={entities?.data}
                  items={items?.data?.data}
                  errors={errors}
                  touched={touched}
                  tag={tags?.data}
                  entity={entity}
                />
              </TabPane>
              <TabPane tabId="tabs2">
                <AddEditModifiersTab
                  handleChange={(val) => {
                    setFieldValue("modifiers", val);
                  }}
                  modifiers={values.modifiers}
                />
              </TabPane>
              <TabPane tabId="tabs3">
                <AvailablityTabItem
                  isAvailableDaily={values.isAvailableDaily}
                  onIsAvailableDailyChange={(val) =>
                    setFieldValue("isAvailableDaily", val)
                  }
                  onChangeSlots={(slots) =>
                    setFieldValue("availability", slots)
                  }
                  slots={values.availability}
                />
              </TabPane>
            </TabContent>
          </>
        ) : (
          <>
            {" "}
            <div
              style={{
                padding: 20,
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <center>
                <Spinner size="lg" color="primary" type="border" />
              </center>
            </div>
          </>
        )}
      </TableWrapper>
    </div>
  );
};

export default AddItem;
